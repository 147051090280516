@import "../../Styles/Core/variables.scss";
$flyout-font-family: $font-family-text;
$flyout-container-width: 620px;
$flyout-container-height: 100%;

$flyout-header-container-align-items: center;
$flyout-header-container-align-self: stretch;
$flyout-header-container-display: flex;
$flyout-header-container-gap: 24px;
$flyout-header-container-padding: 24px 32px;

$flyout-header-color: $slate-70B;
$flyout-header-font-size: $font-xxlarge;
$flyout-header-font-style: normal;
$flyout-header-font-weight: $weight-medium;
$flyout-header-line-height: 36px;

$flyout-actions-container-align-items: center;
$flyout-actions-container-align-self: stretch;
$flyout-actions-container-display: flex;
$flyout-actions-container-gap: 24px;
$flyout-actions-container-padding: 16px 32px 40px 32px;

$flyout-actions-icon-container-gap: 16px;

$flyout-actions-dropdown-width: 100%;
$flyout-icon-color: $slate-70B;
$flyout-notifications-container-height: 100%;

$flyout-no-results-color: $slate-30B;
$flyout-no-results-font-size: $font-small;
$flyout-no-results-font-style: normal;
$flyout-no-results-font-weight: $weight-bold;
$flyout-no-results-line-height: 20px;
$flyout-no-results-text-align: center;
