@import "../../Styles/variables.scss";

.divider {
    border-top: 1px solid $grey-50;    
    width: 100%;
}

.screenReader {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}