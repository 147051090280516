@import "../../styles.module";
@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.content {
  width: 100%;
  position: relative;
}

.dataTable {
  display: block;


  table {
    width: 175%;

    @include desktop {
      width: 100%;
    }
  }

  th tr td:last-of-type {
    width: 6.5%;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  tbody tr td:last-child {
    text-align: right;
    padding-right: 32px;
  }
}

.overrideInputView {
  display: flex;
  flex-direction: column-reverse;

  input[type="text"] {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  & > div > div {
    margin-top: 0;
  }
  div > label {
    color: $grey-60;
    font-size: $font-small;

    & > span {
      display: none;
    }
  }
}

.isEditInput input[type="text"]{
   
    padding: 14px 17px 12px 16px;
    max-width: 153px;
    margin: 10px 0px;
    &:read-only {
      border: $grey-20 1px solid;
      background: $grey-6;
    }
    
}
.isEditInput span:first-of-type {
 display: none;
}
.overrideInputEditing {

  & > label {
    display: initial;
  }
}

.addOtherAdjustmentContainer {
  display: block;
  float: right;
  padding: 24px 32px;
}

.background > div {
  > div {
    z-index: 101;
  }
  > aside {
    z-index: 101;
  }
}

.tableInput {
  @extend .tableStandardInput;

  input {
    max-width: 100%;
  }
}
.tableDateInput {
  margin-right: 26px;
  margin-top: 26px;
  
  input[type="text"]:disabled,
  input[type="text"]:read-only {
    margin-top: 0px;
  }
}

/* To  handle date picker - using selector with specific hierarchy to max specificity */
.fieldset {
  width: 100%;
}