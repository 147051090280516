$indicator-size: 8px;

.indicator {
    background-color: $grey-20;
    @include border-radius(50%);
    height: $indicator-size;
    width: $indicator-size;
    margin-right: 8px;
    display: inline-block;

    &--declined {
        background-color: $notification-error;
    }
    &--approved {
        background-color: $notification-success;
    }
}
