@import "../../../../Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.notification-icon {
  position: relative;

  &-oval {
    position: absolute;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px $white;
    background-color: $notification-error;
    margin-top: 7px;
    margin-left: -10px;
  }
}

.notification-text > button {
  text-decoration: none;

  > span:last-of-type {
    font-weight: $weight-bold;
    color: $soft-black;
  }
}

.notification-flyout {
  z-index: 100;
}

.action-icons {
  font-size: $font-large;

  &-buttons {
    width: 180px;
    padding: 0 16px;
    border-bottom: 1px solid $grey-20;
    margin-right: 16px;
    
    button {
      height: 32px;
      margin-top: 21px;

      & > span:first-child {
        margin-left: 2px;
        margin-right: 0px;
      }

      @include desktop {
        margin-top: 4px;
        & > span:first-child {
          margin-left: 10px;
          padding: 0px;
        }
      }
    }
  }
}

.icon {
  margin: 16px 16px;
  display: inline-block;

  &-colour {
    font-size: 12px;
    color: $global-blue;
  }
}

.readIcon {
  padding-right: 16px;
}

.content {
  &Description {
    font-size: $font-small;
  }

  &Details {
    padding: 15px 0;
    font-size: $font-regular;
  }

  &Heading {
    font-weight: $weight-bold;
    margin-bottom: 8px;
  }

  &Row {
    margin: 0 -32px 0 0;
    border-bottom: 1px solid $grey-20;
  }
}

.read {
  display: none;
}

.open {
  background: $white;
}

.close > div:first-of-type {
  > aside:first-of-type {
    background: transparent;
    box-shadow: none;
  }
}

.hide {
  visibility: hidden;
}

.dateTime {
  margin: 15px 0;
  font-size: $font-small;
  color: $grey-70;
}

.top {
  & > div {
    & > div {
      z-index: 1;
    }

    & > aside {
      width: 1034px;
    }
  }
}

.notificationFlyout {
  height: 100%;

  & > aside:first-of-type {
    padding-left: 0;
    border-left: 1px solid $grey-20;
  }
}

.smallText {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 0 0 32px;

  small {
    margin: 0;
    font-size: $font-small;
    color: $grey-70;
  }
}

.rowColour {
  background-color: $tint-5;
}

.headerWrapper {
  &Main {
    align-items: center;
    border-bottom: 1px solid $grey-20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 32px 35px 32px 32px;
    h2 {
      margin: 0;
    }
  }

  &Flyout {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 32px 35px 0 32px;
    h2 {
      margin: 0;
    }
  }
}

.bodyWrapper {
  margin: 32px;

  a {
    font-size: $font-small;
  }

  .bodyMessage {
    font-size: $font-small;
    margin-top: 16px;

    > span {
      color: $global-blue;
    }
  }
}

.closeButton {
  button {
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }

  > button:first-of-type {
    margin-right: 28px;
    &:first-child {
      transform: rotate(90deg);
    }
  }
}

.chevronButton {
  button {
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
}

.textWrapper {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
}

.categoriesWrapper {
  margin-bottom: 32px;
  label {
    font-weight: bold;
    font-size: 14px;
  }
}

.templateWrapper {
  margin-bottom: 32px;
}

.templateTextArea {
  textarea {
    height: 280px;
  }
}

.notesWrapper {
  margin-bottom: 48px;
}

.notesTextArea {
  textarea {
    height: 160px;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  border-top: thin solid $grey-20;
  padding-top: 32px;
  button {
    margin-right: 9px;
    &:last-child {
      margin-right: 0;
      margin-left: 9px;
    }
  }
}

.selectOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    margin-left: 16px;
  }
  input[type="checkbox"] {
    appearance: none;
    padding: 1px;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    border: thin solid $black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type="checkbox"]:checked {
    border: none;
    background-color: $global-blue;
  }
  input[type="checkbox"]:checked:after {
    color: $white;
    content: "\2713";
    margin-bottom: -1px;
  }
}

.alignBottomLine {
  & > div {
    & > ul {
      padding: 11px 0 20px 16px;
    }
  }
}

.tabBar {
  @include desktop {
    padding: 14px 0;
  }
}

.tabs {
  flex: auto;
  padding-left: 16px;
}

.notificationDrawerSize {
  & > div {
    & > aside {
      width: 100%;
      height: 100%;
      
      @include desktop {
        width: 1034px;
      }
    }
  }
}
