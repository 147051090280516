@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.modifyInternalPIUserLoader {
  min-height: calc(100vh - 350px);
}

.modifyInternalPIUserMain {
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
  padding: 0 $spacing-11 $spacing-13 $spacing-11;
  min-height: calc(100vh - 350px);

  .modifyInternalPIUserMainContainer {
    @include flex(flex-start, unset);
    flex-direction: column;
    padding: $spacing-08 0;
    border-radius: $spacing-02;
    border: 1px solid $slate-50W;
    background: $white;
    width: 100%;

    .modifyInternalPIUserMainContent{
      @include flex(flex-start, space-between);
      flex-direction: column;
      gap: $spacing-08;
      width: 100%;
      padding: 0 $spacing-08 $spacing-08 $spacing-08;

      .modifyInternalPIUserHeaderContainer {
        .modifyInternalPIUserTitle {
          @include font-settings($font-xxlarge, $weight-medium, $line-height-large, $slate-70B);
          text-align: center;
        }
      }

      .modifyInternalPIUserFormContainer {
        @include flex(flex-start, unset);
        flex-direction: column;
        align-self: stretch;
        gap: $spacing-08;

        .modifyInternalPIUserFormBody {
          border-radius: $spacing-02;
          border: 1px solid $slate-50W;
          margin: 0;

          > div {
            padding: $spacing-08 $spacing-08 0;
            margin: 0;
          }

          > div:nth-of-type(2) {
            padding: 0 $spacing-08;
          }

          > div > div > div {
            margin-bottom: $spacing-08 !important;
          }
        
          div:empty {
            display: none;
          }

          .modifyInternalPIUserFormActionBtnContainer {
            @include flex(center, space-between);
            flex-direction: row;
            padding: 0 $spacing-08 $spacing-08;
            gap: $spacing-05;

            & > div {
              display: flex;
              flex-direction: row;
              gap: $spacing-05;
          
              &:first-of-type {
                flex-grow: 2;
          
                & > button {
                  min-width: 186px;

                  @include tablet{
                    min-width: 140px;
                  }
          
                  &:first-of-type {
                    margin-left: 0;
                    max-width: unset;
                  }

                  &:last-of-type { margin-right: 0; }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include for-tablet {
  .modifyInternalPIUserMain {
    padding: 0 $spacing-08 $spacing-13 $spacing-08;

    .modifyInternalPIUserMainContainer {
      .modifyInternalPIUserMainContent{
        .modifyInternalPIUserFormContainer {
          .modifyInternalPIUserFormBody {
            .modifyInternalPIUserFormActionBtnContainer {
              @include flex(flex-start, flex-start);
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}