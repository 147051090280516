@import "src/Components/Styles/variables";

.status {
  position: relative;
  margin-left: 16px;

  &::before {
    content: '';
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    background: $global-blue;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &.warning::before {
    background: $notification-warning;
  }
  &.error::before {
    background: $notification-error;
  }
  &.blue::before {
    background: $global-blue;
  }
  &.success::before {
    background: $notification-success;
  }
  &.grey20::before {
    background: $grey-20;
  }

  &-internal {
    &-saveddraft::before, &-noclaimpayable::before, &-cancelled::before {
      background: $grey-20;
    }
    &-new::before {
      background: $global-blue;
    }
    &-declined::before, &-paymentfailed::before {
      background: $notification-error;
    }
    &-reviewrequired::before, &-inprogress::before, &-pending::before, &-claimpaymentinprogress::before, &-refundrequested::before, &-approvalrequired::before {
      background: $notification-warning;
    }
    &-approvedauto::before, &-approvedmanual::before, &-paid::before, &-cmhcreimbursed::before {
      background: $notification-success;
    }
  }

  &-external {
    &-saveddraft::before, &-cancelled::before, &-noclaimpayable::before {
      background: $grey-20;
    }
    &-new::before {
      background: $global-blue;
    }
    &-declined::before {
      background: $notification-error;
    }
    &-inprogress::before, &-refundrequested::before {
      background: $notification-warning;
    }
    &-paid::before, &-cmhcreimbursed::before {
      background: $notification-success;
    }
  }
  
}