@import "../../../../../../Components/Styles/variables";

.suspenseSections {
  border: 1px solid $grey-20;
  border-radius: 5px;
  padding: 32px;
  margin-bottom: 32px;
  margin-top:32px;
}

.heading { margin-bottom: 32px; }

.helpIcon { margin: 0 10px; vertical-align: text-bottom; }

.marginBottom35 { margin-bottom: 35px; }
