@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/base";
@import "src/Components/Styles/typography";

.section {
  width: 100%;
  display: block;

  @include desktop {
    display: flex;
    justify-content: space-between;
    width: 87.5%;

    &>* {
      min-width: calc(50% - 32px);
    }
  }
}