@import "src/Components/Styles/variables";

.external-inventory-table {
  .data-table__wrapper {
    overflow-y: visible !important;
  }
  table {
    width: 100% !important;
  }

  .table__row td:nth-child(2) {
    width: 185px;
  }

  .table__row td:nth-child(3) {
    position: relative;

    i:nth-child(2) {
      left: -95px;
      top: 50%;
      position: absolute;
      transform: rotateZ(45deg) translateY(-60%);
    }
  }

}

.pagintaion {
  min-width: fit-content;

   button:disabled {
     background: $grey-20;
     color: $soft-black;
   }
 
   button[aria-current="true"] {
     background: $global-blue;
     color: $white;
     border-color: $global-blue;
 
     &:hover {
       background: lighten($global-blue, 5);
       border-color: lighten($global-blue, 5);
     }
   }
 }

.pager {
  margin-right: 8px;
  margin-left: 0px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  padding-top: 0px;
  height: 30px;
  select{
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    height: 30px;
  }
}