.session-timeout-modal{
    &__inner {
        -ms-grid-rows: 1fr auto;
        display: grid;
        display: -ms-grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
    }
  
    &__message {
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        -ms-grid-row: 1;
        grid-column: 1/3;
        grid-row: 1/2;
        margin-bottom: 60px;
    }
  
    &__time-remaining {
        font-weight: $weight-bold;
    }
  
    &__buttons-wrapper {
        display: flex; 
        justify-content: flex-end;
    }
    
    &__log-out-button {
        margin: 0 15px 0 0;
        display: inline-block;
    }
  
    &__continue-session-button {
        display: inline-block;
    }

}