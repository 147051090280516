@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.scrollableWrapper {
  position: relative;
}

.dataTableWrapper {
  display: block;
  overflow-x: scroll;
  margin-bottom: 24px;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey-20;
    border-radius: 4px;

    &:hover {
      background: $grey-60;
    }
  }

  @include desktop {
    overflow-x: initial;
  }

  table {
    width: 120%;

    @include desktop {
      width: 100%;
    }
  }

  thead th:first-child,tbody tr td:first-child  {
    position: -webkit-sticky;
    position: sticky;
    width: 15%;
    left: 0;
    background: $white;
    white-space: normal;
    transition: all 1.2s ease;
    z-index: 1;

    &:after {
      content: '';
      position : absolute;
      width: 5px;
      height: 100%;
      top: 0;
      right: -5px;
      background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0));
      transition: all 1.2s ease;
    }

  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  tbody tr td:first-child {
    font-weight: bold;
  }

  tbody tr:nth-child(even) td:first-child {
    background: $grey-3;
  }

  tbody tr td:first-child[class="subtotalBg"] {
    background: $tint-5;
    padding: 20px;
    font-size: 14px;
    color: #282828;
  }

  tbody tr:not(:last-child) td:first-child[class="subtotalBg"] {
    border-bottom: 1px solid $grey-20;
  }

  tbody tr:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;

    & > td:not(:first-child) {
      border-top: $border-focused;
      border-bottom: $border-focused;
    }
  }
}

.scrolled {
  @include tablet {
    thead th:first-child,tbody tr td:first-child {
      &:after {
        background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0));
      }
    }
  }
}
