@import "src/Components/Styles/adaptive";

.footer {
  display: block;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;

  .btn-primary:disabled {
    background : #7A7A7A !important;
  }
}

.awkText {
  display         : flex;
  align-items     : center;
  justify-content : flex-start;

  .form-check-label {
    display             : flex;
    margin              : 2px 0 0 5px;
    -webkit-user-select : none;
    -moz-user-select    : none;
    -ms-user-select     : none;
    user-select         : none;
  }
}

.checkboxWrapper {
  position : relative;
  margin   : 0;
  padding  : 0;
  display  : flex;
  width: 100%;

  & label {
    font-weight: 400;
    font-size: 14px;
  }

  & > input {
    -moz-appearance     : none;
    -o-appearance       : none;
    -webkit-appearance  : none;
    appearance          : none;
    background-color    : #CCCCCC;
    border              : none;
    border-radius       : 2px;
    cursor              : pointer;
    height              : 16px;
    outline             : none;
    transition-duration : 0.2s;
    width               : 16px;

    &:checked {
      background : #005c84;
      border     : 1px solid #005c84;
    }

    &:checked::after {
      color      : #FFF;
      content    : '\2713';
      display    : block;
      font-size  : 12px;
      left       : 40%;
      position   : absolute;
      text-align : center;
      top        : -3px;
      transform  : translateX(-50%);

    }
  }
}

.header {
  display: flex;
}
