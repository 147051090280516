.attachment-form__attachment-type-selector-wrapper .form__fieldNotificationHolder {
  height: 0;
}

.upload-progress-bar {
  border: solid #cccccc 1px;
  border-radius: 5px;
  /* We can't assign col-12 to upload-progress-bar because col-12 adjusts the 
     padding, not the margins. The margins need to be adjusted for the border 
     to show correctly. */
  margin: 0 16px 16px;
  width: 100%;
}

.upload-progress-bar__main {
  -ms-grid-columns: auto auto;
  -ms-grid-rows: auto auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  width: 100%;
}

.upload-progress-bar__text {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  color: #282828;
  font-size: 0.85em;
  font-weight: bold;
  grid-column: 1/2;
  grid-row: 1/2;
  overflow: hidden;
  padding: 15px 0 0 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 2;
}

.upload-progress-bar__percentage {
  -ms-grid-column: 1;
  -ms-grid-row: 2;
  color: #282828;
  font-size: 0.7em;
  font-weight: bold;
  grid-column: 1/2;
  grid-row: 2/2;
  line-height: normal;
  padding: 0 0 15px 20px;
  z-index: 2;
}

.upload-progress-bar__cancel {
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-column: 2/3;
  grid-row: 1/3;
  margin: auto 20px auto auto;
  background-color: #f8f8f8;
  border: none;
  z-index: 2;
}

.upload-progress-bar__cancel img {
  cursor: pointer;
}

.upload-progress-bar__progressFill {
  background-color: #f8f8f8;
  border-top-left-radius: 5px;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-column: 1/3;
  grid-row: 1/3;
  width: 0%;
  z-index: 1;
}

.upload-progress-bar__progressBorder {
  background-color: #005c84;
  border-bottom-left-radius: 5px;
  height: 3px;
  width: 0%;
}

.upload-progress-bar-error {
  background-color: #f8f8f8;
  border: solid #cccccc 1px;
  border-radius: 5px;
  /* We can't assign col-12 to upload-progress-bar because col-12 adjusts the 
     padding, not the margins. The margins need to be adjusted for the border 
     to show correctly. */
  margin: 0 16px 16px;
  width: 100%;
}

.upload-progress-bar-error__main {
  -ms-grid-columns: auto minmax(0, 1fr);
  -ms-grid-rows: auto auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: auto auto;
  width: 100%;
}

.upload-progress-bar-error__icon {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-column: 1/2;
  grid-row: 1/3;
  margin: auto 0;
  padding-left: 15px;
  padding-right: 15px;
}

.upload-progress-bar-error__file-name {
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  color: #282828;
  font-size: 0.85em;
  font-weight: bold;
  grid-column: 2/3;
  grid-row: 1/2;
  padding: 15px 0 0 0;
}

.upload-progress-bar-error__error-message {
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  color: #282828;
  font-size: 0.7em;
  font-weight: bold;
  grid-column: 2/3;
  grid-row: 2/2;
  line-height: normal;
  padding: 0 0 15px 0;
  max-width: 100%;
}

.upload-progress-bar-error__cancel {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-column: 3/4;
  grid-row: 1/3;
  margin: auto 20px auto auto;
  background-color: #f8f8f8;
  border: none;
}

.upload-progress-bar-error__cancel img {
  cursor: pointer;
}

.upload-progress-bar-error__bottom-border {
  background-color: #da291c;
  border-bottom-left-radius: 5px;
  height: 3px;
  width: 100%;
}

.attachments-table {
  margin-bottom: 30px;
}

th.table__header, td.table__data {
  border-top: none;
}

td.table__data--document-type {
  max-width: 1px;
  white-space: normal;
  width: 30%;
}

td.table__data--file-name {
  max-width: 1px;
  white-space: normal;
  width: 40%;
}

td.table__data--top {
  border-bottom: none;
}

.table__attachment-description-header {
  color: #282828;
  font-weight: bold;
}

.table__attachment-description-wrapper {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  color: #282828;
  margin-bottom: 8px;
  margin-top: -16px;
  padding: 16px;
}

.table__attachment-description-inner-wrapper {
  table-layout: fixed;
  width: 100%;
}

.table__attachment-description-text {
  white-space: normal;
}

.table__attachment-description-text-truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table__attachment-description-show-more {
  color: #7a7a7a;
  cursor: pointer;
  font-weight: bold;
}

a.delete-attachment-icon:first-child, a.download-attachment-icon:first-child {
  margin-right: -36px;
  padding-right: 0;
}

.attachment-fetch-error {
  text-align: center;
}

.attachment-fetch-error-icon {
  color: #DA291C;
}

.no-attachments {
  text-align: center;
}

.form__attachment-text {
  max-width: 100%;
}
