@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.dataTable {
	table {
		thead > tr {
			th {
				vertical-align: top;
				padding: 15px;
			}

			th:nth-child(1) {
				padding-left: 33px;
			}

			th:nth-child(3) > button > span:nth-child(2) {
				width: 134px;
				white-space: normal;
				text-align: left;
				word-spacing: -1px;
			}
		}

		tbody > tr {
			td {
				padding: 14px;
			}
		}

		tbody > tr:nth-child(n) {
			> td:nth-child(1) {
				padding-left: 33px;
			}
		}
	}
}


.layoutContainer {
	display               : grid;
	grid-template-columns : 1fr;
	grid-template-areas   : 
		"title"
		"buttons";
	grid-template-rows    : auto;
	grid-auto-flow        : dense;
  
	& > div { margin-bottom : 24px; }
  
	@include desktop {
	  column-gap            : 32px;
	  grid-template-areas   : "title buttons";
	  grid-template-columns : 1fr 1fr;
  	}
  }
  
.buttonsContainer {
	display: flex;
	justify-content: space-between;
	grid-area : buttons;
	text-align: left;
	padding: 0 0 0 32px;
	button {
		margin-right: 16px;
	}
	
	@include desktop {
		padding: 24px;
		text-align: right;
	}
}
.buttonsContainerFrench {
	button {
		margin-right: 0px;
	}
}
.titleContainer {
	grid-area : title;
	padding: 24px 0 0 32px;
}

.userButtons {
	margin-right: 16px;
	display: inline-block;
	min-width: 190px;
}

.dataTableFooter {
	padding: 24px 0px 0px 0px;
}

.description {
	width: 600px;
}

.statusWrapper {
	border-bottom: 1px solid $grey-20;
	&.isCheck {
		color: $notification-success;
	}
}

.iconList {
	line-height: 0;
}

.icon {
	font-size: 20px;
	margin: 10px 4px 0 0;
}
.dataTable {
    width: 100%;
    padding-top: 16px;
    table {
        width: 100%;
      thead > tr {
        th{
          padding-left: 32px;
        }
      }
  
      tbody > tr:nth-child(n) {
        td{
          padding-left: 32px;
        }
  
      }
  
      tbody > tr:nth-child(even) td {
        background: $grey-3;
      }
    }
  }

  .dataTableFooter {
    >div {
      margin-right: -20px;
    }
  }

  .noSearchResultsMessage {
    display: block;
    padding: 32px;
    text-align: center;
    font-weight: bold;
  }

  .searchBarWrapper { 
    display: flex;
    justify-content: space-between;
    padding-left: 24px;

    @include tablet {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
    }
  
    @include desktop {
      flex-grow: 12;
      padding: 10px 10px 10px 22px;
    }
  }
  .searchBarWidth {
    width: 75%;
  }
