.arrears-links,.arrears-links:hover {
  text-decoration: underline;
}

.card__body-subHeading--text {
  padding-top: 0px;
  margin-bottom: 30px;
  margin-left: 0;
  font-size: 18px;
  font-weight: 500;
}

.resources__heading {
  margin-left: 0px;
}

/* temp for styling width */
.style100 {
  width: 100%;
}
