@import "Components/Styles/Core/variables";
@import "Components/Styles/Core/mixins.scss";

.modalCard {
    display: flex;
    flex-direction: column;
}

.modalHeaderContainer {
  .modalCardHeader {
    display: flex;
    align-items: center;
    padding-bottom: $spacing-07;
    gap: $spacing-07;

    .withLeadingIcon {
      display: flex;
      align-items: center;
    }

    .modalTitle {
      font-family: $font-family-text;
      font-size: $font-xxlarge;
      font-style: normal;
      font-weight: $weight-medium;
      line-height: $line-height-large;
      color: $slate-70B;
      flex-grow: 3;
      margin: 0;
    }

    .modalCloseBtn {
      border: none;
      cursor: pointer;

      &:focus {
        background-color: $white !important;
        @include focus-outline($violet-20W, $spacing-02);
      };
    }
  }

  .cardDivider {
    width: calc(100% + 62px);
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -$spacing-08;
    border-bottom: 1px $slate-50W;
  }
}

.modalBody {
  padding-top: $spacing-08;
}

.modalActionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-10;
    padding-top: $spacing-08;

    .modalTertiaryBtn {
      flex: 1;
    }

    .modalPrimeBtns {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: $spacing-07;
      width: 100%;
      flex: 2;

      button {
        flex: 1;
      }

      button:only-child {
        flex: none;
      }
    }
}


/* TODO: Remove the style of .formTextarea once Form Input UI component is merged.
  Temporarily styles added for input text area as new input design component not ready yet.
*/
.formTextarea {
    label {
        display: flex;
        flex-direction: column;
    }
}