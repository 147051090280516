@import "../../Styles/Core/variables.scss";
@import "../../Styles/Core/mixins";

.pagination {
  @include flex-center;
  @include font-settings($font-small, $weight-bold, $line-height-small, $slate-70B);
  text-align: center;

  .currentPageButton, .pageButton {
    @include button-size(48px, 48px);
  }

  .pageButton {    
    text-decoration: none;
    
    &:hover {
      color: $link-hover-active-color;
      outline: 2px solid $link-hover-active-color;
    }
  }
  
  .paginationItemsOnPageWrapper {
    @include flex-between;
    gap: $spacing-03;
  }
  
  .paginationTextContainer {
    @include  flex-between;
    gap: $spacing-04;
  }
   
  .paginationDropdownValueContainer {
    @include flex-between;
    padding: $spacing-04 $spacing-05;
    margin-right: $spacing-04;
    outline: $spacing-01 solid $slate-50W;
    border-radius: $border-radius;
    text-decoration: none;
    cursor: pointer;
    gap: $spacing-05;    

    &:focus:not(.active) {
      position: relative;
    }

    &:focus::after {
      @include button-focus-after;
    }
  }
  
  .paginationChevron {
    background: none;
    border: none !important;
    
    &:hover {
      outline: 2px solid $link-hover-active-color;
    }
  }

  &.position-center {
    justify-content: center;
  }

  &.position-left {
    justify-content: flex-start;
  }

  &.position-right {
    justify-content: flex-end;
  }
}

.paginationDropdownMenu {
  border-radius: $border-radius;
  justify-content: start;
  width: 80px;
  padding: $spacing-01 0;
  outline: 2px solid $input-active-keyline;
  background: #FFF;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;

  .paginationDropdownList {
    margin: 0;
    padding: 0;
  }

  .paginationDropdownItem {
    @include flex-start;
    gap: $spacing-05;
    @include font-settings($font-small, $weight-regular, $spacing-06);
    padding: $spacing-04 $spacing-05;

    &:hover {
      background: $violet-90W;
    }
  }
}

.paginationButtonContainer {
    @include flex-between;
    gap: $spacing-03;    
}

.paginationEllipsis {
  @include flex-center;
  height: 48px;

  &::after {
    content: "";
    margin: 0;
    display: block;
    width: 16px;
    height: 16px;
    background: url('../HbtIcon/icons/Ellipses.svg') no-repeat center center;
    background-size: contain;
  }
}
