@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.cardHeader {
  padding: 40px 33px 16px 33px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}


.modalTitle {
  font-size: $font-large;
  font-weight: $weight-medium;
  padding-top: 3px;
  padding-bottom: 10px;
}


.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;
  grid-auto-flow        : dense;
  grid-template-areas   : 
    "title"
    "buttons";

  & > div { margin-bottom : 24px; }

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "title buttons";
    grid-template-columns : 1fr 1fr;
  }
}

.buttonsContainer {
  display: flex;
  grid-area : buttons;
  justify-content: flex-start;
  padding-top: 24px; 
  button {
    padding: 12px 32px;
    margin-right: 16px;
  }

  @include desktop {
    padding-bottom: 24px;
    text-align: right;
  }
}

.titleContainer {
  grid-area : title;
  padding: 24px 0 0 32px;
}

.userButtons {
	margin-right: 16px;
	display: inline-block;
	min-width: 190px;
}



.description {
  padding: 0px 0px 32px 33px;
  width: 550px;
}

.descriptionText {
  padding-right: 33px;
  max-width: 550px;
}