
//CARD ====================================================================================================================

.card {
    margin-bottom: 24px;

    &--horizontal-blue {
        border-top: $global-blue 8px solid;
    }

    &--vertical-grey {
        border-left: $grey-6 8px solid;
    }

    &--vertical-success {
        border-left: $notification-success 8px solid;
    }

    &--vertical-error {
        border-left: $notification-error 8px solid;
        .notification {
            .notification-icon{
                width: 24px;
                margin-right: 16px;
            }
        }
    }

    &--vertical-warning {
        .notification{
            background: $grey-3;
            border: 1px solid $grey-20;
            -webkit-box-shadow: inset 7px 0px 0px 0px $notification-warning;
            -moz-box-shadow: inset 7px 0px 0px 0px $notification-warning;
            box-shadow: inset 7px 0px 0px 0px #F89100;
            border-radius: 4px;
            margin-top: -1px;
            margin-bottom: -1px;
            .notification-icon {
                width: 24px;
                margin-right: 15px;
                margin-left: 10px;
            }
        }
    }

    &__divider {
        border-top: $border-default;
        margin-top: 32px;
        margin-bottom: 32px;

        &--m20 {
            border-top: $border-default;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &--thick {
            border-top: $border-thick;
            margin-left: -32px;
            margin-right: -32px;
        }

        &--t0 {
            margin-top:0px
        }
    }

    &__header {
        background-color: $white;
        border-bottom: none;
        padding: 40px 32px 32px 32px;
        @include flexbox;
        @include align-items(center);
        display: block;
        width: 100%;

        &:first-child{
            @include border-radius(8px);
        }

        &--tabbedSection {
            margin-bottom: 40px;
        }
          
    }

    &__body- {
        font-size: $font-large;
        font-weight: $weight-bold;
        letter-spacing: 0;
        line-height: $line-height-regular;
        color: $soft-black;
        margin-bottom: 32px;
    }

    &__header-title, &__body-title {
        font-family: $font-family-text;
        font-size: $font-xxlarge;
        font-weight: $weight-medium;
        letter-spacing: 0;
        line-height: 40px;
        color: $soft-black;
        margin: 0;
        margin-right: auto;
    }

    &__header-title {
        display: inline-block;
        vertical-align: middle;
    }

    &__header-image {
        height: 40px;
        width: 40px;
        margin-right: 16px;
        display: inline-block;
        vertical-align: top;
    }

    &__header-button {
        margin-left: auto;
    }

    &__form-header {
        display: flex;
        align-items: flex-start;
    }

    &__body {
        padding: 0 32px 40px;
    }

    &__body-heading, &__body-subHeading {
        margin-bottom:32px;
        padding-top:32px;
        
        a {
            text-decoration: underline;
        }
    }
    &__body-subHeading {
        font-size: $font-regular;
        font-weight: $weight-bold;
        margin-bottom: 0px;
    }

    &__body-alignTitle {
        margin-left: 40px;
        a {
            text-decoration: underline;
        }
    }
    
    &__body-list-title {
        font-weight: $weight-bold;
        font-size: $font-regular;
        letter-spacing: 0;
        line-height: $line-height-regular;
        color: $soft-black;
    }

    &__body-text {
        > p {
            margin: 0;
        
            &, * {
            font-size: $font-small;
            }

            &:not(:first-child){
                margin-top: 14px;
            }
        }
        a {
            text-decoration: underline;
        }
    }

    &__body-list-link {
        font-size: $font-small;
        display: block;
        margin-bottom: 12px;
        &:hover, &:active {
            color: $shade-30;
            text-decoration: underline;
        }
    }

    &__help-text-wrapper {
        margin-top: 18px;
        font-size: $font-small;
    }

    &__body-row {

        &--fluid {
            margin-left: -48px;
            margin-right: -48px;
        }

        &:not(:last-child) {
            margin-bottom: 0px;
        }

        &--no-flex-end {
            align-items: unset !important;
        }
    }
    &__actionButtons {
        display: flex;
        justify-content: flex-end;
    }
    &__deleteRow {
        padding-bottom: 12px;
        position: relative;
    }
    &__deleteRow .btn__delete{
        min-width: 100%;
        position: absolute;
        bottom: 58px;
        right: 0px;
    }
    // IMAGE CARDS =========================================================
    &__body-logo {
        height: 80px;
        margin-bottom: 50px;
        img{
            height:100%
        }
    }

    &__body--image-card {
        padding: 0;
    }

    &__body-content--image-card {
        padding: 80px;
        padding-left: 88px;
        @include flexbox;
        @include flex-direction(column);
        justify-content: center;
    }

    &__body-image {
        object-fit: cover;
        height: intrinsic;
    }

    &__body-button {
        width: fit-content;
        margin-top: 50px;

        &--right {
            @include align-self(flex-end);
        }
    }
    // end IMAGE CARDS -----------------------------------------------------

    &__inventory-table {
        padding-bottom: 24px !important;
    }
}

.defaultFormContents,
.tabs {
    & .card {
        &__body-row {
            align-items: flex-end;
            &--no-flex-end {
                align-items: stretch;
            }
        }
    }
}

form {
    .card {
        &__body-heading {
            padding-top: 0;
        }
        &__body-subHeading { 
            + h3 {
                margin-top: 32px;
            }
        }
    }
}
