.pager{
    margin: 0px 40px 0px 0px;
    padding-top: 0px;

    & label {
        margin-bottom: 0px;
        margin-right: 8px;
    }

    & label, & .form__element, & .select-container {
        display: inline-block;
    }
}