@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";


.actionMenuHeader {
  opacity: 0;
  padding: 0;
  width: 10px;

  span {
    display: none;
  }
}

.searchBarWrapper {
  border-top: 1px solid $grey-20;
  flex-grow: 12;
  display : flex;
  justify-content: space-between;
  padding: 13px 33px;
  flex-wrap:wrap;
  gap: 10px;
}

.searchInput{
  width: 500px;
}

.filterWrapper {
  display : flex;
}

.filterLabel{
  appearance   : none;
  background   : $grey-3;
  border       : none;
  border: $grey-20 1px solid;
  display      : block;
  font-size    : 11px;
  height       : 60px;
  padding      : 8px 32px 16px 16px;
  position     : relative;
  transition   : background-color 0.3s ease;
  width        : 100px;
}

.labelHeader{
   line-height: 13px;
 }

.selectInput{
   width: 500px;
}

.optionSelected{
  background-color: transparent !important;
  color: $black !important;
  
}
.optionUnselected{
  background-color: inherit;
}

.checkboxStyle{
  margin-right: 10px;
}

.dataTable table {
  width: 100%;

  tr > td, tr > th {
    &:nth-child(1) { width: 65px; }

    &:nth-child(2) { width: 179px; }

    &:nth-child(3) { width: 124px; }

    &:nth-child(4) { width: 95px; }

    &:nth-child(5) { width: 120px; }

    &:nth-child(6) { width: 208px; }

    &:nth-child(7) { width: 177px; }

    &:nth-child(8) { width: 150px; }

    &:nth-child(9) { width: 166px; }

    &:nth-child(10) { width: 120px; }
  }

  tbody > tr:nth-child(even) td {
    background: $grey-3;
  }
}

.textualButton {
  padding: 0;
}

.dataTableFooter {
  padding: 24px 0 0 0;
}

.innerMargin {
  @extend .cardBase;
  margin: 32px;

  > div {
    padding: 32px;
  }
}

.buttonLayoutCreate {
  > div {
    padding-left: 0;
  }
}

.showForDesktopOnly {
  display: none;

  @include desktop {
    display: block;
  }
}

.hideForDesktop {
  display: block;

  @include desktop {
    display: none;
  }
}

.tableClass {
  width: 100%;
}

.layoutContainer {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  & > div {
    margin-bottom: 24px;
  }

  @include desktop {
    column-gap: 32px;
    grid-template-areas: "title buttons";
    grid-template-columns: 1fr 1.5fr;

    & > div:first-child {
      grid-area: title;
    }
    & > div:last-child {
      grid-area: buttons;
    }
  }
}

.buttonsContainer {
  padding: 0 0 0 32px;
  text-align: left;

  button {
    margin-right: 16px;
    padding: 12px 32px;
  }

  @include desktop {
    margin-left: -32px;
    padding: 24px;
    text-align: right;
  }
}

.titleContainer {
  padding: 32px 0 0 32px;
}

.tableButton {
  background-color: rgba(255,255,255, 0);
  border: none;
  color: $global-blue;
  text-decoration: underline;

  img {
    padding-left: 8px;
  }
}
