@import "src/Components/Styles/variables";

.contestClaimFormHeader {
  margin: 32px 0px;
  border-bottom: solid 1px $grey-20;
  & > h2 {
    margin-bottom: 24px;
  }
}
.contestClaimFormBody{
  margin: 32px;
  & > h2 {
    margin-bottom: 24px;
  }

  input[type="text"] {
    border: $grey-20 1px solid;
    border-radius: 4px;
    font-size: $font-regular;
    padding: 12px 16px;
  }
}

.closeButton {
  display: block;
  position: absolute;
  right: 32px;
  top: 32px;

  button {
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
}

.textStyle{
  margin-top: 7%;
  margin-bottom: 8%;
}

.textHeader{
  margin: 32px;
}

.twoColumns {
  display: flex;

  input[type=text] {
    max-width: 265px;
  }

  &.formButtonsWrapper {
    border-top: $grey-20 1px solid;
    padding-top: 32px;

    & > div {
      margin: 0 9px;
    }
  }

  & > div {
    flex-grow: 1;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.saveIconWrapper {
  position: absolute;

  & > span {
    animation: spin 0.9s linear infinite reverse;
    left: -28px;
    position: absolute;
  }
}

.templateWrapper {
  margin-bottom: 32px;
}

.templateTextArea {
  textarea{
    height: 160px;
    border: $border-default;
    border-radius: 4px;
  }

  > div:first-of-type {
    padding: 8px 0;
  }
}