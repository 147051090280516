$desktop-width: 1286px;
$desktop-v2-width: 1440px;
$desktop-breakpoint: 1200px;
$tablet-breakpoint: 864px;
$desktop-small: 1220px;
$tablet-width: 768px;
$tablet-max-width: 1024px;

@mixin for-tablet($min-width: $tablet-width, $max-width: $tablet-max-width) {
  @media screen and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    @content;    
  }
}

@mixin for-small-desktop($min-width: $tablet-max-width + 1, $max-width: $desktop-small) {
  @media screen and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    @content;    
  }
}

@mixin for-laptop($min-width: $tablet-max-width, $max-width: $desktop-v2-width) {
  @media screen and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    @content;    
  }
}

@mixin for-laptop-and-up($min-width: $tablet-max-width, $high-res-breakpoint: $desktop-breakpoint) {
  @media screen and (min-width: #{$min-width}), screen and (min-width: #{$high-res-breakpoint}) and (-webkit-min-device-pixel-ratio: 1.25) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$desktop-width - 1px}) {
    @content;    
  }
}

@mixin for-small-tablet {
  @media screen and (max-width: #{$tablet-breakpoint}) {
    @content;    
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}), screen and (min-width: #{$desktop-breakpoint}) and (-webkit-min-device-pixel-ratio: 1.25)  {
    @content;
  }
}

.page-wrapper {
  width: 100%;
  min-width: $tablet-width;
  margin: 0 auto;
  padding: 0 12px;

  @include desktop {
    max-width: $desktop-width;
  }
}

.ssr-layout{
  display: none !important;
}
