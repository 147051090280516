@import "Components/Styles/Core/variables";
@import "Components/Styles/Core/adaptive";
@import "./formFieldVariables.scss";
@import "./mixins.scss";

.form {
  align-items: $form-field-align-items;
  align-self: $form-field-align-self;
  background: $form-field-background-color;
  border: $form-field-border !important;
  border-radius: $form-field-border-radius;
  display: $form-field-display;
  gap: $form-field-gap;
  padding: $form-field-padding;
  margin: $form-field-margin;

  textarea {
    color: $form-field-filled-color !important;
    font-family: $form-field-font-family;
    font-size: $form-field-font-size;
    font-style: $form-field-font-style;
    font-weight: $form-field-font-weight;
    height: $form-field-height;
    line-height: $form-field-line-height; /* 150% */
    width: $form-field-width;
    resize: $form-field-resize;
    border: $form-field-text-area-border;

    &:focus-visible {
      outline: $form-field-text-area-focus-visible-outline;
    }
  }

  &:hover {
    @include form-field-hover;
  }

  &:focus-within {
    @include form-field-focus($form-field-focused-color);
  }

  &:focus {
    @include form-field-focus($form-field-focused-color);
  }

  &:focus-visible {
    @include form-field-focus($form-field-focused-color);
  }

  &.error {
    border: $form-field-error-border !important;
    background: $form-field-error-background;
  }
}

.error {
  border: $form-field-error-border !important;
  background: $form-field-error-background;
}

.readOnly {
  -webkit-text-fill-color: unset !important;
  background-color: $form-field-disabled-background-color !important;
  border: $form-field-disabled-border !important;
  font-size: $form-field-disabled-font-size;
  font-weight: $form-field-disabled-font-weight;
  line-height: $form-field-disabled-line-height; /* 150% */

  * {
    color: $form-field-disabled-color !important;
  }

  &:hover {
    border: $form-field-disabled-border !important;
  }
}

.formLabel {
  @include form-font-style;
  color: $form-field-label-color;
  font-weight: $form-field-label-font-weight !important;
  gap: $form-field-label-gap;
}

.formHelper {
  color: $form-field-helper-color;
  font-size: $form-field-helper-font-size !important;
  font-style: $form-field-helper-font-style !important;
  line-height: $form-field-helper-line-height !important;
  font-feature-settings: "clig" off, "liga" off;
  gap: $form-field-helper-error-gap;
}

.formHelperTextCount {
  @include form-helper($form-field-text-count-color);
  align-items: $form-field-helper-align-items;
  gap: $form-field-helper-error-gap;
  line-height: $form-field-text-count-line-height !important;
}

.formHelperError {
  @include form-helper($form-field-helper-error-color);
  gap: $form-field-helper-error-gap;
  line-height: $form-field-helper-line-height !important;
}

.formHelperContainer {
  gap: $form-field-helper-container-gap;
}

.formHelperIconContainer {
  align-content: $form-field-helper-icon-container-align-content;
  align-items: $form-field-helper-icon-container-align-items;
  display: $form-field-helper-icon-container-display;
}

.formHelperIcon {
  align-self: start;
  margin-top: 4px;
}

.formTextActive {
  color: $form-field-filled-color !important;
}

.textareaWrapper {
  position: relative;

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    height: 20px;
    width: 18px;
    text-align: center;
    bottom: 10px;
    right: 11px;
    z-index: 2;
    background: $white url('../HbtIcon/icons/Resize.svg') no-repeat;
  }

  &.readOnly {
    &:after {
      background-color: $form-field-disabled-background-color;
    }
  }
}
