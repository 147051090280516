@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

form {
  width: 100%;

  .fileTransferUploadViewMain {
    @include flex(flex-start, unset);
    flex-direction: column;
    align-self: stretch;
    padding-top: $spacing-08;
    gap: $spacing-11;
  
    .fileTransferLenderNameContainer {
      width: 50%;
    
      .lenderNameStepTitle {
        @include font-settings($font-large, $weight-medium, 150%, $slate-70B);
        padding-bottom: $spacing-05;
      }
    }
    
    .fileInstructionInputFieldContainer {
      width: 100%;

      .fileInstructionStepTitle {
        @include font-settings($font-large, $weight-medium, 150%, $slate-70B);
        padding-bottom: $spacing-03;
      }
    }
  
    .fileUploadContainer {
      width: 100%;
    }

    .fileTransferActionBtnsContainer {
      display: flex;
      flex-direction: row;
      margin-top: -$spacing-08;
      width: 100%;

      &:has(> div:nth-child(2)) {
        justify-content: space-between;
        align-items: center;
      }

      &:has(> div.fileTransferUploadViewSubmitBtnContainer:only-child) {
        justify-content: flex-end;
      }

      .fileTransferRemoveFilesBtnContainer {
        .removeAllFilesBtn {
          z-index: unset;
        }
      }
    }
  }
}

@include for-tablet {
  form {  
    .fileTransferUploadViewMain {
      .fileTransferLenderNameContainer {
        width: 100%;
      }
    }
  }
}

