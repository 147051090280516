@import "src/Components/Styles/variables";

.cardWrapper {
  border        : $grey-20 1px solid;
  border-left   : $global-blue 4px solid;
  border-radius : 4px;
  margin        : 24px 0;
  padding       : 24px 24px 24px 20px;
  position      : relative;
}

.warning {
  border-left-color : $notification-warning;
}

.success {
  border-left-color : $notification-success;
}

.error {
  border-left-color : $notification-error;
}

.icon {
  color     : $global-blue;
  font-size : 24px;
  position  : absolute;
}

.iconError {
  color : $notification-error;
}

.iconWarning {
  color : $notification-warning;
}

.iconSuccess {
  color : $notification-success;
}

.content {
  margin : 0 24px 0 40px;

  h2 {
    font-size   : 16px;
    line-height : 24px;
  }

  p {
    font-size   : 14px;
    line-height : 24px;
  }
}

.closeButton {
  position : absolute;
  right    : 24px;
  top      : 24px;

  button {
    background  : none;
    border      : none;
    font-size   : 16px;
    line-height : 0;
    margin      : 0;
    padding     : 0;
  }
}