@import "Components/Styles/Core/variables";
@import "./dropdownVariables.scss";
@import "Components/Styles/Core/mixins";

.clearAllClass {
  display: flex !important;
}

.container {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  max-width: $dropdown-max-width !important;
  min-width: $dropdown-min-width !important;
  background-color: $dropdown-background-color !important;
  gap: $spacing-03 !important;
}

.labelContainer {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.labelText {
  @include font-settings($font-small, $weight-bold, $line-height-regular, $text-links-text-header);
  margin: 0;
  margin-right: $spacing-03;
}

.labelIcon {
  @include flex-center;
}

.menuList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  max-width: 100% !important;
  padding: 0 !important;
  background: $dropdown-background-color;
}

.menuListWrapper {
  margin-top: $spacing-02;
  border-radius: $spacing-02 !important;
  border: $spacing-01 solid $input-active-keyline !important;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.25) !important;
}

.chevronIcon {
  top: auto !important;
  box-sizing: content-box !important;
  padding: 0 $spacing-05;
}

.chevronIconOpen {
  top: auto !important;
  padding: 0 $spacing-05;
  box-sizing: content-box !important;
  transform: rotate(180deg) !important;
}

.selectRoot {
  width: 100% !important;
}

.selectInput {
  display: flex !important;
  padding: $spacing-04 $spacing-10 $spacing-04 $spacing-05 !important;
  justify-content: space-between !important;
  align-items: center !important;
  align-self: stretch !important;
  border-radius: $spacing-02 !important;
  border: $spacing-01 solid $slate-50W !important;
  background: $dropdown-background-color !important;

  &:hover, &:active {
    border: $spacing-01 solid $slate !important
  }

  &:focus {
    outline: $spacing-01 solid $violet-20W !important;
    outline-offset: $spacing-01 !important
  }
}

.selectInputOpen {
  border: $spacing-01 solid $slate !important
}

.selectInputError, .selectInputError:hover {
  border: $spacing-01 solid $red !important;
}

.textContainer {
  display: flex;
  width: 100% !important;
  gap: $spacing-03;
  padding-top: 0;
}

.textContainerIcon {
  display: flex;
  align-items: center;
  padding: $spacing-02 0;
}

.textContainerText {
  flex: 1 0 0;
  align-items: center !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;

  @include font-settings($font-regular, $weight-regular, $line-height-regular, $global-grey, nowrap);
}

.selectionText {
  color: #4A545D !important;
}

.menuListSubheader {
  display: flex;
  flex: 1 0 0;
  background: $dropdown-background-color;
  align-items: center;
  align-self: stretch;
  padding: $spacing-04 $spacing-05;
  gap: $spacing-05;

  @include font-settings(20px, $weight-bold, $text-links-text-header);

  &:focus {
    outline: none !important;
  }

  &:focus::before {
    content: '';
    position: absolute !important;
    inset: $spacing-02 !important;
    border:  $spacing-01 solid $violet-20W !important;
    border-radius: $spacing-01;
    pointer-events: none !important;
    z-index: 0 !important
  }
}

.menuListItem {
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  white-space: normal !important;
  width: 100% !important;
  padding: $spacing-04 $spacing-05 !important;
  gap: $spacing-03 !important;
  background: $dropdown-background-color !important;

  &:hover {
    background: $violet-90W !important; 
  }
}

.menuListItemIcon {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.menuListItemText {
  display: flex;
  align-items: center;

  padding-top: 0;
  padding-bottom: 0;

  max-width: 100% !important;
  white-space: normal !important; 
  word-break: break-all !important; 
  overflow-wrap: break-word !important;
  overflow: hidden !important;

  @include font-settings($font-small, $weight-regular, $line-height-small, $dropdown-item-label);
}

.helperText {
  flex: 1 0 0;

  @include font-settings($font-small, $weight-regular, $line-height-regular, $tooltip-text-color);
}

.errorContainer {
  @include flex-center;
  align-self: stretch;
  gap: $spacing-02;
}

.errorMessageText {
  flex: 1 0 0;
  @include font-settings($font-small, $weight-regular, $line-height-regular, $red);
}

.errorInfoIcon {
  display: flex;
  align-items: center;
}

// Storybook Status Select styles
.violet circle {
  fill: $violet
}

.green circle {
  fill: $green
}

.red circle {
  fill: $red
}

.yellow circle {
  fill: $yellow
}

/* Action Dropdown Styles */

.actionDropdownBody {
  display: flex;
  min-width: $actionMenu-min-width;
  max-width: $actionMenu-max-width;
  border-radius: $spacing-02;
  outline: $spacing-01 solid $input-active-keyline;
  z-index: 1;

  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.25);;

  .actionMenuPaper {
    flex: 1 0 0;
  }

  .actionMenuList {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: $spacing-04;

    & > :first-child {
      border-radius: $spacing-02 $spacing-02 0 0;
    }

    & > :last-child {
      border-radius: 0 0 $spacing-02 $spacing-02;
    }
  }

  .actionMenuItem {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    width: 100%;
    gap: $spacing-03;
    padding: $spacing-04 $spacing-05 !important;
    
    @include font-settings(14px, $weight-regular, $tooltip-text-color);
  
    .actionMenuListItemIcon {
      @include flex-center();
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-color: $violet-90W;
    }

    &:focus-visible {
      &:not(:hover) {
        background-color: transparent !important;
      }
      &::before {
        content: '';
        position: absolute;
        inset: $spacing-02;
        border:  $spacing-01 solid $violet-20W;
        border-radius: $spacing-01;
        pointer-events: none;
        z-index: 0;
      }
    }
  }

  .actionMenuItemLink {
    @include font-settings($font-regular, $weight-medium, 150%, $digital-blue);
    text-decoration-line: underline;
  }
}
