@import "../../../../../Components/Styles/Core/variables";
@import "../../../../../Components/Styles/Core/mixins.scss";

.appBar {
  color: $slate-70B !important;
  background: $white !important;
  padding: $spacing-07 $spacing-11;
  border-bottom: 1px solid $slate-50W;
  box-shadow: none !important;
}

.transparentBar {
  background: transparent !important;
  border-bottom: none !important;
}

.tabletBar {
  padding: $spacing-07 $spacing-08 !important;
}

.toolbar {
  @include flex-center;
  justify-content: space-between;
  padding: 0;
}

.rightContainerOnly {
  justify-content: end;
}

.leftContainer {
  @include flex-center;

  .logoTitleContainer {
    @include flex-center;
    gap: $spacing-07;
  }

  .logoContainer {
    @include flex-center;
    width: 110px;
    height: 54px;
    padding: 0 2.485px $spacing-03 3px;

    .headerLogoImage {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  a {
    border-radius: $spacing-02;
    &:hover {
      color: $violet !important;
      text-decoration: none !important;
    }
    
    &:focus {
      color: $digital-blue !important;
      text-decoration-color: $digital-blue !important;
      outline: $spacing-01 solid $violet-20W;
      border-radius: $spacing-02;
    }
    
    &:active {
      color: $violet !important;
      text-decoration: none !important;
      outline: $spacing-01 solid $slate-50W;
      border-radius: $spacing-02;
    }
  }

  .skipLink {
    position: fixed;
    top: 0;
    left: $spacing-01;
    color: $white;
    background-color: $digital-blue;
    padding: $spacing-03 $spacing-05;
    text-decoration: none;
    z-index: 100;
    transform: translateY(-100%);
    transition: transform 0.3s, background-color 0.3s;
  
    &:hover {
      color: $white !important;
      text-decoration: none !important;
    }
  
    &:focus {
      color: $white !important;
      background-color: $violet;
      outline: $spacing-01 solid $violet-20W;
      border-radius: $spacing-02;
      transform: translateY(0);
      top: $spacing-01;
      left: $spacing-01;
    }
  
    &:active {
      color: $white !important;
      background-color: $slate-50W;
      outline: $spacing-01 solid $slate-50W;
      border-radius: $spacing-02;
    }
  }
}

.textWrapper {
  flex-direction: column;
}

.headerText {
  @include font-settings($font-xlarge, $weight-medium, $spacing-08);
  flex-grow: 1;
}

.rightContainer {
  @include flex-center;
  gap: $spacing-07;
}

.languageContainer {
  @include flex-center;
}

.eyebrowHeaderText {
  @include font-settings($font-small, $weight-bold, $spacing-06);
}

.link {
  @include flex-center;
  justify-content: space-between;
  text-decoration: none;
  padding: $spacing-04;
  cursor: pointer;

  p {
    @include font-settings($font-regular, $weight-medium, $spacing-07);
    padding-left: $spacing-03;
  }

  &:hover {
    p {
      color: $link-hover-active-color;
    }
    text-decoration-line: underline !important;
  }

  &:active {
    p {
      color: $link-hover-active-color !important;
    }
    text-decoration-line: underline;
    outline: $spacing-01 solid $slate-50W;
    border-radius: $border-radius;
  }

  &:focus {
    outline: $spacing-01 solid $link-focused-outline-color;
    border-radius: $spacing-02;
  }
}

.tabletLink {
  padding: $spacing-02 !important;
}

.dropdownMenu {
  border-radius: $spacing-02;
  justify-content: start;
  outline: $spacing-01 solid $input-active-keyline;
  background: $white;
  box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
  width: 200px;

  .menuList {
    margin: 0;
    padding: 0;
  }

  .menuHeaderWrapper {
    @include flex-start;
    padding: $spacing-04 $spacing-05;
    gap: $spacing-05;
  }

  .menuHeader {
    @include font-settings($font-small, $weight-bold, $spacing-06, $text-links-text-header);
  }

  .menuItem {
    @include flex-start;
    gap: $spacing-05;
    @include font-settings($font-small, $weight-regular, $spacing-06);
    padding: $spacing-04 $spacing-05;

    &:hover {
      background: $violet-90W;
    }
  }
}
