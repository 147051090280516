@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive";

.dashboardMain {
  height: 100%;
  width: 100%;
  padding-bottom: $spacing-13;
  overflow-y: scroll;

  .headerImageContainer {
    @include flex(center, unset);
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-left: $spacing-11;

    .headerTextWrapper {
      @include flex(unset, flex-start);
      flex-direction: column;
      gap: $spacing-03;
      flex: 1 0 0;
      padding-right: $spacing-08;
      width: 50%;
      height: 50%;

      .title {
        @include font-settings($spacing-09, $weight-bold, 120%);
      }
    }

    .imageWrapper {
      width: 50%;
      height: 100%;

      .dashboardMainImage {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .dashboardToolsMainContainer {
    margin-top: $spacing-08;
    margin-bottom: $spacing-11;
    padding: 0 $spacing-11;
    @include flex(flex-start, unset);
    flex-direction: column;
    gap: $spacing-08;

    .dashboardToolsHeaderTextWrapper {
      @include flex(flex-start, unset);
      flex-direction: column;
      gap: $spacing-02;

      .toolsHeader {
        @include font-settings($font-xxlarge, $weight-medium, $line-height-large, $slate-70B);
        text-align: center;
      }

      .toolsSubHeader {
        @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-30B);

        a {
          @include font-settings($font-regular, $weight-medium, $line-height-regular, $digital-blue);
          text-decoration-line: underline;
          border-radius: $spacing-02;
          display: inline-flex;
          align-items: center;

          u {
            &:hover {
              text-decoration: none !important;
            }

            &:active {
              text-decoration: none !important;
            }
          }

          &:focus {
            outline: $spacing-01 solid $violet-20W;
          }

          &:hover {
            color: $violet !important;
            text-decoration: none !important;
          }
          
          &:active {
            color: $violet !important;
            text-decoration: none !important;
            outline: $spacing-01 solid $slate-50W;
          }
        }
      }
    }
  }

  .dashboardResourcesMainContainer {
    padding: 0 $spacing-11;
    @include flex(flex-start, unset);
    flex-direction: column;
    gap: $spacing-08;

    .dashboardResourcesHeaderTextWrapper {
      @include flex(flex-start, unset);
      flex-direction: column;
      gap: $spacing-02;

      .resourcesHeader {
        @include font-settings($font-xxlarge, $weight-medium, $line-height-large, $slate-70B);
        text-align: center;
      }

      .resourcesSubHeader {
        @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-30B);
      }
    }
  }
}

@include for-tablet {
  .dashboardMain {
    .headerImageContainer {
      padding: 0 0 0 $spacing-08;

      .headerTextWrapper {
        .title {
          @include font-settings($spacing-08, $weight-bold, 150%);
        }
      }
    }

    .dashboardToolsMainContainer {
      padding: 0 $spacing-08;
    }

    .dashboardResourcesMainContainer {
      padding: 0 $spacing-08;
    }
  }
}