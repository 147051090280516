@import "src/Components/Styles/variables";

.formWrapper {
   margin: 32px 42px;
   width: calc(100% - 84px);

  div:empty {
    display: none;
  }
}

.cardItem {
  border: 1px solid $grey-20;
  margin: 24px 0;
  border-radius: 3px;

  >div:first-of-type {
    padding: 32px 32px 0;
  }

  section>div>div>div {
    margin-bottom: 2rem !important;
  }

  div:empty {
    display: none;
  }
}

.actionButtons {
  display: flex;
  justify-content: flex-start;

  &>div {
    margin: 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}


.buttonsLayout {
  display: flex;
  justify-content: space-between;

  &>div {
    display: flex;

    &:first-of-type {
      flex-grow: 2;

      &>button {
        margin: 0 8px;
        min-width: 186px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

  }
}

.showUserActive {
  display: flex;
  justify-content: flex-start;
  flex-shrink: 1;
  margin: 8px 0px;
}

.addButtons {
  margin-right: 16px;
  display: inline-block;
  width: 185px;
}

.activeValidationMessage {
  margin-bottom: 16px;
  padding: 10px;
  color: #f70707;  
}