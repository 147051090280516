@import "src/Components/Styles/variables";

.notificationActionBar {
  label:first-of-type {
    display: none;
  }
  align-items: center;
  border-bottom: $grey-20 1px solid;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.notificationActionBarTabs {
  border-bottom: none;

  & > div { margin: 24px 0;}

  ul { padding: 0; }

  select { margin-right: 32px; }
}

.notificationActionBarReadAllButton {
  align-items: center;
  background: none;
  border: $border-default;
  border-radius: $border-radius;
  display: flex;
  padding: 8px 16px;

  p { margin: 0 0 0 8px; }

  &:hover {
    background: $tint-2;
  }

}