@import '../../Styles/base';

.switch{
    @include flexbox;
    @include flex-direction(row);
    @include align-items(center);
    margin-right: 24px;
    min-width: 240px;
}

.input {
    margin-right: 16px;
    margin-top: 8px;
    position: relative;
    display: inline-block;
}

    /* Hide default HTML checkbox */
    .inputCheckbox {
        position: absolute;
        opacity: 0 !important;
        clip-path: polygon(0 0);
        left: 0;
        top: 12px;
        pointer-events: none;
        
        &:checked + .slider {
            background-color: var(--inputSliderCheckedBgColor, $tint-40);
            &:disabled {
                &:before {
                    background-color: var(--inputSliderReadOnlyColor, #9d9d9d);
                }
                background-color: var(--inputSliderReadOnlyBgColor, $grey-6);
            }
        
            &:before{
                background-color: var(--inputSliderBgColor, $global-blue);
                @include translateX(16px);
            }
        }
    }

    .label{
        line-height: $line-height-regular;
        padding-left: 40px;
        &:focus-within {
            outline: 2px solid var(--inputSliderFocus, $tint-40);
        }
    }

    .slider {
        cursor: pointer;
        background-color: var(--inputSliderUncheckedBgColor, $grey-60);
        @include border-radius(34px);
        position: absolute;
        top: 7px;
        left: 0;
        right: 0;
        bottom: 0;
        transition: .4s;
        width: 32px;
        height: 12px;
    
        &:before {
            content: "";
            background-color: $white;
            @include border-radius(50%);
            @include box-shadow(0 0 2px 0 $grey-60);
            position: absolute;
            height: 16px;
            width: 16px;
            left: 0;
            bottom: -2px;

            &:disabled{
                background-color: $grey-20;
            }
        }
    
        &:disabled{
            &:before {
                background-color: var(--inputSliderReadOnlyColor, #9d9d9d);
            }
            background-color: var(--inputSliderReadOnlyBgColor, $grey-6);
        }
      }


input[type=checkbox].switch__input-checkbox:focus {
    width: 32px;
    height: 3px;
    position: absolute;
    top: 10px;
    outline: 2px solid var(--inputSliderFocus, $tint-40);
    outline-offset: 10px;
    outline-color: $tint-40;
    opacity: 1;
}