@import "../../../Styles/Core/_variables";

.toastNotification {
    padding: $spacing-07;
    max-width: 1248px;
    min-width: 580px;
    border-radius: $spacing-02;
    display: flex;
    align-items: center;
    box-shadow: 0 $spacing-02 $spacing-03 rgba(0, 0, 0, 0.2);
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: $spacing-08;
  
    &.success {
      background-color: $green;
      color: $white;
    }
  
    &.info {
      background-color: $slate-70B;
      color: $white;
    }

    .iconWrapper {
        margin-right: $spacing-04;
        padding-top: $spacing-02;
        align-self: flex-start;
        width: $font-xxlarge;
    }
  
    .contentWrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: $spacing-02;
      padding-left: $spacing-05;
    }
  
    .header {
      font-size: $font-large;
      font-weight: $weight-medium;
      line-height: 32px;
    }
  
    .subHeader {
      font-size: $font-small;
      line-height: $line-height-regular;
    }
  
    .closeButton {
      background: none;
      border: none;
      cursor: pointer;
      color: $white;
      width: $font-xxlarge;
      height: $font-xxlarge;
      padding: 0;
      align-self: flex-start;
      margin-top: $spacing-02;

      .HbtIcon {
        width: $font-xxlarge;
        height: $font-xxlarge;
      }
    }
  }

.slideUp {
    animation: slideUp 800ms ease-in forwards;
}

@keyframes slideUp {
    from {
      opacity: 0;
      left: 50%;
      transform:translateX(-50%) translateY(100%);
    }
    60% {
        opacity: 1;
        left: 50%;
        transform:translateX(-50%)  translateY(-20%);
      }
      80% {
        left: 50%;
        transform:translateX(-50%) translateY(10%);
      }
    to {
      left: 50%;
      transform: translateX(-50%) translateY(0);
    }
}
  