.notification {
  display  : flex;
  padding  : 24px 24px 24px 16px;
  position : relative;

  &:focus {
    outline: $border-focused;
    background: #f8f8f8;
  }

  .notification__title {
    font-size     : 16px !important; //ToDo: remove important after finding the responsible package that is causing the styles overwrite
    font-weight   : bold;
    line-height   : 27px;
    margin-bottom : 8px;
  }

  .notification__text {
    font-size     : 14px;
  }

  ul {
    margin       : 0;
    padding-left : 20px;
  }

  .notification__icon {
    color : $global-blue;

    .material-icons {
      margin-right : 16px;
      width        : 24px;

      &:hover {color : inherit !important;}
    }

    &.notification__icon--success {color : $notification-success !important;}

    &.notification__icon--warning {color : $notification-warning !important;}

    &.notification__icon--error {color : $notification-error !important;}
  }

  .notification__body {flex-grow : 3;}
  .notification__body__actionButton {
    margin-left : 16px;

    .btn-icon {height: inherit !important;}
  }
}



