@import "src/Components/Styles/variables";
@import "src/Components/Styles/mixins";

.header {
    padding: 32px;
    border-bottom: 1px solid $grey-20;
    margin: 0px;

    .glossaryTitle {
        width: 90%;
    }
}

.flexAuto {
    flex: auto;
}

.flexContent {
    flex: content;
}

.btn {
    position: relative;
    top: 20px;
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0; 

    &:hover {
        cursor: pointer;
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }

    &:focus {
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }
}

.backbtn {
    @extend .btn;
    float: left;

    &:after {
        font-family: $font-family-icons;
        font-size: $font-xxlarge;
        content: "chevron_left";
        color: $black;
    }
    &:focus {
        outline: 2px solid $tint-40;
        height: 28px;
        margin-top: -12px;
    }
}


.closebtn {
    @extend .btn;
    float: right;
    

    &:after {
        font-family: $font-family-icons;
        font-size: $font-xxlarge;
        content: "close";
        color: $black;    
    }
    
    &:active, &:focus {
        outline-offset: 0.5rem;
        outline: 2px solid $tint-40;
    }

}

.categorySelectbtn {
    @extend .btn;
    top: -10px;
    float: right;

    &:after {
        font-family: $font-family-icons;
        font-size: $font-xxlarge;
        content: "chevron_right";
        color: $black;
    }
}

.scrollable {
    overflow-y: scroll;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar{
        display: none;
    }
}

.glossary{
    list-style: none;
    margin-bottom: 0;

    @include flexbox;
    @include flex-direction(column);
    @include align-self(flex-start);
    @include align-items(stretch);
    justify-content: center;
    font-family: $font-family-text;
    font-size: $font-small;
    letter-spacing: 0;
    margin-left: unset;
    width: 100%;
    padding: 0px 0;
}

.glossaryCard {
    @include flexbox;
    @include flex-direction(column);
    width: 100%;
    border-bottom: solid 1px $grey-20;
}

.glossaryList {
    padding: 0px;
}

.glossaryCategory{
    background-color: $white;
    border: 0;
    display: inline-block;
    font-family: $font-family-text;
    font-size: $font-xlarge;
    font-weight: $weight-medium;
    text-decoration: none;
    text-align: justify;
    width: 100%;
    padding: 28px 32px;

    &.hasChildren:after {
        float: right;
        font-family: $font-family-icons;
        font-size: $font-xxlarge;
        content: "chevron_right";
    }

    &:active, &:focus {
        position: relative;
        outline: 2px solid $tint-40;
    }
}

.glossaryItem {
    background-color: $white;
    display: inline-block;
    font-family: $font-family-text;
    font-size: $font-xlarge;
    font-weight: $weight-medium;
    line-height: $line-height-regular;
    color: $soft-black;
    text-decoration: none;
    width: 100%;
    padding: 28px 32px;

    &.hasChildren:after {
        float: right;
        font-family: $font-family-icons;
        font-size: $font-xxlarge;
        content: "expand_more";
    }

    &:hover {
        color: $soft-black;
    }

    &.hasChildren[aria-expanded="true"]:after {
        content: "expand_less";
    }
}

.glossaryItemContentContainer {
    background-color: $white;
    list-style: none;
    padding-left: 0;
}

.contentContainer {
    padding: 0px 32px;
    table {
      width: 100% !important;
    }
}