.checkBoxListWrapper {
  display        : flex;
  flex-direction : column;
  flex-wrap      : wrap;
  margin         : 0;
  padding        : 0;

  > div {
    margin          : 0px 16px 16px 0px;
  }
}