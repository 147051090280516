@import "src/Components/Styles/adaptive";

.icon {
    margin-top: 0.6%;
    position: absolute;
    margin-left: 1%;
}

.claimsSubmissionForm {
    margin-left: 12px;
    position: relative;
    @include desktop {
        margin-left: 0;
    }
    > div:nth-of-type(2) {
        width: 1150px;
    }
}
.paymentCenterMargin {
    margin-bottom: 24px;
    @include desktop {
        margin-bottom: 0;
    }
}
.divider {
    margin: 25px 0px;
    @include desktop {
        margin: 56px 0px;
    }
}
