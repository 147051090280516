@import 'src/Components/Styles/BaseComponentStyles/cards';

.emptyDiv {
	&Area {
		margin-bottom: 32px;
	}

	&Attachment {
		margin-bottom: -16px;
	}
}

.buttonLink {
  border: none;
  cursor: pointer;
  background: none;
  margin-left: -5px;
  text-decoration: underline;
  color: $global-blue; 

}
.attachmentText{
  color: $global-blue; 
}