@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/_variables.scss";

.formInputLabelStyle {
    font: {
        family: Roboto;
        weight: bold;
        size: 14px;
    };

    &:global(.error) {
        color: #DA291C;
    }
};

.formLabelTextStyle {
    margin: {
        bottom: 8px;
    };
    font-size: $font-small;
    @include desktop {
        font-size: $font-regular;
    }
}
