/* Remove appearance of native browser date pickers. */
input[type=date]::-webkit-calendar-picker-indicator { -webkit-appearance: none; display: none; }

.form {
    
    &.arrears-submission-form{
        padding-bottom: 40px;
    }

    .form-error {

        &__label {
            color: $notification-error;
        }

        &__field {
            color: $notification-error;
            border: $border-error;
        }

        &__text {
            color: $notification-error;
        }

        &__sub-text {
            font-size: $font-small;
            font-weight: $weight-regular;
            color: $notification-error;
            margin-left: 16px;
        }

        &__text--icon:before {
            content: "info_outline";
            font-family: $font-family-icons;
            font-size: $font-xxlarge;
            color: $notification-error;
            margin-left:-24px;
            position: absolute;
        }
    }

    /* IE10+ specific styles go here */
    @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .select-container {
            display: table;
        }

        .dropdown-select {
            display: table-row;
        }

        .select-container {
            &:after {
                display: none;
            }
        }
    }

    .select-container {
        width: 100%;
        position: relative;

        &:after {
            content: "expand_more";
            pointer-events: none;
            font-family: $font-family-icons;
            font-size: $font-regular;
            @include cross-browser-icon-support;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 14px;
            right: 16px;

        }
    }

    select, input, textarea {
        font-size: $font-small;
        border: $border-default;
        @include border-radius(4px);
        height: min-content;
        padding: 12px 16px;

        &:focus {
            @include box-shadow(inset 0px 0px 0px 2px $tint-40);
            box-sizing: border-box;
            outline-color: transparent;
        }

        &:disabled {
            background-color: $grey-6;
            border-color: $grey-20;
            opacity: 1;
        }
    }

    select {
        &:not(.react-datepicker__year-select){
            @include appearance(none);
        }
        &.select-element:focus {
            border-color: $tint-40;
            box-shadow: 0 0 1px 1px $tint-40;
            box-shadow: 0 0 0 1px -moz-mac-focusring;
            color: $soft-black;
            outline: none;
            background-color: $white
        }

        &.select-element:hover {
            border-color: $grey-20;
            background-color: $grey-3;
        }

        &.select-element:disabled:hover {
            border-color: $grey-20;
            background-color: $grey-6;
        }

        &.select-element option:checked,
        &.select-element option:hover {
            box-shadow: 0 0 10px 100px $shade-30 inset;
        }

        & option {
            font-family: $font-family-text;
        }
    }

    textarea {
        height: 160px;
    }

    &__radio-group {
        margin-top: 8px;
    }

    &__radio-group-horizontal {
        label {
            margin-right: 16px;
        }
    }

    input{
        &:checked {
            &:before {
                background-color: transparent;
            }
        }

        &[type=radio] {
            display: inline;
            margin-right: 8px;

            &:not(:first-child) {
                margin-left: 16px;
            }

            &:after {
                width: 16px;
                height: 16px;
                border-radius: 16px;
                top: -2px;
                left: -1px;
                position: relative;
                background-color: $white;
                content: '';
                display: inline-block;
                visibility: visible;
                border: 2px solid $soft-black;
            }
            &:checked {
                &:after {
                    width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    top: -2px;
                    left: -1px;
                    position: relative;
                    background-color: $global-blue;
                    content: '';
                    display: inline-block;
                    visibility: visible;
                    border: 2px solid $global-blue;
                }
            }

            &:disabled {
                &:after {
                    border-color: $grey-20;
                    background-color: $grey-6;
                }
            }
        }
    }

    .date {

        &__container {
            height: 100%;


            &--range {
                &:after {
                    content: "";
                }
            }
        }

        &__picker {
            height: 100%;
        }

        &__picker-range-separator{
            padding: 12px 24px;
            height: min-content;
        }
    }

    label, legend {
        font-size: $font-regular;
        font-weight: $weight-bold;
        margin-bottom: 0px;

        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }

    .form__label--muted, .form__legend--muted {
        font-weight: $weight-regular;
    }

    &__text-area-limit {
        font-size: $font-small;
        text-align: right;
        letter-spacing: 0;
        line-height: $line-height-regular;
        @include align-self(flex-end);
    }

    &__element {
        @include flex-direction(column);
    }

    &__element--inventory {
        @include flex-direction(row);
    }

    &__element, &__element--inventory {
        @include flexbox;

        &:not(:first-child){
            margin-top: 32px;
        }

        &--checkbox {
            @include flex-direction(row);

            input[type=checkbox]{
                @include align-self(flex-start);
                margin-right: 0px;
                margin-top: 4px;
                width: 0px;
            }

            & label {
                margin-bottom: 0;
            }
        }

        .subHeading {
            font-size: 14px;
            font-weight: 400;
        }


        &-checkbox-container {
            width: 100%;
        }

        &-checkbox-container-label {
            margin-bottom: 24px;

            .form__element-sub-label {
                font-weight: $weight-regular;
                line-height: $line-height-regular;
                letter-spacing: 0;
            }
        }



        small {
            margin-bottom: 10px;
            font-style: italic;
        }


        &-sub-label {
            margin-top: 24px;

            &:first-child {
                margin-top: 0;
            }

            &--emphasized {
                font-style: italic;
                font-size: $font-small;
                line-height: $line-height-regular;
                margin-top: 8px;
            }
        }

        &--attachment {
            height:100%
        }

        &--2-column {
            &:first-child, &:nth-child(2) {
                margin-top: 0;
            }
        }

        &-range-separator{
            padding: 12px 24px;
            height: min-content;
        }

        .form__attachment {
            text-align: center;
            border: $border-thick-dashed;
            @include border-radius(4px);
            @include flexbox;
            @include flex-direction(column);
            justify-content: center;
            @include align-items(center);
            padding: 24px;
            height: 100%;
            
            &.form-error {
                color: $notification-error;
                border: $border-error;
            }

            &-icon {
                width: 64px;
                height: 64px;
            }

            &-text {
                font-weight: $weight-bold;
                font-size: $font-regular;
                display: inline-flex;
            }
            &-sub-text {
                font-weight: $weight-regular;
                font-size: $font-small;
            }

            &-manual-upload {
                cursor: pointer;
                color: transparent;
                border: none;
                display: inline;
                max-width: 62px;
                max-height: 24px;
                padding: 0 6px;
                position: absolute;
                left: -99999rem;

                &:focus + .upload-span {
                    outline: $border-focused;
                }

                &::-webkit-file-upload-button {
                    visibility: hidden;
                }
                &::before {
                    content: 'upload';
                    @include user-select(none);
                    font-weight: $weight-bold;
                    font-size: $font-regular;
                    white-space: nowrap;
                    color: $global-blue;
                    outline: none;
                    display: inline-block;
                    padding: 0;
                    text-decoration: underline;
                }
                &:hover::before {
                    border-color: $grey-80;
                }
                &:active {
                    outline: 0;
                }
            }
        }
    }

    &__element--range--to {
        margin: auto;
    }

    .defaultFormContents {
        padding-top: 24px;
    }

    .checkboxBlock {
        &__section-showContent {
            margin-left: 32px;
            padding-top: 1rem;
        }

        &__contentRow {
            margin-bottom: 24px !important;
          }
    }

    /* IE10+ specific styles go here */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        _:-ms-lang(x), fieldset[disabled] {
            pointer-events: none;

            & .card, & .card__header {
                background-color: $grey-3;
            }
            // & input[type="text"], & textarea, & select {
            //     background-color: $grey-20;
            // }

            & .checkbox {
                & label {
                    &::before{
                        content: "";
                        background-color: $grey-20;
                        border: 1px solid $grey-20;
                    }

                    &::after {
                        border-color: $grey-20;
                        background-color: $grey-20;
                        color: $grey-60;
                    }
                }
            }
        }
    }

    fieldset{
        & small {
            display: block;
        }
        &:disabled {
            pointer-events: none;

            & .card, & .card__header {
                background-color: $grey-3;
            }
            // & input[type="text"], & textarea {
            //     background-color: $grey-20;
            // }

            & .checkbox {
                & label {
                    &::before{
                        content: "";
                        background-color: $grey-20;
                        border: 1px solid $grey-20;
                    }

                    &::after {
                        border-color: $grey-20;
                        background-color: $grey-20;
                        color: $grey-60;
                    }
                }
            }
        }
    }

    &__fieldNotificationHolder {
        height: 44px;
    }
}

.checkbox {
    & input[type="checkbox"] {
        opacity: 0;

        & + label::after {
            content: none;
        }
        &:checked + label::after {
            content: "\e5ca";
        }
        &:focus + label::before {
            border: none;
          }
    }
    & label {
        position: relative;
        margin-left: 32px;
        margin-bottom: 0px;

        &:not(:last-child) {
            margin-bottom: .5rem;
        }

        &::before{
            content: "";
            display: inline-block;
            height: 16px;
            width: 16px;
            background-color: transparent;
            border: 1px solid  $soft-black;
            border-radius: 2px;
            margin-left: -32px;
            position: absolute;
            top: 3px;
        }
        &::after {
            font-family: 'Material Icons';
            content: "\e5ca";
            display: inline-block;
            height: 20px;
            width: 16px;
            background-color: $global-blue;
            border-radius: 2px;
            position: absolute;
            color: $white;
            left: -32px;
            top: -1px;
            clip: rect(4px,16px,20px,0px);
        }
    }
    &:disabled {
        & label {
            &:before{
                border-color: $grey-20;
                background-color: $grey-6;
            }
        }
    }
}

.input-upload-label {
    .upload-span {
        cursor: pointer;
        font-weight: $weight-bold;
        font-size: $font-regular;
        white-space: nowrap;
        color: $global-blue;
        outline: none;
        display: inline-block;
        padding: 0;
        margin-left: 5px;
        margin-right: 5px;
        text-decoration: underline;
        text-transform: lowercase;
    }
}
.form__divider {
    border-bottom: 1rem #000000;
}
.default-submission-form__lender-site-internal-wrapper {
    margin-bottom: -24px
}

fieldset .card__body-row:not(:last-child) .card__body-checkboxSection {
    margin-bottom: 40px;
}

.footer-buttons {
    align-items: center;
    display: flex;
    justify-content: space-around;
}
.subText {
    margin-top: -6px;
    display: block;
    font-size: 12px;
    color: #656565
  }
.form-displaybox {
     display: -webkit-inline-box;
  }