@import "../../../../../../../Components/Styles/variables";
@import "../../styles.module";
@import "src/Components/Styles/adaptive";


.content {
  width: 100%;
}

.dataTable {
  display: block;

  table {
    table-layout: fixed;
    width: 150%;

    @include desktop {
      width: 100%;
    } 
  }

  th {
    white-space: normal;
    width: 11.5%;

    @include desktop {
      &:first-of-type {
        width: 19.5%;
      }
    }

    &:last-of-type {
      width: 5%;
    }
  }

  tbody td {
    vertical-align: top;

    & > span {
      margin-bottom: 4px;
    }
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  tbody tr td span:nth-child(2) {
    color: $black;
    font-size: 14px;
  }

  tbody tr td:last-child {
    padding-right: 32px;
    text-align: right;
  }


}

.tableInput {
  @extend .tableStandardInput;

  margin: 4px 0;
}

.trendValueWrapper {
  display: block;
  position: relative;
}

.trendValueWrapperActive {
  display: block;
  position: relative;
  
  span {
   margin-left: -16px;
  } 
}

.adjustedValueWrapper {
  margin: 16px;
}

.fieldset {

  label {
    margin: 0;
  }

  input:read-only {
    background: none !important;
    padding: 0 8px !important;
  }
}

.tableInput {
  @extend .tableStandardInput;
}
