i {
    &.material-icons{
        @include cross-browser-icon-support;
    }

    &.icon--v-align-middle {
        vertical-align: middle;
    }

    &:hover {
        color: $grey-60;
    }

    &:disabled {
        color: $grey-20;
    }

    &:focus {
        border: $border-focused;
    }
}

.icon--size-16 { font-size: 16px; }
.icon--size-24 { font-size: 24px; }
.icon--size-32 { font-size: 32px; }
.icon--size-40 { font-size: 40px; }

.icon--dark {
    color: $soft-black;
}

// Image Icons ====================================================================================================================

.icon-16 {
    width: 16px;
    height:16px;
    padding: 1px;
}

.icon-24 {
    width: 24px;
    height:24px;
    padding: 2px;
}

.icon-32 {
    width: 32px;
    height:32px;
    padding: 2px;
}

.icon-40 {
    width: 40px;
    height:40px;
    padding: 2px;
}
