@import "src/Components/Styles/variables";

.modal {
  display  : block;
  height   : 100vh;
  left     : 0;
  position : fixed;
  top      : 0;
  width    : 100vw;
  z-index  : 100;
}

.modalBg {
  background      : rgba(0, 0, 0, 0.75);
  height          : 100%;
  left            : 0;
  position        : absolute;
  top             : 0;
  width           : 100%;
  z-index         : 101;

}

.modalCard {
    left: 50%;
    padding: 32px;
    position: absolute;
    top: 30vh;
    transform: translateX(-50%);
    width: 627px;
    z-index: 102;
    background: $white;
    border-radius: 4px;
  }
    .modalHeader {
      align-items : flex-start;
      display     : flex;
      padding     : 0;

      .modalHeaderIcon {
        flex-shrink : 1;
        margin-right  : 16px;
  
        .materialIcons {
          color : inherit;
        }
      }
  
      .modalHeaderTitle {
        flex-grow : 3;
  
        h2 {margin : 8px 16px;}
      }
    }
  
    .modalBody {
      margin-bottom : 40px;
      padding       : 0;
    }
  
    .modalFooterButtons {
      align-items     : center;
      display         : inline-flex;
      justify-content : space-between;
      margin-top: 32px;

      button {
        margin-right : 16px;
        padding: 10px 59px;
      }
      
      .modalFooterLinkButton {
        .btn {
          color   : $global-blue;
          padding : 0;
        }
  
        .btn__content-wrapper {
          justify-content : flex-start;
        }
      }
    }
  
.modalContent {
    margin-top: 15px;
  }

.closeIconWrapper {
  border: none;
  background: none;
}

.iconDefault {color : $global-blue;}
.iconError  {color : $notification-error;}
.iconSuccess {color : $notification-success; }
.iconWarning {
	color: $notification-warning;
	i:hover {
		color: $notification-warning;
	}
}
.iconInactive  {color : $grey-20;}
.iconCancel  {color : $global-blue;}

@keyframes spin {
  0% {transform : rotate(0deg)}
  100% {transform : rotate(360deg)}
}

.saveIconWrapper {
  position : absolute;
  & > span {
    animation : spin 0.9s linear infinite reverse;
    left      : -28px;
    position  : absolute;
  }
}