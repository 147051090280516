// Common styles
@mixin form-field-hover {
  border: 2px solid $slate !important;
}

@mixin form-field-focus($focus-outline) {
  outline: 2.5px solid $focus-outline !important;
  outline-offset: 2.5px !important;
}

@mixin form-font-style {
  font-size: $font-small !important;
  font-style: normal !important;
  line-height: $line-height-small !important;
  font-feature-settings: "clig" off, "liga" off;
}

@mixin form-helper($color) {
  align-items: $form-field-helper-error-align-items;
  color: $color;
  font-feature-settings: "clig" off, "liga" off;
  font-size: $form-field-helper-font-size !important;
  font-style: $form-field-helper-font-style !important;
}
