:root {
  //colours=============================================================
  --White: #ffffff;
  --Slate: #697885;
  --Slate-90W: #f0f2f3;
  --Slate-50W: #b4bcc2;
  --Slate-30B: #4a545d;
  --Red: #da291c;
  --Violet-20W: #6D65D7;
  --Violet-50W: #a49fe6;
  --Violet-90W: #edecfa;

  --Text-Links-Body: #454e56;

  //spacing=============================================================
  --Spacing-01: 2px;
  --Spacing-02: 4px;
  --Spacing-03: 8px;
  --Spacing-04: 12px;
  --Spacing-05: 16px;
  --Spacing-07: 24px;

  //typography==========================================================
  --Font-Family-Text: 'Roboto', sans-serif;
  --Weight-Regular: 400;
  --Weight-Bold: 700;

  --Font-Small: 14px;
  --Font-Regular: 16px;

  --Line-Height-Small: 20px;
  --Line-Height-Regular: 24px;
}
