@import 'src/Components/Styles/adaptive';
@import "src/Components/Styles/variables";


.listingSideDrawer {
  display: grid;
  grid-template-rows: 89px 76px auto;
  height: 100vh;
}

.sideDrawer, .contentSideDrawer {
  @include tablet {
    width: 50%;
    @media only screen and (max-width: $tablet-width) and (orientation : portrait) {
       width: 85%; 
    }  
  }

  width: 517px ;
}

.contentSideDrawer {
  position: absolute;
  left: -100%
}

.missingNotificationMessage {
  margin: auto;
}