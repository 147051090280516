@import "src/Components/Styles/adaptive";

.tabContent {
  margin: 0
}

.tabs {
  @include tablet {
    margin-top: -48px;

    & > div > div {
      max-width: 634px;
    }
  }
}
.externalTabsWidth {
  @include tablet {
    margin-top: -48px;

    & > div > div {
      max-width: 656px;
    }
  }
}