@import 'Project/Website/styles/_layout.scss';

.half {
  width: calc(50% - #{$gutter-width / 2});
  padding: {
      bottom: $gutter-width / 2;
  };

  &Left {
      @extend .half;
      margin: {
          right: $gutter-width;
      };
  }
}

.full {
  width: 100%;
  padding: {
      bottom: $gutter-width / 2;
  };
}