@import "../../Styles/Core/variables.scss";

$primary-spinner-first-ring-color: $violet;
$primary-spinner-second-ring-color: $slate-90W;

$secondary-spinner-first-ring-color: $white;
$secondary-spinner-second-ring-color: #6B7885;

$spinner-stroke-width-small: 3px;
$spinner-stroke-width-medium: 4px;
$spinner-stroke-width-large: 7px;
