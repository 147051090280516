.dashboard {
    &__item {
        &--body {
            padding: 0 4px 4px;
        }
    }
}

.card-modified {
    &__body-icon {
      width: 24px;
      height: 24px;
      padding-right: 4px;
    }
  
    &__body-list-link {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 12px;
      &:hover, &:active {
        color: $shade-30;
        text-decoration: underline;
      }
    }
}

.card-footer-content {
  color: $global-blue;
  &:hover, &:active {
    color: $shade-30;
    text-decoration: underline;
  }
}