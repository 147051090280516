@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.fileTransferToastMsgContainer {
  padding: 0 $spacing-11 40px $spacing-11;
}

.fileTransferMainBody {
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
  padding: 0 $spacing-11 $spacing-13 $spacing-11;
  min-height: calc(100vh - 350px);

  .fileTransferMainContainer {
    @include flex(flex-start, unset);
    flex-direction: column;
    padding: $spacing-08 0;
    border-radius: $spacing-02;
    border: 1px solid $slate-50W;
    background: $white;
    width: 100%;

    .fileTransferMainContent {
      @include flex(flex-start, space-between);
      flex-direction: row;
      gap: $spacing-08;
      width: 100%;
      padding: 0 $spacing-08 $spacing-08 $spacing-08;
  
      .fileTransferHeaderContainer {
        .fileTransferHeaderContent {
          @include flex(flex-start, unset);
          flex-direction: column;
          align-self: stretch;
          gap: $spacing-03;
    
          .fileTransferHeaderTitleInfoWrapper {
            @include flex(flex-start, unset);
            flex-direction: column;
            align-self: stretch;
            gap: $spacing-03;
    
            .fileTransferTitle {
              @include font-settings($font-xxlarge, $weight-medium, $line-height-large, $slate-70B);
              text-align: center;
            }
    
            .fileTransferTitleInfo {
              @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-70B);
            }
          }
        }

        .cmhcSupportContainer {
          @include flex(flex-start, unset);
          flex-direction: column;
          align-self: stretch;
          gap: $spacing-02;
        
          .cmhcSupportListContainer {
            margin: 0;
            padding-inline-start: $spacing-05;
            color: $slate-30B;
            animation: easeIn 0.1s;

            .piFileTransferUserGuideContent {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-30B);
  
              a {
                @include font-settings($font-regular, $weight-medium, $line-height-regular, $digital-blue);
                text-decoration: underline;
                border-radius: $spacing-02;
                display: inline-flex;
                align-items: center;

                .piFileTransferUserGuideExternalIconLink {
                  display: inline;
                  align-self: center;
                  margin-left: $spacing-03;
                }

                &::after {
                  content: none;
                }

                &:hover {
                  color: $violet !important;
                  text-decoration: none !important;
                }
                
                &:focus {
                  color: $digital-blue !important;
                  text-decoration-color: $digital-blue !important;
                  outline: $spacing-01 solid $violet-20W;
                  border-radius: $spacing-02;
                }
                
                &:active {
                  color: $violet !important;
                  text-decoration: none !important;
                  outline: $spacing-01 solid $slate-50W;
                  border-radius: $spacing-02;
                }
              }
            }
            .needHelpInfoContent {
              @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-30B);

              a {
                @include font-settings($font-regular, $weight-medium, $line-height-regular, $digital-blue);
                text-decoration-line: underline;
                border-radius: $spacing-02;
                display: inline-flex;
                align-items: center;

                u {
                  &:hover {
                    text-decoration: none !important;
                  }

                  &:active {
                    text-decoration: none !important;
                  }
                }

                &:hover {
                  color: $violet !important;
                  text-decoration: none !important;
                }
                
                &:focus {
                  color: $digital-blue !important;
                  text-decoration-color: $digital-blue !important;
                  outline: $spacing-01 solid $violet-20W;
                  border-radius: $spacing-02;
                }
                
                &:active {
                  color: $violet !important;
                  text-decoration: none !important;
                  outline: $spacing-01 solid $slate-50W;
                  border-radius: $spacing-02;
                }
              }
            }
          }
        }
      }
    }

    .fileTransferTabContainer {
      @include flex(flex-start, unset);
      flex-direction: column;
      align-self: stretch;
      padding: 0 $spacing-08;
      gap: $spacing-08;
    }
  }
}

@keyframes easeIn {
  100% {
    opacity: 1
  }

  90% {
    opacity: 0.9
  }

  80% {
    opacity: 0.8
  }

  70% {
    opacity: 0.7
  }

  60% {
    opacity: 0.6
  }

  50% {
    opacity: 0.5
  }

  40% {
    opacity: 0.4
  }

  30% {
    opacity: 0.3
  }

  20% {
    opacity: 0.2
  }

  10% {
    opacity: 0.1
  }

  0% {
    opacity: 0
  }
}

@include for-tablet {
  .fileTransferMainBody {
    padding: 0 $spacing-08 $spacing-13 $spacing-08;

    .fileTransferMainContainer {  
      .fileTransferMainContent {
        flex-direction: column;
      }
    }
  }
}