@import 'Project/Website/styles/main.scss';

/* Win the specificity fight for the global style... */
:global(.form) {
    .form-wrapper :global(textarea) {
        // Resets:
        height: auto;

        // New for component:
        resize: none;
    }
}

.add-button {
    display: flex;
    background-color: $white;
    border: 1px solid $grey-20;
    border-radius: 4px;
    padding: {
        left: 4px;
        top: 4px;
        right: 16px;
        bottom: 4px;
    }
    text-align: center;

    font: {
        family: $font-family-text;
        size: $font-small;
    }

    & > span:first-child {
        margin: {
            right: 8px;
        }
    }
}

.update-button {
    display: flex;
    background-color: $global-blue;
    border: none;
    color: $white;
    padding: 12px 24px;
    border-radius: 4px;
    font: {
        family: $font-family-text;
        size: $font-small;
    }
}

.cancel-button {
    border: none;
    background-color: transparent;
    color: $global-blue;
    font: {
        family: $font-family-text;
        size: $font-small;
    }
    margin: {
        left: 22px;
    }
}

.collapsed-label {
    font: {
        family: $font-family-text;
        size: $font-small;
        weight: bold;
    }
    margin: {
        right: 18px;
    }
}

.display-only-input {
    border: none;
    background-color: transparent;
    font: {
        family: $font-family-text;
        size: $font-small;
    }
}