@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.card {
    padding : 32px 32px;
}

.section {
    padding-right: 0px;
    margin-top: 0px;
}

.layoutContainer {
    display               : grid;
    grid-template-columns : 2fr;
    grid-template-rows    : auto;
    grid-auto-flow        : dense;
}

.templateList {
    list-style-type: none;
    margin: 24px 0 0 0;
    padding: 0;
}

.templateListItem {
    display: inline-block;
    padding-right: 24px;
    text-decoration: underline;

    @include desktop {
        padding-right: 16px;
        &:last-of-type span {
            margin-left: 0px !important;
        }
    }
    
}

.showForDesktopOnly {
    display: none;

    @include desktop {
        display: block;
    }
}

.hideForDesktop {
    display: block;

    @include desktop {
        display: none;
    }
}