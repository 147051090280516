@import "src/Components/Styles/variables";

.formWrapper {
  margin : 32px;
}

.formHeader {
  align-items     : center;
  border-bottom   : $grey-20 1px solid;
  display         : flex;
  justify-content : space-between;
  margin          : 0px -32px;
  padding         : 0 32px 32px;

  & > h2 {
    color       : $soft-black;
    font-size   : $font-xxlarge;
    line-height : $line-height-regular;
    margin      : 0;
  }

  & > button {
    background  : none;
    border      : none;
    font-size   : $font-xxlarge;
    line-height : 0;
  }
}

.formBody {
  margin : 32px 0;

  input[type="text"] {
    border        : $grey-20 1px solid;
    border-radius : 4px;
    font-size     : $font-regular;
    padding       : 12px 16px;
  }

  textarea {
    border        : $border-default;
    border-radius : 4px;
    height        : 160px;
  }
}

.formFooter {
  border-top  : $grey-20 1px solid;
  display     : flex;
  padding-top : 32px;

  & > button {
    margin : 8px;

    &:first-child { margin-left : 0; }

    &:last-child { margin-right : 0}
  }
}