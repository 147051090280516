.content-loading-modal__overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: 0;
  background-color: rgba( $soft-black, .65 );
  overflow-x: hidden;
  transition: 0.5s;
}

.loading-modal {
  margin: 170px auto auto auto;
  height: 480px;
  width: 627px;
  border-radius: 8px;
  background-color: $white;
}

.loading-icon {
  padding-top: 5px;
  text-align: center;
}

.spinning-loader--wrapper {
  margin: 0;
}

.loading-title {
  margin-top: 24px;
  color: $soft-black;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}

.loading-description {
  margin: 8px auto auto auto;
  color: $soft-black;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: 407px;
  text-align: center;
}