@import "../../Styles/Core/variables.scss";

.tagContainerStatic {
    padding: 0 $spacing-05 !important;
    background-color: $slate-95W !important;
}

.tagGroupContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: $spacing-05 0;
    gap: $spacing-03 $spacing-04;
    width: inherit;
}

.containerTestStorybook {
    width: 500px;
}

.containerStatic{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $slate-95W;
    border-radius: $spacing-05;
    padding: $spacing-02 $spacing-05;
    border: 1px solid $slate-80W;
     
    .labelStyle{
        padding: 0;
    }

    &:focus{
        outline: $spacing-01 solid $violet-20W;
        outline-offset: $spacing-01;
    }
}

.containerInteractive {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $slate-95W;
    border-radius: $spacing-05;
    padding: $spacing-02 $spacing-04;
    border: 1px solid $slate-80W;
    margin-left: 0 !important;
    gap: $spacing-04;

    &:hover {
        border-color: $violet;

        .labelStyle{
            color: $violet;
        }

    }

    &:active {
        box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25);
        border-color: $slate;
        .labelStyle{
            color: $violet;
        }

    }

    &:focus{
        outline: $spacing-01 solid $violet-20W;
        outline-offset: $spacing-01;
    }
}

.containerInteractive>div {
    display: flex;
    align-items: center;
    margin: 0;
}

.labelStyle {
    padding-right: $spacing-02;
    padding-left: $spacing-02;
    font-family: $font-family-text;
    font-size: $font-small;
    font-weight: $weight-regular;
    line-height: $line-height-small;
    color: $slate-30B;
}

.checkStyle{
    padding-right: $spacing-02;
}

.closeStyle {
    cursor: pointer;
}
