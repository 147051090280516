$desktop-width: 1286px;
$desktop-breakpoint: 1200px;
$tablet-width: 768px;

@mixin tablet {
  @media screen and (max-width: #{$desktop-width - 1px}) {
    @content;    
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}), screen and (min-width: #{$desktop-breakpoint}) and (-webkit-min-device-pixel-ratio: 1.25)  {
    @content;
  }
}

.page-wrapper {
  width: 100%;
  min-width: $tablet-width;
  margin: 0 auto;
  padding: 0 12px;

  @include desktop {
    max-width: $desktop-width;
  }
}

.ssr-layout{
  display: none !important;
}
