@import 'Project/Website/styles/main.scss';
@import "src/Components/Styles/variables";
.customOption {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 4px;
    
    label{
        font-weight: normal;
        padding-left: 4px;
    }
    /* Adjust the gap as needed */

    input[type="checkbox"] {
        padding: 8px 8px;
        margin: 12px;
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #ccc;
        border-radius: 1px;
        background-color: #fff;
        margin-right: 4px;
        /* Adjust the margin as needed */
    }

    input[type="checkbox"]:checked {
        background-color: $global-blue;
            border: 1px solid $global-blue;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='white'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 13l4 4L19 7' /%3E%3C/svg%3E");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.selectInput {
    width: 100%;
}