@import "src/Components/Styles/variables";

.notificationButton {
  align-items: center;
  background: 0;
  border: 0;
  display: flex;

  p {
    font-size: $font-small;
    font-weight: bold;
    margin: 0 0 0 8px;
  }
}

.notificationIcon {
  margin-top: 2px;
  position: relative;

  &.hasNew > span {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: bellRing;
    animation-timing-function: ease-in-out;
    transform-origin: top center;
  }

  &.hasNew:after {
    animation-delay: 1s;
    animation-duration: 0.75s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: notificationDot;
    animation-timing-function: ease-in-out;
    background: $notification-error;
    border: $white 1px solid;
    border-radius: 50%;
    content: "";
    display: block;
    height: 8px;
    position: absolute;
    right: 1px;
    top: 8px;
    width: 8px;
  }
}

@keyframes notificationDot {
  0% {
    transform: scale(0);
  }

  75% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1.00);
  }

}

@keyframes bellRing {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(25deg);
  }

  50% {
    transform: rotate(-20deg);
  }

  75% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0);
  }
}

