@import "../../../../../../../Components/Styles/variables";

.assignmentUserIconContainer {
  margin: 2px -4px 4px 8px;
  line-height: 0;
}


.assignmentDropdown {
  width: 300px;
}

.inlineLabel {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.labelText {
  font-size: $font-small;
  font-weight: bold;
  margin-right: 8px;
}
