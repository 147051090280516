@import "src/Components/Styles/variables";

.checkboxContainer {
  box-sizing: border-box;
  position: relative;

  label {
    font-weight: 400;

    & > span {
      display: block;
      margin-left: 30px;
      max-width: 250px;
    }

    &::before {
      border: $soft-black 1px solid;
      border-radius: 2px;
      content: "";
      display: block;
      height: 16px;
      position: absolute;
      top: 3px;
      width: 16px;
    }

    &::after {
      border-bottom: $white 2px solid;
      border-left: $white 2px solid;
      content: "";
      display: block;
      height: 6px;
      left: 2px;
      position: absolute;
      top: 6px;
      transform: rotate(-45deg);
      width: 12px;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  & input[type="checkbox"] + label::after {
    content: none;
  }

  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  input[type="checkbox"]:checked + label::before {
    background: $global-blue;
    border-color: $global-blue;
  }

  input[type="checkbox"][readonly] + label::before,
  input[type="checkbox"]:disabled + label::before,
  input[type="checkbox"][readonly]:checked + label::before,
  input[type="checkbox"]:checked:disabled + label::before {
    background: $grey-20;
    border: 1px solid $grey-20;
  }

  input[type="checkbox"][readonly]:checked + label::after,
  input[type="checkbox"]:checked:disabled + label::after {
    border-bottom: $soft-black 1.5px solid;
    border-left: $soft-black 1.5px solid;
    content: "";
    display: block;
    position: absolute;
    height: 6px;
    width: 12px;
    top: 6px;
    left: 2px;
    transform: rotate(-45deg);
    
  }

  input[type="checkbox"]:focus + label::before {
    display: block;
    border-color: $global-blue;
  }

  :global(fieldset.error) & {
    input[type="checkbox"] + label::before {
      border-color: $notification-error;
    }
  }
}
