@import "../../../../../../Components/Styles/variables";

.cardTitleWrapper {
  font-size: 24px;
  line-height: 40px;
  display: block;
}

.preTitle {
  display : block;
  font-size: 16px;
  color: $grey-70;
  font-weight: bold;
  margin-bottom: -8px;
}