@import 'Components/Styles/Core/variables';
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";
@import './HbtLoginPageVariables.scss';

.Welcome {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;

  .skipLink {
    position: absolute;
    top: 0;
    left: 10px;
    color: $white;
    background-color: $digital-blue;
    padding: $spacing-03 $spacing-05;
    text-decoration: none;
    z-index: 100;
    transform: translateY(-100%);
    transition: transform 0.3s, background-color 0.3s;
  
    &:hover {
      color: $white !important;
      text-decoration: none !important;
    }
  
    &:focus {
      color: $white;
      background-color: $violet;
      outline: $spacing-01 solid $violet-20W;
      border-radius: $spacing-02;
      transform: translateY(0);
    }
  
    &:active {
      color: $white;
      background-color: $slate-50W;
      outline: $spacing-01 solid $slate-50W;
      border-radius: $spacing-02;
    }
  }  


  .LanguageContainer {
    background: transparent;
    @include flex(center, flex-end);
    color: $slate-70B;
    padding: $spacing-07 $spacing-11;
    width: 100%;
    position: fixed;
    z-index: 30;
  }

  .leftContainer {
    position: relative;
    width: 55%;
    height: 100vh;

    .loginMainImageBg {
      display: block;
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    .overlayWomanImage {
      position: absolute;
      transform: scale(1);
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    .rightBorderContainer {
      position: absolute;
      right: -75%;
      border: $desktopKeyLineBorderWidth;
      border-bottom: 0;
      background-color: transparent;
      color: transparent;
      height: 70%;
      width: calc(45vw + calc(55vw * 0.25) - 96px);

      div {
        width: 100%;
      }

      .borderChildOne {
        border: $desktopKeyLineBorderWidth solid $red;
        background: transparent;
        border-bottom: 0;
      }

      .borderChildTwo {
        position: relative;
        border: $desktopKeyLineBorderWidth solid;
        border-right-color: $red;
        border-top: 0;
        border-bottom: 0;
        height: 120px;
      }

      .borderChildThree {
        border-left: $desktopKeyLineBorderWidth solid $red;
        border-right: $desktopKeyLineBorderWidth solid $red;
      }
    }

    .borderLogo {
      position: absolute;
      left: -50.5px;
      height: 100%;
      transform: scale(1.01);
    }
  }

  .rightContainer {
    position: relative;
    width: 45%;
    display: flex;
    align-items: center;
    height: calc(100vh - 198px);
    top: 100px;

    .loginContainer {
      z-index: 1;
      @include flex(flex-start, unset !important);
      flex-direction: column;
      gap: $spacing-05;
      position: absolute;
      padding: 0 80px;
      width: calc(45vw - 64px);

      .loginTextWrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-03;

        h2,
        h4 {
          margin: 0;
        }

        .title {
          background: linear-gradient(127deg, $navy 25.71%, $red 74.29%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include font-settings($spacing-10, $weight-bold, 120%);
          font-family: $font-family-text;
          text-wrap: balance !important;
        }

        .subTitle {
          @include font-settings($font-large, $weight-medium, $spacing-08, $text-links-text-header);
          font-family: $font-family-text;
        }
      }

      .loginBtn {
        padding-top: $spacing-07;
      }

      .loginHelpBtnContainer {
        padding-top: $spacing-03;

        .loginHelpBtn {
          max-width: unset;
          text-align: left;
        }
      }
    }
  }

  .loginHelpContainer {
    z-index: 13;
    position: fixed;
    left: 0;
    @include flex(flex-start, space-between);
    flex-direction: row;
    gap: 160px;
    padding: $spacing-09 $spacing-11;
    opacity: 0.95;
    background-color: $white;
    width: 100%;
    border-bottom: 1px solid $slate-50W;
  }

  .helpContainerSlideUp {
    bottom: 100px;
    animation: slideup 0.2s;
    transition: transform 0.2s ease-in-out;
  }

  @keyframes slideup {
    0% {
      bottom: 0
    }

    10% {
      bottom: 10px
    }

    20% {
      bottom: 20px
    }

    30% {
      bottom: 30px
    }

    40% {
      bottom: 40px
    }

    50% {
      bottom: 50px
    }

    60% {
      bottom: 60px
    }

    70% {
      bottom: 70px
    }

    80% {
      bottom: 80px
    }

    90% {
      bottom: 90px
    }

    100% {
      bottom: 100px
    }
  }

  .helpContainerSlideDown {
    bottom: 0;
    animation: slideDown 0.2s;
    transition: transform 0.2s ease-in-out;
  }

  @keyframes slideDown {
    100% {
      bottom: 0
    }

    90% {
      bottom: 10px
    }

    80% {
      bottom: 20px
    }

    70% {
      bottom: 30px
    }

    60% {
      bottom: 40px
    }

    50% {
      bottom: 50px
    }

    40% {
      bottom: 60px
    }

    30% {
      bottom: 70px
    }

    20% {
      bottom: 80px
    }

    10% {
      bottom: 90px
    }

    0% {
      bottom: 100px
    }
  }

  .helpTextWrapper {
    @include flex(flex-start, space-between);
    flex-direction: row;
    gap: 160px;
    flex: 1 0 0;

    .helpTitle {
      h4 {
        @include font-settings($font-large, $weight-medium, $spacing-08, $slate-70B);
        font-family: $font-family-text;
        text-wrap: nowrap !important;
      }
    }

    .helpdescription {
      @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-70B);
      font-family: $font-family-text;

      p {
        margin: 0;
      }
    }
  }

  .helpClose {
    .loginHelpCloseBtn {
      border: none;
      cursor: pointer;

      &:active {
        box-shadow: none;
      }
    }
  }
}

.imagesUpAnimation {
  top: -64px !important;
  animation: imageSlideUp 0.2s;
  transition: transform 0.2s ease-in-out;

}

@keyframes imageSlideUp {
  0% {
    top: 0
  }

  10% {
    top: -6.4px
  }

  20% {
    top: -12.8px
  }

  30% {
    top: -19.2px
  }

  40% {
    top: -25.6px
  }

  50% {
    top: -32px
  }

  60% {
    top: -38.4px
  }

  70% {
    top: -44.8px
  }

  80% {
    top: -51.2px
  }

  90% {
    top: -57.6px
  }

  100% {
    top: -64px
  }
}

.imagesDownAnimation {
  top: -0 !important;
  animation: imageSlideDown 0.2s;
  transition: transform 0.2s ease-in-out;
}

@keyframes imageSlideDown {
  100% {
    top: 0
  }

  90% {
    top: -6.4px
  }

  80% {
    top: -12.8px
  }

  70% {
    top: -19.2px
  }

  60% {
    top: -25.6px
  }

  50% {
    top: -32px
  }

  40% {
    top: -38.4px
  }

  30% {
    top: -44.8px
  }

  20% {
    top: -51.2px
  }

  10% {
    top: -57.6px
  }

  0% {
    top: -64px
  }
}

.visibilityHidden {
  visibility: hidden;
}

@include for-tablet {
  .Welcome {
    .leftContainer {
      width: 45%;

      .rightBorderContainer {
        left: 33.75vw;
        width: calc(55vw + calc(45vw * 0.25) - 32px);
        border: $tabletKeyLineBorderWidth;

        .borderChildOne {
          border: $tabletKeyLineBorderWidth solid $red;
          border-bottom: 0;
        }

        .borderChildTwo {
          border: $tabletKeyLineBorderWidth solid;
          border-right-color: $red;
          border-top: 0;
          border-bottom: 0;
        }

        .borderChildThree {
          border-left: $tabletKeyLineBorderWidth solid $red;
          border-right: $tabletKeyLineBorderWidth solid $red;
        }
      }
    }

    .rightContainer {
      width: 55%;

      .loginContainer {
        padding: 0 $spacing-09;
        width: calc(55vw - 46px);

        .loginTextWrapper {
          .title {
            font-size: $font-xxxlarge !important;
          }
        }
      }
    }

    .loginHelpContainer {
      padding: $spacing-07 $spacing-08;
      gap: $spacing-06;

      .helpTextWrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-06;
      }
    }
  }
}