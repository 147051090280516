@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.modifyExternalPIUserLoader {
  min-height: calc(100vh - 350px);
}

.modifyExternalPIUserMain { 
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
  padding: 0 $spacing-11 $spacing-13 $spacing-11;
  min-height: calc(100vh - 350px);

  .modifyExternalPIUserMainContainer {
    @include flex(flex-start, unset);
    flex-direction: column;
    padding: $spacing-08 0;
    border-radius: $spacing-02;
    border: 1px solid $slate-50W;
    background: $white;
    width: 100%;

    .modifyExternalPIUserMainContent{
      @include flex(flex-start, space-between);
      flex-direction: column;
      gap: $spacing-08;
      width: 100%;
      padding: 0 $spacing-08 $spacing-08 $spacing-08;

      .modifyExternalPIUserHeaderContainer {
        .modifyExternalPIUserTitle {
          @include font-settings($font-xxlarge, $weight-medium, $line-height-large, $slate-70B);
          text-align: center;
        }
      }

      .modifyExternalPIUserFormContainer {
        @include flex(flex-start, unset);
        flex-direction: column;
        align-self: stretch;
        gap: $spacing-08;

        .modifyExternalPIUserFormBody {
          border-radius: $spacing-02;
          border: 1px solid $slate-50W;
          margin: 0;
          padding: $spacing-08;

          > div:first-of-type {
            margin: 0;

            > div > div > div {
              margin-bottom: $spacing-08 !important;
            }
          }
        
          div:empty {
            display: none;
          }

          .userActiveToggleContainer {
            @include flex(center, flex-start);
            padding: $spacing-08 0;

            .modifyExternalUserActiveToggle {
              --inputSliderCheckedBgColor: #{$navy};
              --inputSliderUncheckedBgColor: #{$slate};
              --inputSliderReadOnlyBgColor: #{$slate-80W};
              --inputSliderReadOnlyColor: #{$slate};
              --inputSliderBgColor: #{$white};
              --inputSliderFocus: #{$violet-20W};
            }
          }

          .modifyExternalPIUserFormActionBtnContainer {
            @include flex(center, space-between);
            flex-direction: row;
            gap: $spacing-05;

            & > div {
              display: flex;
              flex-direction: row;
              gap: $spacing-05;
          
              &:first-of-type {
                flex-grow: 2;

                & > button {
                  min-width: 186px;
                  @include tablet{
                    min-width: 140px;
                  }
          
                  &:first-of-type {
                    margin-left: 0;
                    max-width: unset;
                  }

                  &:last-of-type { margin-right: 0; }
                }
              }
            }
          }

          .modifyExternalPIUserFormSubmitBtnContainer {
            @include flex(center, space-between);
            flex-direction: row;
            padding-top: $spacing-03;
            gap: $spacing-05;

            & > div {
              display: flex;
              flex-direction: row;
              gap: $spacing-05;  
            }
          }
        }
      }
    }
  }
}

@include for-tablet {
  .modifyExternalPIUserMain {
    padding: 0 $spacing-08 $spacing-13 $spacing-08;

    .modifyExternalPIUserMainContainer {
      .modifyExternalPIUserMainContent{
        .modifyExternalPIUserFormContainer {
          .modifyExternalPIUserFormBody {
            .modifyExternalPIUserFormActionBtnContainer {
              @include flex(flex-start, flex-start);
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
