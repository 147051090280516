@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.cardHeader {
  padding: 40px 33px 16px 33px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.fileView {
  display: block;
}

.fileTableView{
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 48px 0px;
}

.modalTitle {
  font-size: $font-large;
  font-weight: $weight-medium;
  padding-top: 3px;
  padding-bottom: 10px;
}

.viewport {
  height: 62vh;
  overflow-y: auto;
}

.layoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-flow: dense;
  grid-template-areas:
    "title"
    "buttons";

  &>div {
    margin-bottom: 24px;
  }

  @include desktop {
    column-gap: 32px;
    grid-template-areas: "title buttons";
    grid-template-columns: 1fr 1fr;
  }
}

.titleContainer {
  grid-area: title;
  padding: 24px 0 0 32px;
}

.dataTable {
  width: 100%;  
  margin-top: 16px;
  table {
    width: 100%;
    thead>tr {
      th {
        padding-left: 32px;
      }
    }

    tbody>tr:nth-child(n) {
      td {
        padding-left: 32px;
      }

      td:nth-child(1) {
        width: 20%;
        flex-wrap: wrap;
      }

      td:nth-child(2) {
        width: 20%;
      }

      td:nth-child(3) {
        width: 60%;
        white-space: normal
      }

    }

    tbody>tr:nth-child(even) td {
      background: $grey-3;
    }
  }
}

.dataTableFooter {
  >div {
    margin-right: -64px;
  }
}

.description {
  padding: 0px 0px 32px 33px;
  width: 550px;
}

.addUser {
  margin-left: 48px;

  >div {
    margin-right: -16px;
  }
}

.descriptionText {
  padding-right: 33px;
  max-width: 550px;
}


$button-margin: 5px;
$button-padding: 5px;

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .buttonWrapper {
    flex: 0 1 auto;
    margin: $button-margin;
    padding: $button-padding;
    box-sizing: border-box;

    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  }
}