@import "../HbtIcon/hbtIconVariables.scss";

$spacing-01: 2px;
$spacing-02: 4px;
$spacing-03: 8px;
$spacing-04: 12px;
$spacing-05: 16px;
$tooltip-text-color: #454E56;
$tooltip-shadow-rgba: rgba(0, 0, 0, 0.25);
$arrow-shadow-rgba: rgba(0, 0, 0, 0.15);

$left-tooltip-value-right-icon-sm: $icon-sm + $spacing-03;
$left-tooltip-value-right-icon-md: $icon-md + $spacing-03;
$left-tooltip-value-right-icon-lg: $icon-lg + $spacing-03;
$left-tooltip-value-right-icon-xl: $icon-xl + $spacing-03;

$right-tooltip-value-right-icon-sm: $icon-sm + $spacing-03;
$right-tooltip-value-right-icon-md: $icon-md + $spacing-03;
$right-tooltip-value-right-icon-lg: $icon-lg + $spacing-03;
$right-tooltip-value-right-icon-xl: $icon-xl + $spacing-03;

$bottom-tooltip-value-top-icon-sm: 38px;
$bottom-tooltip-value-top-icon-md: $bottom-tooltip-value-top-icon-sm + $spacing-03;
$bottom-tooltip-value-top-icon-lg: $bottom-tooltip-value-top-icon-md + $spacing-03;
$bottom-tooltip-value-top-icon-xl: $bottom-tooltip-value-top-icon-lg + $spacing-03;

$bottom-left-tooltip-value-left-icon-sm: -90px;
$bottom-left-tooltip-value-left-icon-md: $bottom-left-tooltip-value-left-icon-sm + $spacing-02;
$bottom-left-tooltip-value-left-icon-lg: $bottom-left-tooltip-value-left-icon-md + $spacing-02;
$bottom-left-tooltip-value-left-icon-xl: $bottom-left-tooltip-value-left-icon-lg + $spacing-02;

$bottom-center-tooltip-value-left-icon-sm: -48px;
$bottom-center-tooltip-value-left-icon-md: $bottom-center-tooltip-value-left-icon-sm + $spacing-02;
$bottom-center-tooltip-value-left-icon-lg: $bottom-center-tooltip-value-left-icon-md + $spacing-02;
$bottom-center-tooltip-value-left-icon-xl: $bottom-center-tooltip-value-left-icon-lg + $spacing-02;

$center-arrow-value-offset: 47.5%;
$center-arrow-shadow-value-offset: 47%;

$bottom-right-tooltip-value-left-icon-sm: -9px;
$bottom-right-tooltip-value-left-icon-md: $bottom-right-tooltip-value-left-icon-sm + $spacing-02;
$bottom-right-tooltip-value-left-icon-lg: $bottom-right-tooltip-value-left-icon-md + $spacing-02;
$bottom-right-tooltip-value-left-icon-xl: $bottom-right-tooltip-value-left-icon-lg + $spacing-02;

$top-tooltip-before-value-bottom: -9px;
$top-tooltip-after-value-bottom: $top-tooltip-before-value-bottom + 2px;


$top-left-and-right-tooltip-arrow-offset: 16px;
$top-left-and-right-tooltip-arrow-shadow-offset: 15px;

$top-tooltip-value-bottom-icon-sm: 11px;
$top-tooltip-value-bottom-icon-md: $top-tooltip-value-bottom-icon-sm + $spacing-03;
$top-tooltip-value-bottom-icon-lg: $top-tooltip-value-bottom-icon-md + $spacing-03;
$top-tooltip-value-bottom-icon-xl: $top-tooltip-value-bottom-icon-lg + $spacing-03;

$top-right-tooltip-value-left-icon-sm: -13px;
$top-right-tooltip-value-left-icon-md: $top-right-tooltip-value-left-icon-sm + $spacing-02;
$top-right-tooltip-value-left-icon-lg: $top-right-tooltip-value-left-icon-md + $spacing-02;
$top-right-tooltip-value-left-icon-xl: $top-right-tooltip-value-left-icon-lg + $spacing-02;

// leave this value separate from the above to facilitate future tweaking if needed
$top-left-tooltip-value-right-icon-sm: -13px;
$top-left-tooltip-value-right-icon-md: $top-left-tooltip-value-right-icon-sm + $spacing-02;
$top-left-tooltip-value-right-icon-lg: $top-left-tooltip-value-right-icon-md + $spacing-02;
$top-left-tooltip-value-right-icon-xl: $top-left-tooltip-value-right-icon-lg + $spacing-02;

$top-center-tooltip-value-left-icon-sm: -52px;
$top-center-tooltip-value-left-icon-md: $top-center-tooltip-value-left-icon-sm + $spacing-02;
$top-center-tooltip-value-left-icon-lg: $top-center-tooltip-value-left-icon-md + $spacing-02;
$top-center-tooltip-value-left-icon-xl: $top-center-tooltip-value-left-icon-lg + $spacing-02;