/* ==================================================
  These are custom updates to bootrstrap-datepicker, 
  last revised on 2020-08-29.
  This utilizes bootstrap-datepicker:
  https://uxsolutions.github.io/bootstrap-datepicker. 
  ================================================== */


/* Previous and next buttons ========================================================================= */
.calendar-navigation {
    content: "";
    visibility: hidden !important;
    padding: 0;

    &:after {
        visibility: visible;
        font-family: $font-family-icons;
        font-size: $font-xlarge;
        color: $soft-black;
        display: block;
        position: absolute;
        padding: 0;
        top: 24px;
    }
}
/* end  Previous and next buttons ------------------------------------------------------------------- */

body .datepicker {
    padding: 16px 24px;
    font-size: $font-small;

    table {
        border-spacing: 4px;
        border-collapse: separate;
    }

    
    /* Previous and next buttons ==================================================================== */
    th.next {
        @extend .calendar-navigation;

        &:after {
            content: "chevron_right";
        }
    }
    th.prev {
        @extend .calendar-navigation;

        &:after {
            content: "chevron_left";
        }
    }
    /* end  Previous and next buttons -------------------------------------------------------------- */


    /* Remove arrow pointing towards datepicker selection input box ================================ */
    &.datepicker-dropdown.datepicker-orient-bottom {
        &:after, &:before{
            border: none;
        }
    }
    /* end Remove arrow pointing towards datepicker selection input box ---------------------------- */


    /* Days Calendar ========================================================================= */
    .datepicker-days table tr{
        td, th {
           border-radius: 100%;
           padding: 6px 9px;
           width: 36px;
           height: 36px;
           max-width: 36px;
       }
       .dow{
           font-weight: $weight-regular;
       }
   }
   /* end Days Calendar --------------------------------------------------------------------- */


   /* Months/Years Calendars ================================================================ */
   .datepicker-years, .datepicker-months {
       table tr td {
           width: 308px;

           .month, .year {
               line-height: 40px;
               border-radius: 24px;
               width: 96px;
               height: 40px;
               margin: 4px;

               &:nth-child(3n+1){
                   margin-left: 0;
               }
               &:nth-child(3n+3){
                   margin-right: 0;
               }
           }
       }
   }
   /* end Months/Years Calendars ---------------------------------------------------------- */


   /* Months/Years Switch ================================================================= */
   .datepicker-days table tr th.datepicker-switch{
       border-radius: 0%;
   }
   /* end Months/Years Switch ------------------------------------------------------------- */

    
    /* Today ======================================================================================= */
    table tr td.today {
        background-color: $grey-6;
        border-color: $grey-6;
    }

    table tr td.today{
        &:hover, &:hover:hover{
            background-color: $grey-6;
            border-color: $grey-6;
            border-radius: 100%;
        }
    }
    /* end Today ---------------------------------------------------------------------------------- */


    /* Date Range =============================================================================== */
    table tr td.range.today{
        &, &:hover, &:hover:hover, &.disabled:hover, &.disabled:hover:hover, &:focus, &:hover:focus, &.disabled:focus, &.disabled:hover:focus, &:active, &:hover:active, &.disabled:active, &.disabled:hover:active, &.active, &:hover.active, &.disabled.active, &.disabled:hover.active{
            background-color: $grey-6;
            border-color: $grey-6;
            border-radius: 100%;
        }
    }    
    
    .open .dropdown-toggle.datepicker table tr td.range{
        &.today, &.today:hover, &.today.disabled, &.today.disabled:hover{
            background-color: $grey-6;
            border-color: $grey-6;
            border-radius: 100%;
        }
    }

    table tr td.range {
        &.today, &.today:hover, &.today.disabled, &.today.disabled:hover, &.today.disabled:hover:hover {
            background-color: $grey-6;
            border-color: $grey-6;
            border-radius: 100%;
        }
    }

    table tr td.range {
        color: $soft-black;
        background-color: $tint-2;
        border-color: $tint-2;
        border-radius: 0;
    }
    /* end Date Range ------------------------------------------------------------------------ */


    /* Selected Date(s) ====================================================================== */
    table tr td.active {
        &.active, &.highlighted.active, &.highlighted:active, &:active {
            color: $white;
            background-color: $global-blue;
            border-color: $global-blue;
        }
    }

    table tr td {
        &.selected, &.selected:hover, &.selected.disabled, &.selected.disabled:hover{
            color: $white;
            background-color: $global-blue;
            border-color: $global-blue;
        }
    }
    /* end Selected Date(s) ------------------------------------------------------------------ */

}

.react-datepicker {
    &__input-container::after{
        content: "calendar_today";
        pointer-events: none;
        font-family: "Material Icons Outlined";
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
        position: absolute;
        top: 12px;
        right: 0;
        margin-right: 10px;
        // width: 16px;
        // height: 0px;
        // display: flex;
        // position: relative;
        // float: right;
        // margin: 0px 12.5px;
        // top: -38px;
    }
}