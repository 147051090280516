@import "../../Styles/Core/variables.scss";
@import "../../Styles/Core/mixins.scss";

.tabContainer {
    width: 100%;
}

.tabButtons {
    display: inline-flex;
    width: 100%;
    @include font-settings($font-regular, $weight-regular, $line-height-regular);
    font-family: $font-family-text;
}

.tabLabel{
    padding: $spacing-05 $spacing-06;
    white-space: pre-wrap;
}

.tabButtonsFixWidth{
    display: inline-flex;
    width: 768px;
    color: $slate-70B;
    @include font-settings($font-regular, $weight-regular, $line-height-regular);
    font-family: $font-family-text;
    overflow-x: scroll;
    position: relative;
}

.tabButtonsFixWidth::-webkit-scrollbar {
    display: none;
}

.leftAlignedTab {
    max-width: 340px;
    max-height: 80px;
    min-height: 56px;
    min-width: 104px;
    cursor: pointer;
    text-align: left;
    align-content: center;
    background-color: white;
    box-shadow: inset 0 -4px 0 0 transparent;

    &:hover {
        box-shadow: inset 0 -4px 0 0 $violet;
        font-weight: $weight-bold;
        color: $violet;
    }

    &:focus {
        box-shadow: inset 0 -4px 0 0 $violet-20W;
        outline: none;
    }
}

.leftAlignedTabActive {
    max-width: 340px;
    max-height: 80px;
    min-height: 56px;
    min-width: 104px;
    font-weight: $weight-bold;
    color: $navy;
    cursor: pointer;
    text-align: left;
    align-content: center;
    box-shadow: inset 0 -4px 0 0 $navy;
    background-color: $navy-95W;

    &:focus {
        box-shadow: inset 0 -4px 0 0 $violet-20W;
        outline: none;
    }
}

.fullWidthTab {
    width: 100%;
    max-height: 80px;
    min-height: 56px;
    cursor: pointer;
    text-align: left;
    align-content: center;
    background-color: white;
    box-shadow: inset 0 -4px 0 0 transparent;

    &:hover {
        box-shadow: inset 0 -4px 0 0 $violet;
        color: $violet;
        font-weight: $weight-bold;
    }
}

.fullWidthTabActive {
    width: 100%;
    max-height: 80px;
    min-height: 56px;
    font-weight: $weight-bold;
    cursor: pointer;
    text-align: left;
    align-content: center;
    box-shadow: inset 0 -4px 0 0 $navy;
    background-color: $navy-95W;
}

.noRipple {
    .MuiTouchRipple-root {
      display: none;
    }
  }

.dropdownStyle{
    width: 100%;
}

.dividerStyle{
    position: absolute;
    bottom: 0;
}