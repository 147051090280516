@import 'Project/Website/styles/_layout.scss';
@import "src/Components/Styles/variables";

.half {
    width: calc(50% - #{$gutter-width / 2});
    padding: {
        bottom: $gutter-width / 2;
    };

    &Left {
        @extend .half;
        margin: {
            right: $gutter-width;
        };
    }
}

.subHeading {
    @extend .full;
    color: $soft-black;
    font: {
        weight: bold;
    }
    margin-top: 2%;
    margin-bottom: 4%;
}

.separator {
    @extend .full;
    color: $grey-6;
}

.full {
    width: 100%;
    padding: {
        bottom: $gutter-width / 2;
    };
}

.pullDown {
    margin-top: 24px;
  }