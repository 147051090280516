@import 'src/Components/Styles/BaseComponentStyles/cards';

.removeScroll {
	> div::-webkit-scrollbar {
		display: none;
	}

	> div {
		-ms-overflow-style: none;
		scrollbar-width: none;

		> div:nth-of-type(1) {
			padding-left: 40px;
		}
	}
}

.textSeparator {
	width: 350px;
}

.footerMargin {
	margin-bottom: 24px;

	> div {
		&:last-of-type {
			margin-left: -16px;
		}

		> button {
			width: 100%;
		}
	}
}

.contentDivider {
	width: 86%;
	&Hr {
		> div {
			margin-top: 32px;
		}

		hr {
			margin-top: 0;
		}
	}
}

.emptyDiv {
	&Area {
		margin-bottom: 32px;
	}

	&Attachment {
		margin-bottom: -16px;
	}
}

.headerWrapper {
	border-bottom: 1px solid $grey-20;
	padding: 32px 35px 32px 32px;
	div {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		h2 {
			margin: 0;
		}
	}

	&Subtext small {
		background-color: $grey-6;
		padding: 0 24px;
		border-radius: 16px;
		font-weight: $weight-medium;
	}
}

.notificationCard {
	margin-top: 8px;
	margin-bottom: 0px;
}

.closeButton {
	button {
		background: 0;
		border: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
	}
}

.textWrapper {
	color: $black;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 32px;
}

.buttonLink {
  border: none;
  cursor: pointer;
  background: none;
	margin-left: -5px;
  color: $global-blue;
  text-decoration: underline;
}
