@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.piTermsAndConditionsMainBody {
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
  padding: $spacing-10 $spacing-11 $spacing-13 $spacing-11;

  .piTermsAndConditionsMainContainer {
    @include flex(center, unset);
    flex-direction: column;
    flex: 1 0 0;
    gap: $spacing-07;
    border-radius: $spacing-02;
    border: 1px solid $slate-50W;
    background: $white;
    width: 100%;
    max-height: calc(100vh - 362px);
    padding: $spacing-08;
    min-height: 440px;

    .piTermsAndConditionsHeaderContainer {
      @include flex(flex-start, unset);
      flex-direction: column;
      align-self: stretch;
      gap: $spacing-03;

      .piTermsAndConditionsHeader {
        @include font-settings($font-xxlarge, $weight-medium, $line-height-large, $slate-70B);
        text-align: center;
      }

      .piTermsAndConditionsSubHeader {
        @include font-settings($font-xlarge, $weight-medium, 150%, $slate-30B);
      }
    }

    .piTermsAndConditionsContentContainer {
      @include flex(center, unset);
      flex-direction: column;
      align-self: stretch;
      flex: 1 0 0;

      .piTermsAndConditionsContentInfo {
        overflow-y: scroll;
        overflow-x: auto;
        height: calc(100vh - 608px);
        padding-right: $spacing-05;
        min-height: calc(440px - 278px);

        @media screen and (min-width: 985px) {
          min-height: calc(440px - 248px);
        }

        p {
          @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-30B);
        }

        p:not(:last-of-type) {
          margin-bottom: $spacing-05;
        }

        .acknowledgedCheckboxContainer {
          @include flex(flex-start, unset);
          flex-direction: column;
          padding-top: $spacing-07;
          width: 100%;
          margin-left: -7px;

          label, span {
            input[type="checkbox"] {
              &:focus {
                outline: none;
              }
            }
          }

          .checkboxError {
            @include flex(center, unset);
            flex-direction: row;
            gap: $spacing-02;
            margin-left: 7px;
            padding-top: $spacing-03;
            color: $red;
          }
        }

        ::-webkit-scrollbar {
            background: $slate-80W;
            border-radius: 7px;
            width: 10px;
            height: 226px;
        }

        ::-webkit-scrollbar-track {
          background: none;
        }

        ::-webkit-scrollbar-thumb {
          background: $slate-80W;
          border-radius: 7px;
          width: 10px;
          height: 226px;

          &:hover {
            background: $grey-60;
          }
        }
      }

      .piTermsAndConditionsActionBtnContainer {
        @include flex(center, flex-end);
        align-self: stretch;
        gap: $spacing-07;
        padding-top: $spacing-08;
        width: 100%;
      }
    }
  }
}

@include for-tablet {
  .piTermsAndConditionsMainBody {
    padding: $spacing-10 $spacing-08 $spacing-13 $spacing-08;

    .piTermsAndConditionsMainContainer {
      .piTermsAndConditionsContentContainer {
        .piTermsAndConditionsContentInfo {
          height: calc(100vh - 638px);
        }
      }
    }
  }
}