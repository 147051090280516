@import "Project/Website/styles/_layout.scss";
@import "src/Components/Styles/variables";
.full {
    width: 100%;
    padding: {
      bottom: $gutter-width / 2;
    }
  
    &Right {
      @extend .full;
      text-align: right;
    }
  }