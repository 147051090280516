@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.notificationContentOuterWrapper {
  @include tablet {
    width: 100%;
    left: -100%
  }

  border-right: $grey-20 1px solid;
  height: 100vh;
  left: -514px;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  width: 514px;
}

.notificationContentWrapper {
  background: $white;
  height: 100vh;
  left: 0;
  padding: 32px;
  position: absolute;
  top: 0;
  width: 514px;
  overflow-y: auto;
  overflow-x: hidden;

  @include tablet {
    width: 100%;
  }

  time {
    font-size: $font-small;
    color: $soft-black;
  }

  & > div:last-of-type {
    font-size: $font-small;
    overflow-wrap: break-word;
    a {
      font-size: $font-small !important;
    }
  }
}

.notificationContentHeader {
  align-content: flex-start;
  display: flex;
  justify-content: space-between;
  overflow: auto;

  button {
    background: none;
    border: 0;
    margin: 0;
    max-height: 40px;
    padding: 8px;
  }
}

.lender {
  margin-top: 32px;

  span {
    display: block;
  }
}

.onEnter {
  animation-duration: 0.3s;
  animation-name: notificationContentAnimation;
  animation-timing-function: ease-in-out;
  position: absolute;
  z-index: -1;
  //animation-iteration-count: infinite;

}

.onExit {
  animation-direction: reverse;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: notificationContentAnimation;
  animation-timing-function: ease-in-out;
  position: absolute;

}

@keyframes notificationContentAnimation {
  from {
    left: 514px;
  }
  to {
    left: 0;
  }
}

