@import "Components/Styles/Core/variables";

.inputColor {
  > label {
    > div > div > div > div {
      color: $grey-80;
    }
  }

  > :global(label.error) {
    > div > div {
      border-color: $notification-error;
    }
  }
}

.emailIDReadOnly input:read-only {
  background: $slate-90W;
}

.externalUserInfoFormField {
  input, select {
    color: $slate-70B;

    &:focus {
      box-shadow: inset 0 0 0 $spacing-01 $slate-50W;
      outline: $spacing-01 solid $violet-20W;
      outline-offset: $spacing-01;
    }
  }
}