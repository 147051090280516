@import "src/Components/Styles/variables";
@import 'Project/Website/styles/_layout.scss';
@import "src/Components/Styles/adaptive";

.dataTable {
  margin-bottom: 24px;
  table {
    width: 100%;

    tbody td:nth-child(2) {
      max-width: 300px;
      white-space: normal;
    }
  }
}


.searchContainer {
  padding: 16px 32px;
  border-top: $grey-20 1px solid;
}

.actionMenuHeader {
  opacity: 0;
  width: 10px;
  padding: 0;

  span { display: none; }
}

.loanNumber { width: 180px; }
.approvedAmount { width: 270px; }
 
.preClaimsPropertyRepairsContainerFrench {
  div > div:nth-child(2) {
    button > span:nth-child(1) {
      margin: -12px 16px 0px 0px;
    }
  }
  button > span:nth-child(2) {
    text-align: left;
  }
  
  @include desktop {
    div > div:nth-child(2) {
      button > span:nth-child(1) {
        margin: 0px 8px 0px 0px;
      }
    }
    button > span:nth-child(2) {
      text-align: center;
    }
  }  
}