.navigation{
    border-bottom: $border-default;
    background-color: $white;
    padding: 40px 0;

    &__container{
        @include flexbox;
    }

    &__header{
        padding: 0;
        a {
            position: absolute;
            width: 380px;
            &:focus {
                outline-offset: 5px;
                outline: 2px solid $tint-40; 
            }
        }
    }
    
    &__header-logo{
        height: 48px;
        width: 110px;
        margin-right: 24px;
        vertical-align: top;
    }
    
    &__header-titles{
        height: 48px;
        display: inline-block;
        padding-left: 130px;
    }
    
    &__header-subtitle{
        font-family: $font-family-text;
        font-size: $font-xsmall;
        font-weight: $weight-bold;
        letter-spacing: 0;
        line-height: 16px;
    }
    
    &__header-title{
        font-weight: $weight-regular;
        font-size: $font-xlarge;
        line-height: 32px;
        margin: 0;
    }
    
    &__items{
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        margin-left: auto;
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        justify-content: center;

        .navigation__item-link {
            color: $soft-black;
            padding: 0;
            padding-left: 0;
            padding-right: 0;
        
            &.navigation__item-link--blue{
                color: $global-blue;
                padding: 10px;
            }
        }

        .languageSwitch, &--headerActions {
            background: none;
            border: none;
            color: $global-blue;
            font-weight: $weight-bold;
            padding: 7px 10px;
            transition: color 0.3s ease;
            &:hover, &:active {
                color: $shade-30;
                text-decoration: underline;
            }
        }
    }
        
    &__item:not(:first-child){
        margin-left: 24px;
    }
    
    &__item-icon {
        margin-right: 4px;
    }
    
    &__item-circle{
        font-size: $font-regular;
        font-weight: $weight-regular;
        text-align: center;
        letter-spacing: 0;
        line-height: $line-height-regular;
        border: 2px solid $soft-black;
        @include border-radius(50%);
        width: 32px;
        height: 32px;
        padding-top: 2px;
        display: inline-block;
    }
    
    &__dropdown-menu{
        text-align: left;
        background-color: $white;
        border: $border-default;
        @include border-radius(4px);
        @include box-shadow(1px -1px 6px rgba($black,0.25));
        min-width: 132px;
        padding: 0;
        position: absolute;
        right: 0;
        left: unset;
    }
    
}