@import "../../Styles/Core/variables.scss";
@import "Components/Core/HbtIcon/hbtIconVariables.scss";
@import "./tooltipVariables.scss";
@import "./mixins.scss";

.tooltipWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
  height: $icon-sm;
  width: $icon-sm;

  &.icon-sm {
    width: $icon-sm;
    height: $icon-sm;
  }

  &.icon-md {
    width: $icon-md;
    height: $icon-md;
  }

  &.icon-lg {
    width: $icon-lg;
    height: $icon-lg;
  }

  &.icon-xl {
    width: $icon-xl;
    height: $icon-xl;
  }

  &:hover .tooltip {
    display: block;
  }
}

.tooltip {
  display: none;
  position: absolute;
  background-color: $slate-80W;
  color: $tooltip-text-color;
  padding: 5px 10px 3px 10px; // Move the text 1px down to improve visual alignment based on designer review
  border-radius: $border-radius;
  font-size: $font-small;
  box-shadow: 0 $spacing-01 $spacing-01 0 $tooltip-shadow-rgba;
  white-space: nowrap;
  line-height: 20px;
  max-height: 52px;
  min-width: 48px;
  max-width: 370px;
  
  &.tooltipLabel{
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    overflow: visible;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }  
  
  &.top-right {
    &.icon-sm {
      @include topRight($top-tooltip-value-bottom-icon-sm, $top-right-tooltip-value-left-icon-sm);
    }
    &.icon-md {
      @include topRight($top-tooltip-value-bottom-icon-md, $top-right-tooltip-value-left-icon-md);
    }
    &.icon-lg {
      @include topRight($top-tooltip-value-bottom-icon-lg, $top-right-tooltip-value-left-icon-lg);
    }
    &.icon-xl {
      @include topRight($top-tooltip-value-bottom-icon-xl, $top-right-tooltip-value-left-icon-xl);
    }
  }

  &.top-center {
    &.icon-sm {
      @include topCenter($top-tooltip-value-bottom-icon-sm, $top-center-tooltip-value-left-icon-sm);
    }
    &.icon-md {
      @include topCenter($top-tooltip-value-bottom-icon-md, $top-center-tooltip-value-left-icon-md);
    }
    &.icon-lg {
      @include topCenter($top-tooltip-value-bottom-icon-lg, $top-center-tooltip-value-left-icon-lg);
    }
    &.icon-xl {
      @include topCenter($top-tooltip-value-bottom-icon-xl, $top-center-tooltip-value-left-icon-xl);
    }
  }
  
  &.top-left {
    &.icon-sm {
      @include topLeft($top-tooltip-value-bottom-icon-sm, $top-left-tooltip-value-right-icon-sm);
    }
    &.icon-md {
      @include topLeft($top-tooltip-value-bottom-icon-md, $top-left-tooltip-value-right-icon-md);
    }
    &.icon-lg {
      @include topLeft($top-tooltip-value-bottom-icon-lg, $top-left-tooltip-value-right-icon-lg);
    }
    &.icon-xl {
      @include topLeft($top-tooltip-value-bottom-icon-xl, $top-left-tooltip-value-right-icon-xl);
    }
  }
  
  &.bottom-left {
    &.icon-sm {
      @include bottomLeft($bottom-tooltip-value-top-icon-sm, $bottom-left-tooltip-value-left-icon-sm);
    }
    &.icon-md {
      @include bottomLeft($bottom-tooltip-value-top-icon-md, $bottom-left-tooltip-value-left-icon-md);
    }
    &.icon-lg {
      @include bottomLeft($bottom-tooltip-value-top-icon-lg, $bottom-left-tooltip-value-left-icon-lg);
    }
    &.icon-xl {
      @include bottomLeft($bottom-tooltip-value-top-icon-xl, $bottom-left-tooltip-value-left-icon-xl);
    }
  }
  
  &.bottom-center {
    &.icon-sm {
      @include bottomCenter($bottom-tooltip-value-top-icon-sm, $bottom-center-tooltip-value-left-icon-sm);
    }
    &.icon-md {
      @include bottomCenter($bottom-tooltip-value-top-icon-md, $bottom-center-tooltip-value-left-icon-md);
    }
    &.icon-lg {
      @include bottomCenter($bottom-tooltip-value-top-icon-lg, $bottom-center-tooltip-value-left-icon-lg);
    }
    &.icon-xl {
      @include bottomCenter($bottom-tooltip-value-top-icon-xl, $bottom-center-tooltip-value-left-icon-xl);
    }
  }
  
  &.bottom-right {
    &.icon-sm {
      @include bottomRight($bottom-tooltip-value-top-icon-sm, $bottom-right-tooltip-value-left-icon-sm);
    }
    &.icon-md {
      @include bottomRight($bottom-tooltip-value-top-icon-md, $bottom-right-tooltip-value-left-icon-md);
    }
    &.icon-lg {
      @include bottomRight($bottom-tooltip-value-top-icon-lg, $bottom-right-tooltip-value-left-icon-lg);
    }
    &.icon-xl {
      @include bottomRight($bottom-tooltip-value-top-icon-xl, $bottom-right-tooltip-value-left-icon-xl);
    }
  }

  &.left {
    &.icon-sm {
      @include left($left-tooltip-value-right-icon-sm);
    }
    &.icon-md {
      @include left($left-tooltip-value-right-icon-md);
    }
    &.icon-lg {
      @include left($left-tooltip-value-right-icon-lg);
    }
    &.icon-xl {
      @include left($left-tooltip-value-right-icon-xl);
    }
  }

  &.right {
    &.icon-sm {
      @include right($right-tooltip-value-right-icon-sm);
    }
    &.icon-md {
      @include right($right-tooltip-value-right-icon-md);
    }
    &.icon-lg {
      @include right($right-tooltip-value-right-icon-lg);
    }
    &.icon-xl {
      @include right($right-tooltip-value-right-icon-xl);
    }
  }
}
