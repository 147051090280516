@import "src/Components/Styles/_adaptive";
@import "src/Components/Styles/_variables";
@import "src/Components/Styles/BaseComponentStyles/cards";

.card {
  > section:first-of-type {
    @extend .cardBase;
  }
}

.cardHeader {
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  &Item {
    min-width: 15.5%;
  }
}

.tableToolsWrapper {
  display: flex;
  flex-direction: row;
  margin: 16px 32px;
  align-content: center;
}

.searchBarWrapper {
  flex-grow: 12;
  width: 80%;
  padding-right: 16px;
}

.showMyInventoryWrapper {
  flex-shrink: 1;
  padding-left: 16px;
  align-self: center;
}

.dataTable {
  display: block;

  table {
    width: 100%;

    button {
      text-align: left;
    }

    th {
      vertical-align: text-top;

      &:first-of-type {
        padding: 0 32px;
      }
    }

    tbody tr td:first-of-type {
      padding: 20px 32px 20px;
    }

    tbody tr:last-of-type td:first-of-type {
      padding: 20px 48px 20px;
    }
  }

  &Footer {
    margin-top: 86px;
    padding-top: 24px;
    border-top: 1px solid $grey-20;
  }
}

.removeScroll {
  > div::-webkit-scrollbar {
    display: none;
  }

  > div {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.textSeparator {
  width: 350px;
}

.footerMargin {
  padding: 16px 0 40px 32px;

  > div {
    &:last-of-type {
      margin-left: -16px;
    }

    > button {
      width: 100%;
    }
  }
}

.contentDivider {
  width: 90%;
}

.bottomText > div:first-of-type {
  padding-top: 8px;
}

.reduceFont {
  > label,
  div,
  b {
    font-size: $font-small;
  }

  > div {
    > label,
    > fieldset > legend,
    > fieldset > div > label {
      font-size: $font-small;
    }
  }
}

.summaryReportTabs {
  & > div {
    flex-direction: column;
    align-items: flex-start;

    @include tablet {
      width: 406px;
    }
  }
  label {
    margin-bottom: 0.5rem;
  }
}
