@import '../../../../Components/Styles/variables';

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;

  .closeButton {
    button {
      background-color: $white;
      border: none;

      &:focus {
        outline: 2px auto $tint-40;
      }
    }
  }
}

.sideMenuListWrapper {
  a:focus, a:active {
    outline: 2px auto $tint-40;
    outline-offset: -2px;
  }
}
