@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;
  grid-auto-flow        : dense;
  grid-template-areas   : 
    "searchbar"
    "buttons";
  margin: 8px 0;

  & > div:first-child { grid-area : buttons; }
  & > div:last-child { grid-area : searchbar; }
  & > div { margin: 8px 0; }

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "buttons searchbar";
    grid-template-columns : 4fr 4fr;
    
  }
}

.noSearchResultsMessage {
  display: block;
  padding: 32px;
  text-align: center;
  font-weight: bold;
}

.filterContainer {
    display               : grid;
    column-gap            : 32px;
    grid-template-areas   : "searchbar toggle";
    grid-template-columns : 9fr 3fr;
    grid-auto-flow        : dense;

    & > div:first-child { grid-area : toggle; }
    & > div:last-child { grid-area : searchbar; }

    @include desktop {
        grid-template-areas   : "toggle searchbar";
        grid-template-columns : 1fr 1fr;
    }
  }

.showForDesktopOnly {
    display: none;

    @include desktop {
        display: block;
    }
}

.hideForDesktop {
    display: block;

    @include desktop {
        display: none;
    }
}

.inventorySearch {
    width: 100%;
}

.inventoryPagination { 
  font-family: $font-family-text;
  font-size: 14px;
  color: $grey-80;
  font-weight: bold;
}