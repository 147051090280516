@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.dashboardCardsContainer {
  @include flex(flex-start, unset);
  align-content: flex-start;
  gap: $spacing-08;
  flex-wrap: wrap;
  width: 100%;

  .dashboardCardBase {
    @include flex(flex-start, center);
    flex-direction: column;
    padding: $spacing-05 $spacing-08;
    border-radius: $spacing-02;
    border: 1px solid $slate-50W;
    background: $white;
    min-width: 350px;
    width: calc(50% - 32px);

    &:hover {
      border: 1px solid $violet;
      background: $violet-90W;
    }

    &:active {
      border: 1px solid $violet;
      background: $violet-90W;
    }

    &:focus {
      outline: 3px solid $violet-20W;
      outline-offset: 2px
    }

    .container {
      @include flex(center, unset);
      gap: $spacing-06;

      .cardTitle {
        font-family: $font-family-text;
        @include font-settings($font-xlarge, $weight-medium, $line-height-medium, $slate-70B);
        height: $spacing-11;
        @include flex(center, center);
        flex-direction: column;
        flex: 1 0 0;
      }
    }
  }

  a.dashboardCardBase {
    &[target='_blank']:after {
      content: none;
    }
  }

  .dashboardCardBase:hover .container .cardTitle,
  .dashboardCardBase:active .container .cardTitle {
    color: $violet !important;
  }
}

@include for-small-desktop {
  .dashboardCardsContainer {
    .dashboardCardBase {
      width: 100%;
    }
  }
}

@include for-small-tablet {
  .dashboardCardsContainer {
    .dashboardCardBase {
      width: 100%;
    }
  }
}