@import "src/Components/Styles/adaptive";

.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;

  & > div { margin-bottom : 32px; }

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "sidebar content";
    grid-template-columns : 3fr 9fr;

    & > div:first-child { grid-area : sidebar; }
    & > div:last-child { grid-area : content; }
  }

}
