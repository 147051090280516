@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.breadcrumbs {
  list-style-type: none;
  margin: 0 0 40px 0;
  padding: 0;
  align-items: flex-start;
  font-size: $font-small;
  display: flex;

  li {
    padding: 0 6px 0 2px;
    display: flex;
    align-items: flex-start;
  }

  a {
    font-size: $font-small;
  }
  .chevronIcon {
    margin-left: 10px;
    margin-top: 4px;
  }
}

.homeIcon  a {
  margin-top: 4px;
  margin-bottom: -4px;
}
