@import "../../styles.module";
@import "src/Components/Styles/adaptive";

.content {
  width: 100%;
}

.dataTable {
  display: block;

  table {
    table-layout: fixed;
    width: 175%;

    @include desktop {
      width: 100%;
    }
  }

  th {
    white-space: normal;
    width       : 11.5%;

    @include desktop {
      &:first-of-type {
        width : 19.5%;
      }
    }

    &:first-of-type {
      width: 19.5%;
    }

    &:last-of-type {
      width: 6.5%;
    }
  }

  tbody tr td:first-child {
    white-space: normal;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  tbody tr td:last-child {
    text-align: right;
    padding-right: 32px;
  }

}

.tableInput {
  @extend .tableStandardInput;

  margin: 4px;
}

.admissibleDate {
  label {
    margin-bottom: 0px;
  }
}

.fieldset {
  label {
    margin: 0;
  }
}