
.nav-tabs{
    border-bottom: $border-thick;

    .nav-link {
        font-size: $font-regular;
        font-weight: $weight-regular;
        color: $soft-black;
        border: none;
        padding: 24px 16px;
        
        &.active{
            font-weight: $weight-bold;
            color: $global-blue;
            border: none;
            border-bottom: $border-active;
        }
    }

    .nav-item:first-child{
        .nav-link{
            padding-left: 32px;
        }
    }
}
button.nav-link {
    background-color: transparent;
}