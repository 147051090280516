.itmFlagsdataTable {
  margin-bottom: 24px;
  table {
    width: 100%;
    th ,td {
      padding: 20px  8px 20px 0px;
    }
    tbody td:nth-child(1) {
      width: 285px;
      white-space: normal;
      padding: 20px 8px 20px 0px;
    }
    tbody td:not([class]) {
      padding: 20px  8px 20px 0px;
    }
  }
  .titleText{
    padding-top: 18px;
    padding-bottom: 32px;
  }
  .itmType {
    width: 125px;
  }
  .reference {
    width: 165px;
  }
  .status {
    width: 135px
  }
  .activeDate {
    width: 165px;
  }
  .cancelDate {
    width: 155px;
  }
}
.noSearchResultsMessage {
  display: block;
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
}