@import "src/Components/Styles/variables";

.tableRow {
  td:not([class]) {
    padding: 20px;
    border-bottom: $grey-20 1px solid;
    white-space: nowrap;
    font-size: 14px;
    color: $soft-black;
  }
}

.subtotal {
  td {
    background: $tint-5; // this allows for maintaining bg in the case where the table is in a scrollable container
    font-weight: bold;
  }
}

