@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.announcementItem {
  background: $white;
  border: $grey-20 1px solid;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 24px 48px;

  @include desktop {
    padding: 24px;
  }

  h3 {
    font-size: $font-regular;
    font-weight: $weight-bold;
    letter-spacing: 0;
    line-height: $line-height-regular;
    margin: 0 0 8px 0;
  }
}

.announcementContent {
  font-size: $font-small;
  line-height: $line-height-regular;
  margin-bottom: 40px;
}

.timestamp {
  display: block;
  border-top: $grey-20 1px solid;
  padding: 8px 24px 0 0;
  text-align: right;
  font-size: $font-xsmall;
  color: $grey-80;
  font-weight: 600;
}