/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}

// BOOTSTRAP OVERRIDES =======================================================================================
$gutter-width: 32px;
$container-width: 1286px;

.container, .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
    padding-right: $gutter-width/2;
    padding-left: $gutter-width/2;
}

.container, .container-fluid{
    min-width: $container-width;
}

.row{
    margin-right: ($gutter-width/2)*-1;
    margin-left: ($gutter-width/2)*-1;
    display: -ms-Flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media (min-width: 1200px) {
    .container {
        max-width: $container-width;
    }
}

@media (max-width: 992px) {
    .navbar-collapse {
        -ms-flex-preferred-size: revert;
        flex-basis: revert;
        -ms-flex-positive: revert;
        flex-grow: revert;
        -ms-flex-align: center;
        align-items: center;
    }
    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
}
// end BOOTSTRAP OVERRIDES ---------------------------------------------------------------------------------

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    _:-ms-lang(x), fieldset[disabled] {
        pointer-events: none;
    }
}

