@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.announcementHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: $font-regular;
    font-weight: bold;
  }
}

.showHideButton {
  background: $white;
  border: $grey-20 1px solid;
  border-radius: 4px;
  padding: 4px 16px 4px 28px;
  font-size: $font-small;
  align-content: center;
  position : relative;
  display: block;

  @include desktop {
    display: none;
  }
}
.buttonIcon {
  font-size: 18px;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.announcementList {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: none;

    &:first-of-type {
      display: initial;
    }

    @include desktop {
      display: initial !important;
    }
  }

  &.showAll li {
    display: initial;
  }
}