//NOTE: update cssvariables if you update any in this file
@import 'cssvariables';

//COLOURS ====================================================================================================================

$text-links-body: #454E56;
$dropdown-item-label: #454E56;
$soft-black: #282828; //default body text
$tooltip-text-color: #454E56;
$text-links-text-header: #202428; // header text
$input-active-keyline: #6B7885; //active state for input fields
$text-links-text-supplementary: #6B7885;
$global-grey: #6B7885;
$grey-35B: #454E56;
$grey-80: #4A4A4A; //footer
$grey-70: #656565;
$grey-70b: #F0F1F3; // States selection for menu items
$grey-60: #7A7A7A; //supplementary/help/admin text
$grey-20: #CCCCCC; //disabled states, keylines
$grey-6: #E9E9E9; // divider for section/datatable rows separation and hover state indication
$grey-3: #F8F8F8; //contrast and segmenting i.e. textfield bg
$white: #FFFFFF; //default form component / page/card/data table bg
$black: #000000;

//Navy
$navy: #00093A;
$navy-20W: #333A61;
$navy-50W: #80849D;
$navy-90W: #E6E6EB;
$navy-95W: #F3F3F5;

//Red
$red: #DA291C;

//Violet
$violet: #483FCD;
$violet-20W: #6D65D7;
$violet-50W: #A49FE6;
$violet-90W: #EDECFA;

// Slate
$slate: #697885;
$slate-70B:#1F2428;
$slate-30B: #4A545D; 
$slate-50W: #B4BCC2;
$slate-80W: #E1E4E7;
$slate-90W: #F0F2F3;
$slate-95W: #F8F9F9;
$white: #FFFFFF;

$green: #447F00;
$yellow: #F89100;
$digital-blue: #1E2D73;


$shade-30: #00405C; //hover over state over the component with glibal blue
$global-blue: #005C84; //fill for primary CTA, outline for secondary CTA
$tint-60: #669DB5;
$tint-40: #99BECE; //focus state border outline
$tint-5: #EDF2F4; //selected state on data table row
$tint-2: #F2F4F5; //hover state on data table row

$notification-success: $green;
$notification-error: $red;
$notification-warning: $yellow;

//SPACING ==============================================================================================================
$spacing-01: 2px;
$spacing-02: 4px;
$spacing-03: 8px;
$spacing-04: 12px;
$spacing-05: 16px;
$spacing-06: 20px;
$spacing-07: 24px;
$spacing-08: 32px;
$spacing-09: 40px;
$spacing-10: 48px;
$spacing-11: 64px;
$spacing-12: 80px;
$spacing-13: 96px;

//DIVIDERS ====================================================================================================================
$border-default: 1px solid $grey-20;
$border-primary: 1px solid $global-blue;
$border-thick: 2px solid $grey-20;
$border-thick-dashed: 2px dashed $grey-20;
$border-focused: 2px solid $tint-40;
$border-active: 2px solid $global-blue;
$border-error: 1px solid $notification-error;

//TYPOGRAPHY ====================================================================================================================
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

$font-family-text: 'Roboto', sans-serif;
$font-family-icons: 'Material Icons';
$line-height-small: 20px;
$line-height-regular: 24px;
$line-height-medium: 32px;
$line-height-large: 40px;
$line-height-xlarge: 48px;

$weight-regular: 400;
$weight-medium: 500;
$weight-medium-bold: 600;
$weight-bold: 700;

$font-xxsmall: 10px;
$font-xsmall: 12px;
$font-small: 14px;
$font-regular: 16px;
$font-large: 18px;
$font-xlarge: 20px;
$font-xxlarge: 24px;
$font-xxxlarge: 36px;

//BUTTON ====================================================================================================================
$button-width-minimum: 120px;
$border-radius: 4px;
$border-radius-lg: 6px;
$button-primary-focus-outline: $violet-20W;

//LINK ====================================================================================================================
$link-default-focused-color:  #1E2D73;
$link-hover-active-color:  #483FCD; 
$link-focused-outline-color: #6D65D7;
$link-border-radius: var(--Spacing-02, 4px);
$link-focused-border: var(--Spacing-01, 2px) solid var(--Accent-Violet-20W, #6D65D7);
$link-active-border: var(--Spacing-01, 2px) solid var(--Accent-Slate-50W, #B4BCC2);
$link-border-padding: 0px var(--Spacing-02, 4px);
$link-gap: var(--Spacing-03, 8px);
$link-spacing: 12px;