@import "src/Components/Styles/variables";

.commentCell {
  position : relative;

  & > button {
    background: none;
    border: none;
    padding: 0;
    line-height: 0;
    color: $global-blue;
  }
}

.commentContainer {
  position : absolute;
  top: 32px;
  right: -4px;
  z-index: 100;
}

