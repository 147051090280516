@import "../../../../../../Components/Styles/variables";

.assignmentStatusWrapper {
  display: flex;

  ::-webkit-scrollbar {
    width: 14px;
    background: none;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background: $grey-20;
    border-radius: 20px;
    border: 4px $white solid;
  }

}

.assignmentWrapper {}