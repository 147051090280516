@import 'Project/Website/styles/_layout.scss';

.full {
    width: calc(100% - #{$gutter-width});
}

.radio-label {
    @extend .full;
    font: {
        family: Roboto;
        weight: bold;
        size: 14px;
    };
    margin: {
        bottom: 8px;
    };

    :global(fieldset.error) & {
        color: #DA291C;
    }
};
