@import "src/Components/Styles/variables";

.form-text {
    padding: 12.5px 16.5px;
    border: {
        width: 1px;
        radius: 4px;
    }

    :global(label.error) & {
        border: {
            color: $notification-error;
        }
    }

    &:disabled {
        background-color: $grey-6;
    }

    &:read-only {
        background-color: $grey-6;
        border-color: $grey-20;
        opacity: 1;
    }
}
