body {
  background-color: $grey-3;
  color: $soft-black;
}

.main-content {
  padding-top: 40px;
  padding-bottom: 80px;
  flex-grow: 1;
}
.content-min-height {
  min-height: 50vh;
}

a:active,
a:focus,
a:visited {
  box-shadow: none;
}

a,
tr {
  &:focus {
    outline: 2px solid $tint-40;
  }
  &[target="_blank"]:after {
    content: "\1F5D7";
  }
}

button:focus {
  outline: 2px solid $tint-40;
  box-shadow: none;
}

.page-header {
  margin-bottom: 40px;
}

.justify-right {
  @include flexbox;
  @include flex-direction(row);
  justify-content: flex-end;
}

.pagination {
  margin: auto 24px;
}

.timestamp {
  margin-right: 12px;
}

.flex-container--row {
  @include flexbox;
  @include flex-direction(row);
}

input[type="radio"]:focus::after {
  outline-offset: 3px;
  outline: 2px solid $tint-40;
}

input[type="checkbox"]:focus {
  width: 16px;
  height: 17px;
  position: absolute;
  outline-offset: 3px;
  opacity: 1;
  box-shadow: none;
  outline: 2px solid $tint-40;
  outline-color: $tint-40;
}

.terms-and-conditions__checkbox--wrapper input[type="checkbox"]:focus,
.form__element--checkbox input[type="checkbox"]:focus {
  width: 16px;
  height: 17px;
  position: absolute;
  outline-offset: 3px;
  opacity: 1;
  box-shadow: none;
  outline: 2px solid $tint-40;
  outline-color: $tint-40;
}

.terms-and-conditions__checkbox--wrapper .checkbox input[type="checkbox"] {
  & + label::before {
    top: 4px;
  }
  &:focus + label {
    margin-top: 1px;
    &:before {
      outline: none;
      top: 3px;
    }
  }
}

// Styles to append External Link Icon For Portfolio Insurance External Links
.piAnchorTagExternalLinkIcon {
  &::after {
    content: "";
    -webkit-mask-image: url("~Components/Core/HbtIcon/icons/ExternalLink.svg");
    mask-image: url("~Components/Core/HbtIcon/icons/ExternalLink.svg");
    background-color: currentColor;
    background-size: 16px 16px;
    mask-size: 16px 16px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    height: 16px;
    width: 16px;
    margin-left: 8px;
  }
}
