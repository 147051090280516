// PROGRESS BAR STYLES
#progressBar, #progressBarDetail {
  width: 423px;

  &.form-progress__sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 1rem !important;
  }
}

.form-progress__scrollableContent {
  height: 73vh;
  min-height: 73vh;

  .form-progress {
    &__items {
      display: flex;
      flex-direction: row;

      &:before {
        position: relative;
        left: 13px;
      }

      &:not(:last-child) {
        margin-left: -2px;

        &:before {
          border: 1px solid $grey-6;
          height: 32px;
          content: "";
          top: 32px;
          margin-bottom: 40px;
          width: 0;
        }
      }

      &-status {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-right: 8px;

        &:after {
          font-size: 24px;
          font-family: "Material Icons";
          position: relative;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: "liga";
        }
      }

      &-link {
        margin: 0;
        color:$soft-black;
        font-weight: $weight-regular;
        height: 20px;

        &:hover {
          color: $grey-80;
          font-weight: $weight-medium;
        }

        &:active {
          box-shadow: none;
          outline: none;
        }

        &:focus {
          box-shadow: none;
          outline: $border-focused;
        }
      }

      &--default .form-progress__items-status {
        background-color: $grey-6;
        border: $grey-20 4px solid;

        &:after {
          content: "trip_origin";
          color: $grey-20;
          top: -20px;
        }
      }

      &--pending .form-progress__items-status {
        background-color: $grey-60;
        border: $grey-20 4px solid;

        &:after {
          content: "trip_origin";
          color: $grey-20;
          top: -20px;
        }
      }

      &--pending .form-progress__items-link {
        color: $grey-80;
        font-weight: $weight-bold;
      }

      &--success .form-progress__items-status {
        background-color: $white;
        border: none;

        &:after {
          content: "check_circle_outline";
          color: $notification-success;
        }
      }

      &--success .form-progress__items-link {
        color: $notification-success;
        font-weight: $weight-bold;
      }

      &--error .form-progress__items-status {
        background-color: $white;
        border: none;

        &:after {
          content: "error_outline";
          color: $notification-error;
        }
      }

      &--error .form-progress__items-link {
        color: $notification-error;
        font-weight: $weight-bold;
      }
    }
  }
}

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .js-stickybit-parent {
    .card {
      word-wrap: normal;
    }

    .sticky-top {
      top: 0;
    }

    .form-progress {
      &__sticky.IEsticky {
        width: 423px;
        top: 0;

        .form-progress__scrollableContent {
          height: 77vh;
          min-height: 77vh;
        }
      }

      &__sticky.IEstuck {
        position: fixed !important;

        .sticky-top {
          top: 0;
        }

        .form-progress__scrollableContent {
          height: 62vh;
          min-height: 62vh;
        }
      }

      &__items {
        &--section-C {
          width: 41px;
        }

        &--section-I {
          width: 28px;
        }

        &--default .form-progress__items-status {
          &:after {
            content: "normal";
          }
        }

        &--pending .form-progress__items-status {
          &:after {
            content: "normal";
          }
        }
      }
    }
  }
}

