@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.half {
  width: calc(45% - 16px);
  padding: {
    bottom: 32px;
  }

  &Left {
    @extend .half;
    margin: {
      left: 16px;
      right: 32px;
    }
  }
}

.full {
  width: 100%;
  padding: {
    bottom: 32px;
  }
  margin: {
    left: 16px;
    right: 32px;
  }
}

.radio-group {
  width: calc(45% - 16px);
  margin: {
    left: 16px;
    right: 16px;
  }
  padding: {
    bottom: 32px;
  }

  &-full {
    @extend .radio-group;
    width: 100%;
  }
}

.textArea {
  width: 100%;
  height: 550px !important;
  font-size: 14px;
    &:focus-visible{
      @extend .focusElement;
    }
  border-radius: 4px;
  padding: 12px 16px;
  margin: 16px;
}

.textArea::-webkit-scrollbar {
  width: 8px;
}

.textArea::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #d8d8d8;
}

.header {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 1px solid $grey-20;
}

.cancelIcon {
  background: none;
  border: 0;
}

.detailRequestedWrapper {
  display: flex;
  flex-direction: row;
  margin-left: -16px;
}

.refundLetterPaddingTop {
  padding-top: 32px;
}

.pullDown {
  @include desktop {
    margin-top: 24px;
  }
  margin-top: 0px;
}

.focusElement {
   border-collapse: separate;
   box-shadow: inset 0px 0px 0px 2px $tint-40;
   box-sizing: border-box;
   outline-color: transparent;
}

.outlineStyling { 
   border: 1px solid $grey-20; 
}

.body {
  margin: 32px;
  > div > label > input {
    @extend .outlineStyling;
    &:focus{
      @extend .focusElement;
    }
  }
  > div:first-of-type > label > div:first-of-type > div > input {
    &:focus{
      @extend .focusElement;
    }
  }
  > div:nth-of-type(6) > label:last-of-type > div > select {
    &:focus{
      @extend .focusElement;
    }
  }
  > div:nth-of-type(8) > label > textarea {
    &:focus-within{
      @extend .focusElement;
    }
  }
}

.buttonGroup {
  margin: 32px;
  justify-content: space-evenly;
}

.responsiveButton {
  width: calc(50% - 16px);
}
