@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/variables";

.addButtons {
  margin-right: 16px;
  display: inline-block;
  width: 185px;
}

.buttonLayoutCreate {
  > div {
    padding-left: 0px;
  }
}

.cardItem {
  border: 1px solid $grey-20;
  margin: 24px 0;
  border-radius: 3px;

  > div:first-of-type {
    padding: 32px 32px 0;
  }

  section > div > div {
    p:first-of-type,
    > div {
      margin-bottom: 2rem !important;
    }
  }

  div:empty {
    display: none;
  }
  label,
  legend {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
