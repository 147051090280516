
@import "src/Components/Styles/variables";

.actionMenu {
  button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    display: block;
    text-align: left;
  }

  & > button {
    padding: 4px 6px;
  }
}

.subNavContainer {
  position: absolute;
  top: 0;
  z-index: 1;
  border-radius: 4px;
  background: #FFF;
  border: $global-blue 1px solid;
  box-shadow: 0 4px 8px $grey-20;
  padding: 0;
  min-width: 188px;
  margin-top: 4px;
  margin-left: -4px;
  transform: translate3d(5px, 35px, 0px)!important;
  display: block;

  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: flex;
    align-items: center;
  }

  button {
    padding: 16px 24px;
    position : relative;
    width: 100%;
    border-bottom: 1px solid $grey-20;

    &:hover {
      background: $tint-5;
    }
    &:disabled {
      background: $grey-3;
      opacity: 0.5;
      color: $soft-black;
    }
  }
}

.actionMenuItemIconWrapper {
  position : absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);

  .icon {
    margin-top: 2px;
    font-size: 18px;
  }
  .imageSize {
    width: 16px;
    height: 16px;
  }
}
.actionMenuItemText {
  font-size: $font-small;
  margin-left: 24px;
}
