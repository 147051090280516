@import "Components/Styles/Core/variables";
@import "Components/Styles/Core/mixins";
@import "Components/Styles/Core/adaptive.scss";

.notificationWrapper {
    padding: 0 $spacing-11;
    margin-top: -$spacing-07;
  
    @media screen and (max-width: 991px) {
      padding: 0 $spacing-08;
    }
  }
  
  :global{
    .tab-content {
      position: relative;
    }  

    .MuiDrawer-paper {
      overflow-y: hidden !important;
    }

    [class*="toastNotification"] {
      position: fixed !important;
      bottom: $spacing-08 !important;
      z-index: 100;
    }
  }

.mainContent {
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
}

.dropdownIcon {
  margin-left: 5px;
}

.layoutContainer {
  display: flex;
  width: 100%;
  padding: $spacing-08 0;
  flex-direction: column;
  border-radius: $border-radius;
  background: $white;
  position: relative;
  
  .subheading {
    @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-70B);
    padding-bottom: $spacing-07;
  }
}

.cell {
  width: 120px !important;
}

.fileTransferDataTableContainer {
  padding: $spacing-03 0;

  .tableCellFileDownloadBtn {
    z-index: unset;
  }

  .tableCellFileDownloadBtn div span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.purposeTestDatatablePaginationContainer {
  margin-top: $spacing-10;
  padding-top: $spacing-08;
  border-top: 1px solid $slate-50W;
}

.tabDrawer {
  overflow-y: hidden;
}

.flyoutContainer {
  width: 620px;
  overflow-y: scroll;
  margin-bottom: $spacing-13;
  max-height: calc(100vh - 200px);
}

.flyoutHeaderContainer {
  padding: $spacing-07 $spacing-08;
}

.flyoutBody {
  overflow-y: auto;
  padding: $spacing-07 $spacing-08;
}

.piFileTransferFlyoutAccordion {
  &::before {
    display: none;
  }
  &:first-of-type {
    border-bottom: none;
  }

  &:last-of-type {
    margin-top: $spacing-01;
    border-top: none;
  }

  &:not(:first-of-type):not(:last-of-type) {
    border-top: none;
    border-bottom: none;
    margin-top: $spacing-01;
  }

  .piFileTransferFlyoutAccordionSummary {
    &:hover {
      background-color: $violet-90W !important;
      font-weight: 700 !important;
    };

    &:focus {
      background-color: $white !important;
      outline: $spacing-01 solid $violet-20W !important;
      font-weight: 700 !important;
    };

    &:active {
      background-color: $violet-90W !important;
      border-bottom: 1px solid $slate !important;
      font-weight: 700 !important;
    }
  }
}

.flyoutFooter {
  background-color: $white;
  bottom: 0;
  box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25);
  left: 0;
  padding: $spacing-07 $spacing-08;
  position: absolute;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.icon {
  color: $text-links-text-header;
  margin-top: -7px;
}

.accordion {
  box-shadow: none !important;
}

.accordionHeader {
  align-items: baseline !important;
  padding: 0 $spacing-03 0 0 !important;
}

.accordionTitle {
  @include font-settings($font-regular, $weight-bold, $line-height-regular, $text-links-text-header);
  padding-bottom: $spacing-03;
}

.accordionSubtitle {
  @include font-settings($font-small, $weight-regular, 150%, $text-links-text-header);
}

.accordionUsersTitle {
  padding-bottom: $spacing-03;
}

.checkboxContainer {
  margin-left: -$spacing-03;
  padding-bottom: $spacing-05;
}

.usersCheckboxContainer {
  margin-left: -$spacing-03;
  padding-bottom: $spacing-05;

  &:first-of-type {
    padding-top: $spacing-05;
  }
}

.piFileTransferFilterTagWrapper {
  gap: $spacing-05;

  .tagContainer {
    @include flex(center, unset);
    align-content: center;
    flex-wrap: wrap;
    flex: 1 0 0;
    min-width: 600px;
    padding-right: $spacing-08;
    gap: $spacing-04;
  }
}