@import "src/Components/Styles/variables";

.declineClaimFormHeader {
  margin: 32px 0px;
  border-bottom: solid 1px $grey-20;
  & > h2 {
    margin-bottom: 24px;
  }
  .textHeader {
    margin: 32px;
  }
  .closeButton {
    display: block;
    position: absolute;
    right: 32px;
    top: 32px;

    button {
      background: 0;
      border: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
    }
  }
}
.declineClaimFormBody {
  margin: 32px;
  & > p {
    margin-bottom: 24px;
  }
  .textStyle {
    margin: 32px 0px;
  }
  .declineForm {
    .twoColumns {
      display: flex;

      &.formButtonsWrapper {
        border-top: $grey-20 1px solid;
        padding-top: 32px;

        & > div {
          margin: 0 9px;
        }
      }

      & > div {
        flex-grow: 1;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .saveIconWrapper {
      position: absolute;

      & > span {
        animation: spin 0.9s linear infinite reverse;
        left: -28px;
        position: absolute;
      }
    }

    .templateWrapper {
      .languageCodeWrapper {
        margin-bottom: 24px;
      }
      .notificationTemplate {
        margin-top: 24px;
      }
      .notificationTemplateWrapper {
        height: 315px;
        border: 1px solid $grey-20;
        border-radius: 4px;
        background-color: $grey-3 ;
        padding: 12px 12px;
        font-size: 14px;
        margin-bottom: 24px;
      }
      .declineNotificationTemplate {
        textarea{
          height: 160px;
        }
      }
      .selectOption {
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          margin: 0;
          padding: 0;
          margin-left: 16px;
        }
        input[type=checkbox] {
          appearance: none;
          padding: 1px;
          height: 16px;
          width: 16px;
          border-radius: 2px;
          border: thin solid $black;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        input[type=checkbox]:checked {
          border: none;
          background-color: $global-blue;
        }
        input[type=checkbox]:checked:after {
          color: $white;
          content: '\2713';
          margin-bottom: -1px;
        }
      }
      
    }
  }
}