@import '../../../../Components/Styles/variables';

.documentRequestWrapper {
	display: flex;
	flex-direction: column;
	padding: 32px;

	.buttonWrapper {
		display: flex;
		flex-direction: row;
		border-top: thin solid $grey-20;
		padding-top: 33px;
		button {
			margin-right: 9px;
			&:last-child {
				margin-right: 0;
				margin-left: 9px;
			}
		}
	}
}

.headerWrapper {
	align-items: center;
	border-bottom: 1px solid $grey-20;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 32px 35px 32px 32px;
	h2 {
		margin: 0;
	}
}

.closeButton {
	button {
		background: 0;
		border: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
	}
}

.textWrapper {
	color: $black;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 32px;
	padding-right: 32px;
}


