// Common button styles
@mixin button-base {
    border-radius: $button-border-radius-base;
    font-family: $button-font-family;
    font-size: $button-font-size-base;
    font-weight: $button-font-weight;
    padding: $button-padding-base;
    gap: $button-gap-base;
    max-width: $button-max-width;
    min-width: $button-min-width;
    line-height: $button-line-height;
    box-sizing: border-box;
    text-align: center;
    word-wrap: break-word;
    align-items: center;
    align-content: center;
    justify-content: center;
    box-sizing: border-box;
  }

@mixin button-hover($hover-color) {
    background: $hover-color;
}

@mixin button-focus($focus-outline) {
    outline: 2.5px solid $focus-outline !important;
    outline-offset: 2.5px !important;
}

@mixin button-active {
    border-color: $primary-pressed-color;
    box-shadow: $button-active-box-shadow;
}

@mixin secondary-button-hover($hover-color) {
    color: $hover-color;
    border-color: $hover-color;
}