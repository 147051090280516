@import "src/Components/Styles/variables";

.assignedStatusContainer {
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }
}
.assignedToContainer {
  margin-right: 40px;
}

.valueWrapper {
  padding: 8px 16px;
  border: $grey-20 1px solid;
  border-radius: 4px;
  width: 250px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.assignedToValueContainer {
  & > span:first-child {
    margin-right: 8px;
    margin-top:-5px
  }
}

.statusIndicator {
  background: $grey-20;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 7px;
}

.label {
  font-weight: bold;
  margin-right: 8px;
}

.statusValueContainer {}

.status {
  // cancelled, no claim payable, invalid or missing status (not an enum)
  &-1, &-6, &-0 {
    background-color: $grey-20;
  }

  // new
  &-2 {
    background-color: $global-blue;
  }

  // in progress, refund requested
  &-3, &-7 {
  background-color: $notification-warning;
  }

  // declined
  &-4 {
    background-color: $notification-error;
  }

  // paid, reimbursed
  &-5, &-8 {
    background-color: $notification-success;
  }
}
