.hideLabel {
    span:first-of-type {
        visibility: hidden;
    }
}

.roleCodePadding {
    padding: 0px;
}

.probationaryOffice{
    margin-top: 20px;
}