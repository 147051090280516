.table {
    overflow-x: scroll;

    &--border-top {
        border-top: $border-default;
    }

    &__container {
        width: 100%;
        display: table;
    }

    &__row {
        display: table-row;
    }

    &__icon-group {
        @include flexbox;
        justify-content: center;

        &-icon {
            &:first-child {
                padding-right: 20px;
            }
        }
    }

    &__header, &__data {
        font-family: $font-family-text;
        font-size: $font-small;
        letter-spacing: 0;
        line-height: $line-height-regular;
        white-space: nowrap;
        color: $soft-black;
        border-bottom: $border-default;
        height: 24px;
        min-width: min-content;
        padding: 20px 16px;
        vertical-align: middle;
        display: table-cell;

        p {
            margin-bottom: 0;
        }

        &:first-child {
            padding-left: 24px;
        }
        &:last-child {
            padding-right: 24px;
        }
    }
    &__header {
        font-weight: $weight-bold;
        color: $grey-80; 
        @include align-items(center);
        .sortLink {
            color: $grey-80; 
        }
    }

    &__header-toggle {
        display: inline-flex;
        @include flex-direction(column);

        i {
            line-height: .3;
        }
    }

    &__scroll {
        top: 50%;
        position: absolute;

        &--right {
            right: 0;
        }
    }

    &__scroll-button {
        font-family: $font-family-icons;
        font-size: 32px;
        color: $white;
        background-color: $grey-60;
        opacity: 0.5;
        height: 80px;
        width: 40px;
        margin-right: 16px;
        @include flexbox;
        justify-content: center;
        @include align-items(center);

        &--left {
            @include border-radii($topRight: 40px, $bottomRight: 40px);

            &:after {
                content: "chevron_left";
            }
        }
    
        &--right {
            @include border-radii($topLeft: 40px, $bottomLeft: 40px);

            &:after {
                content: "chevron_right";
            }
        }
    }

    &__page-actions--bottom {
        padding: 24px 0px 0px;
    }
}
