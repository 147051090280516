@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;
  grid-auto-flow        : dense;

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "sidebar content";
    grid-template-columns : 8fr 4fr;

    & > div:first-child { grid-area : sidebar; }
    & > div:last-child { grid-area : content; }
  }
}

.workoutBodyLayout {
    display               : block;  
    & > div { margin-bottom : 32px; }
    & > div:first-child { width: 100%; }
    & > div:last-child {  width: 100%; }

    @include desktop {
        display: inline-flex;
        & > div:first-child { width: 50%; margin-right: 32px; }
        & > div:last-child {  width: 50%; }
    }
}

.showForDesktopOnly {
    display: none;

    @include desktop {
        display: block;
    }
}

.hideForDesktop {
    display: block;

    @include desktop {
        display: none;
    }
}

.stickyFooter {
    border-top: $grey-20 1px solid;
    padding: 24px 0;

    button {
        margin-left: 16px;
        min-width: 178px;
    }
}

.cardNoMargin {
    & > div:first-child {
        margin-bottom: 0px;
        border-radius: 4px 4px 0 0;
    }

    @include desktop {
        & > div:first-child {
            margin-bottom: 24px;
            border-radius: 4px;
        }
    }

}

.footerButtons {
    margin: 0 32px 32px 32px;

    button {
        margin-right: 8px;
    }
}

.footerButtonContainer {
    border: $grey-20 1px solid;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding-top: 32px;

    @extend .hideForDesktop;
}

.btn {
    position: relative;
    top: 20px;
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0; 

    &:hover {
        cursor: pointer;
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }

    &:focus {
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }
}

.closeWrapper {
    position: fixed;
    top: 35px;
    left: 550px;
    width: 50px;
    height: 40px;
    z-index: 100;
    background: none;
    border: none;
    background-color: $grey-3;
    span:focus {
        outline: 2px solid $tint-40;
    }
}


.closebtn {
    padding-top: 8px;
    @extend .btn;

    &:after {
        font-family: $font-family-icons;
        font-size: $font-xxlarge;
        content: "close";
        color: $black;
    }
}

.openSideDrawer {
    margin-bottom: 32px;
}

.openSideDrawerButton {
    color: $global-blue;
    background: none;
    border: none;
    line-height: 24px;
    font-size: 14px;
    font-weight: bold;
    padding: 0;

    :hover {
        text-decoration: underline;   
        text-decoration-color: $global-blue;
        cursor: pointer;
    }
}

.sideDrawer {
    display: block;
    
    & > div:first-child {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: $black;
        opacity: 0.5;
        z-index: 1;
    }

    & > div:last-child {
        display: block;
        position: fixed;
        width: 624px;
        height: 100%;
        top: 0;
        right: 0;
        background: $white;
        z-index: 100;
        box-sizing: border-box;

        overflow-y: scroll;
    }

    & > div {
        transition-timing-function: ease-out;
        transition: 0.3s;
        transform: translateX(0);

        & > fieldset {
            & > div {
                margin-bottom: 0px;
            }
        }
    }

    @include desktop {
        display: block;

        & > div {
            transform: none;
            transition: 0s;

            & > fieldset {
                & > div {
                    margin-bottom: 24px;
                }
            }
        }

        & > div:first-child {
            display: none;
        }

        & > div:last-child {
            display: block;
            position: relative;
            width: auto;
            height: auto;
            top: auto;
            right: auto;
            overflow-y: hidden;
        }
    }
}

.hideSideDrawer {
    display: block;
    position: absolute;

    & > div {
        transition-timing-function: ease-in;
        transition: 0.3s;
        transform: translateX(130%);
    }
    & > div:last-child {
        z-index: 0;
    }

    @include desktop {
        position: relative;

        & > div {
            transform: none;
            transition: 0s;
        }
    }
}  

.headerText {
    & > div {
        margin-bottom: 8px;
        box-sizing: border-box;
    }

    @include desktop {
        & > div {
            margin-bottom: 40px;
            box-sizing: border-box;
        }
    }

}

.cardHeaderActionButtons {
    background-color: $white;
    border-bottom: none;
    padding: 40px 32px 32px 32px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    display: block;
    width: 100%;
    text-align: right;

    button {
        margin: 0 8px;
    }
}