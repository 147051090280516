
.label{
    font-size: $font-xsmall;
    font-weight: $weight-bold;
    text-transform: uppercase;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
    padding: 4px 8px;
    height: 24px;
    @include border-radius(4px);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; 

    &--primary{
        color: $global-blue;
        background-color: $tint-5;
    }
}