@import "../../Styles/variables";

.counterWrapper {
  &Count {
    font-size: $font-small;
  }

  &Warn {
    font-size: $font-small;
    color: $notification-error;
  }
}
