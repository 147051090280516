@import "src/Components/Styles/base";
@import "src/Components/Styles/adaptive";

.formContainer {
  padding: 0 34px 34px 34px; 

:global {
  .header3 {
    font-size: $font-xlarge;
    margin-top: 40px;
    margin-bottom: 32px;
  }
}
}

.loanInfoWrapper {
  @include desktop {
    margin: {
      top: 32px;
      bottom: 32px;
    };
  }
}

iframe {
  width: 100%;
  height: 500px;
  border: 0;
}