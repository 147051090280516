@import 'Components/Styles/_variables.scss';

%card {
    position: relative;
    display: flex;
    flex-direction: column;

    min-width: 0;

    word-wrap: break-word;

    font-size: $font-small;

    background: {
      color: $white;
      clip: border-box;
    };

    border: 1px solid $grey-6;
    border-radius: 4px;

    margin: {
      bottom: 24px;
    };
    padding: 32px;
}

.balanceCard {
  @extend %card;

  hr {
    width: 100%;
    height: 1px;
    margin: {
      top: 0;
      bottom: 32px;
    }
  }
}

.balanceRow {
  display: flex;
  justify-content: space-between;
  margin: {
    bottom: 32px;
  }
}

.netBalanceRow {
  @extend .balanceRow;
  font: {
    size: $font-large;
    weight: bold;
  }
}

.buttonBar {
  display: flex;
  gap: 32px;
  padding: 32px;
  margin: {
    left: -32px;
    right: -32px;
    bottom: -32px;
  }
  border: 1px solid $grey-6;
}
