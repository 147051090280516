@import "src/Components/Styles/adaptive";
@import "../../../../Components/Styles/variables";

.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;
  grid-auto-flow        : dense;

  & > div { margin-bottom : 32px; }

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "sidebar content";
    grid-template-columns : 8fr 4fr;

    & > div:first-child { grid-area : sidebar; }
    & > div:last-child { grid-area : content; }
  }
}

.showForDesktopOnly {
    display: none;

    @include desktop {
        display: block;
    }
}
.arrearsReportTable {
	tbody tr td:nth-child(3) {
		max-width: 347px;
		white-space: normal;
	}
}

.arrearsReportInvalidRow {
  tr td {
    color: red !important;
  }
}

.displayHidden {
  display: none;
}

.hideForDesktop {
    display: block;

    @include desktop {
        display: none;
    }
}

.buttonLink {
  border: none;
  cursor: pointer;
  background: none;
  color: $global-blue;
  text-decoration: underline;
  text-align: left;
}

.failedErrorNotification {
  width: 800px;
  @include desktop {
    width: 855px;
  }
}

.replaceButton {
  padding: 5px;
  font-weight: normal;
  font-size: $font-small;
  border: 1px solid $grey-80;
}

.stickyFooter {
  border-top: $grey-20 1px solid;
  padding: 24px 0;

  button {
      margin-left: 16px;
      min-width: 178px;
  }
}

.counterWrapper {
  &Count {
    font-size: $font-small;
  }

  &Warn {
    font-size: $font-small;
    color: $notification-error;
  }
}
