@import "./hbtIconVariables.scss";

.smIcon {
  width: $icon-sm !important;
  height: $icon-sm !important;
}

.mdIcon {
   width: $icon-md !important;
   height: $icon-md !important;
 }

.lgIcon {
  width: $icon-lg !important;
  height: $icon-lg !important;
}

.xlIcon {
  width: $icon-xl !important;
  height: $icon-xl !important;
}
