@import "../../Styles/Core/variables.scss";
@import "./tooltipVariables.scss";

@mixin left($valueRight){
  @include tooltip(
    50%, auto, auto, $valueRight,
    54%, auto, auto, -5px, 5px 0 5px 5px, transparent transparent transparent $arrow-shadow-rgba,block,
    50%, auto, auto, -5px, 5px 0 5px 5px, transparent transparent transparent $slate-80W,
       
  );
}

@mixin right($valueLeft){
  @include tooltip(
    50%, auto, $valueLeft, auto,
    52%, auto, -5px, auto, 5px 5px 5px 0, transparent $arrow-shadow-rgba transparent transparent, block,
    50%, auto, -5px, auto, 5px 5px 5px 0, transparent $slate-80W transparent transparent        
  );    
}

@mixin bottom($valueTop, $valueLeft, $afterValueLeft, $afterValueRight){
  @include tooltip(
    $valueTop, auto, $valueLeft, auto,
    0, auto, auto, auto, 0, transparent,none,
    -2px, auto, $afterValueLeft, $afterValueRight, 0 5px 5px 5px, transparent transparent $slate-80W transparent
  );
}

@mixin bottomLeft($valueTop, $valueLeft){
  @include bottom($valueTop, $valueLeft, auto, $spacing-04);
}

@mixin bottomCenter($valueTop, $valueLeft){
  @include bottom($valueTop, $valueLeft, auto, $center-arrow-shadow-value-offset);
}

@mixin bottomRight($valueTop, $valueLeft){
  @include bottom($valueTop, $valueLeft, $spacing-04, auto);
}

@mixin top($valueBottom, $valueLeft, $valueRight,
$beforeValueBottom, $beforeValueLeft, $beforeValueRight, 
$afterValueBottom, $afterValueLeft, $afterValueRight) {
  @include tooltip(
    auto, $valueBottom, $valueLeft, $valueRight,
    auto, $beforeValueBottom, $beforeValueLeft, $beforeValueRight, 6px 6px 0 6px, $arrow-shadow-rgba transparent transparent transparent, block,
    auto, $afterValueBottom, $afterValueLeft, $afterValueRight, 5px 5px 0 5px, $slate-80W transparent transparent transparent    
  );
}

@mixin topRight($valueBottom, $valueLeft){
  @include top($valueBottom, $valueLeft, auto,
    $top-tooltip-before-value-bottom, $top-left-and-right-tooltip-arrow-shadow-offset, auto,
    $top-tooltip-after-value-bottom, $top-left-and-right-tooltip-arrow-offset, auto
  );
}

@mixin topLeft($valueBottom, $valueRight){
  @include top($valueBottom, auto, $valueRight, 
    $top-tooltip-before-value-bottom, auto, $top-left-and-right-tooltip-arrow-shadow-offset, 
    $top-tooltip-after-value-bottom, auto, $top-left-and-right-tooltip-arrow-offset
  );
}

@mixin topCenter($valueBottom, $valueLeft){
  @include top($valueBottom, $valueLeft, auto, 
    $top-tooltip-before-value-bottom, $center-arrow-shadow-value-offset, auto, 
    $top-tooltip-after-value-bottom, $center-arrow-value-offset, auto
  );
}

@mixin tooltip(
  $baseValueTop, $baseValueBottom, $baseValueLeft, $baseValueRight, 
  $beforeValueTop, $beforeValueBottom, $beforeValueLeft, $beforeValueRight, $beforeArrowWidth, $beforeArrowColor,
  $beforeDisplay,
  $afterValueTop, $afterValueBottom, $afterValueLeft, $afterValueRight, $afterArrowWidth, $afterArrowColor) {
  top: $baseValueTop;
  bottom: $baseValueBottom;
  left: $baseValueLeft;
  right: $baseValueRight;
  transform: translateY(-50%);

  // arrow shadow
  &::before {
    display: $beforeDisplay;
    top: $beforeValueTop;
    bottom: $beforeValueBottom;
    left: $beforeValueLeft;
    right: $beforeValueRight;
    transform: translateY(-50%);
    border-width: $beforeArrowWidth;
    border-color: $beforeArrowColor;
    z-index: 0;
  }

  // arrow
  &::after {    
    top: $afterValueTop;
    bottom: $afterValueBottom;
    left: $afterValueLeft;
    right: $afterValueRight;
    transform: translateY(-50%);
    border-width: $afterArrowWidth;
    border-color: $afterArrowColor;
    z-index: 1;
  }
}