@import "Components/Styles/Core/variables";

.dataTable {
  --PITableScrollContainershiftButtonColor: #{$navy};
  --PITableScrollContainershiftButtonFocusColor: #{$violet-20W};

  display: block;

  table {
    width: 100%;
    table-layout: auto;

    th {
      width: 10%;
      white-space: pre-wrap;
      text-align: center;
      vertical-align: top;

      &:nth-of-type(1) {
        min-width: 7rem;
      }

      &:nth-of-type(2) {
        min-width: 7rem;
      }

      &:nth-of-type(3) {
        min-width: 7rem;
      }

      &:nth-of-type(4) {
        min-width: 7rem;
      }

      &:nth-of-type(5) {
        min-width: 7rem;
      }

      &:nth-of-type(6) {
        min-width: 12rem;
      }

      &:nth-of-type(7) {
        min-width: 10rem;
      }

      &:nth-of-type(8) {
        min-width: 7rem;
      }

      &:nth-of-type(9) {
        min-width: 7rem;
      }

      &:nth-of-type(10) {
        min-width: 7rem;
      }

      &:nth-of-type(11) {
        min-width: 12rem;
      }
    }

    tbody > tr:nth-child(n) {
      &:focus {
        outline: $spacing-01 solid $violet-20W;
      }
    }

    tbody > tr:nth-child(even) {
      background: $slate-95W;
    }

    tbody tr td {
      width: 10%;
      white-space: pre-wrap;

      &:first-of-type {
        padding-left: $spacing-08;
      }

      &:nth-of-type(2) {
        min-width: 7rem;
      }

      &:nth-of-type(3) {
        min-width: 7rem;
      }

      &:nth-of-type(4) {
        min-width: 7rem;
      }

      &:nth-of-type(5) {
        min-width: 7rem;
      }

      &:nth-of-type(6) {
        min-width: 12rem;
      }

      &:nth-of-type(7) {
        min-width: 10rem;
      }

      &:nth-of-type(8) {
        min-width: 7rem;
      }

      &:nth-of-type(9) {
        min-width: 7rem;
      }

      &:nth-of-type(10) {
        min-width: 7rem;
      }

      &:nth-of-type(11) {
        min-width: 12rem;
      }
    }
    }

    &Footer {
      white-space: nowrap;
      border-top: 1px solid #CCC;
      padding: $spacing-07 0 0 0;

      div:first-of-type > nav > ul {
        flex-wrap: nowrap;
      }
    }
}

.actionIcons {
  font-size: $font-large;
}

.form {
  textarea {
    min-height: 50px;
    width: 8rem;
    height: 50px;
  }

  input {
    width: 100%;
  }
}

.externalToolAccessTableFormInputs {
  input, textarea {
    color: $slate-70B;

    :global(label.error) & {
      border: {
        color: $red;
      }
    }

    &:disabled {
      background-color: $slate-90W;
    }

    &:read-only {
      background-color: $slate-90W;
      border-color: $slate-90W;
      opacity: 1;
    }

    &:focus {
      box-shadow: inset 0 0 0 $spacing-01 $slate-50W;
      outline: $spacing-01 solid $violet-20W;
    }
  }
}

.externalToolAccessTableFormDropdown {
  select {
    border-radius: $spacing-02;
    background: $white;

    &:hover, &:active {
      border: $spacing-01 solid $slate;
    }

    &:focus {
      box-shadow: inset 0 0 0 $spacing-01 $slate-50W;
      outline: $spacing-01 solid $violet-20W;
      outline-offset: $spacing-01;
    }
  }
}

.externalToolAccessCheckbox {
  label {
    &::before {
      border: $black 1px solid;
      top: 7px;
      left: 9px;
    }

    &::after {
      border-bottom: $white $spacing-01 solid;
      border-left: $white $spacing-01 solid;
      left: 11px;
      top: 10px;
    }
  }

  input[type="checkbox"]:checked + label::before {
    background: $navy;
    border-color: $navy;
  }

  input[type="checkbox"]:focus {
    outline: $spacing-01 solid $violet-20W;
    outline-color: $violet-20W;
  }

  input[type="checkbox"]:focus + label::before {
    border-color: $navy;
  }

  input[type="checkbox"][readonly] + label::before,
  input[type="checkbox"]:disabled + label::before,
  input[type="checkbox"][readonly]:checked + label::before,
  input[type="checkbox"]:checked:disabled + label::before {
    background: $slate-50W;
    border: 1px solid $slate-50W;
    top: 3px;
    left: 1px;
  }

  :global(fieldset.error) & {
    input[type="checkbox"] + label::before {
      border-color: $red;
    }
  }
}

.externalToolAccessRemoveBtn {
  &:hover {
    background: $white;
    border-color: $violet;
    color: $violet;
  }

  &:active {
    border-color: $violet;
    box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25);
  }

  &:focus {
    outline: $spacing-01 solid $violet-20W;
  }
}