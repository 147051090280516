@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";
@import "src/Components/Styles/BaseComponentStyles/topNavButton";

.navbar {
  background: $white;
  border-bottom: $grey-20 1px solid;
  min-width: 768px;
  @include desktop {
    width: 100%;
  }
}
.navbarContentContainer {
  @extend .page-wrapper;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brandingContainer {
  padding: 40px 0;

  & > a {
    display: flex;
    align-items: center;
  }
}

a.hideNavigation {
  position: absolute;
  top: -3em;
}

a.hideNavigation:focus {
  top: 0;
  outline: 2px solid #99BECE;
}

.brandingLogo {
  img {
    width: 111px;
    height: auto;
  }
}

.brandingSiteTitle {
  margin-left: 24px;
  padding-top:24px;

  small {
    font-size: $font-xsmall;
    font-weight: bold;
    line-height: 24px;
  }

  h1 {
    font-size: $font-xlarge;
    font-weight: normal;
    line-height: 18px;
  }
}

.topNavContainer {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;

  & > li {
    margin: 4px;

    @include desktop {
      margin: 0 12px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    & > button {
      padding: 8px;

      @include desktop {
        padding: 0;
      }
    }
  }
}

.signOutButton {
  @extend .topNavButtonBase;

  text-decoration: underline;
  display: none;

  @include desktop {
    display: initial;
  }
}
// navbar navbar-expand-lg container-fluid navigation
// navigation__header
// navigation__header-logo