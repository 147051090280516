@import "src/Components/Styles/variables";

.stickyFooter {
  position : fixed;
  bottom: 0;
  left: 0;
  display: block;
  width: 100vw;
  background: $white;
  box-shadow: 0 -1px 2px rgba(0,0,0, 0.2);
  z-index: 1;
}