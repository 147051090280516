@import "../../../../../../Components/Styles/variables";

.documentRequestWrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.headerWrapper {
  align-items: center;
  border-bottom: 1px solid $grey-20;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 35px 32px 32px;
  h2 {
    margin: 0;
  }
}

.closeButton {
  button {
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
}

.textWrapper {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
}

.categoriesWrapper {
  margin-bottom: 32px;
  label {
    font-weight: bold;
    font-size: 14px;
  }
  > label > div > div > div:first-of-type > div:first-of-type {
    color: $soft-black;
  }
}

.templateWrapper {
  margin-bottom: 32px;
}

.templateTextArea {
  textarea{
    height: 280px;
  }
}

.notesWrapper {
  margin-bottom: 32px;
}

.notesTextArea {
  textarea {
    height: 160px;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  border-top: thin solid $grey-20;
  padding-top: 32px;
  button {
    margin-right: 9px;
    &:last-child {
      margin-right: 0;
      margin-left: 9px;
    }
  }
}

.selectOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    margin-left: 16px;
  }
  input[type=checkbox] {
    appearance: none;
    padding: 1px;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    border: thin solid $black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type=checkbox]:checked {
    border: none;
    background-color: $global-blue;
  }
  input[type=checkbox]:checked:after {
    color: $white;
    content: '\2713';
    margin-bottom: -1px;
  }
}

.requestTemplateContentWrapper {
  border: 1px solid $grey-20;
  border-radius: 4px;
  background-color: $grey-3 ;
  padding: 12px 12px;
  font-size: 14px;
}

.radioWrapper {
  margin-bottom: 32px;
  label {
    font-size: 14px;
  }
}
