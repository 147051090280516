@import "src/Components/Styles/variables";
@import 'src/Components/Styles/adaptive';

.dataTable {
  position: relative;
}

.dataTableFillWidth {
  width: 100%;
  margin-bottom: 24px;
  overflow-x: scroll;

  @include desktop {
    overflow-x: hidden;
  }

  &>table {
    width: 100%;
    table-layout: auto;
  }
}

.hScrollWrapper {
  position: relative;
}

.hScrollContainer {
  position: relative;
  margin-bottom: 24px;
  overflow-x: scroll;
  overflow-y: visible;

  .dataTable {
    margin-bottom: 36px;
  }

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey-20;
    border-radius: 4px;

    &:hover {
      background: $grey-60;
    }
  }
}