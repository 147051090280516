@import 'Project/Website/styles/main.scss';

@mixin columnWidth ($span, $cols: 8, $gutter: 16px) {
    width: calc(#{ $span } * ((100% - #{($span - 1) * $gutter}) / #{$cols} ) + #{($span - 1) * $gutter });
}

.headings {
    width: 100%;
    padding: {
        bottom: 0;
    }
    margin: {
        top: 16px;
    }
}

.heading {
    display: inline-block;
    width: calc(50% - 16px);

    &:first-child {
        margin: {
            right: 32px;
        }
    }
}

.income-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.delete-row-button {
    position: absolute;
    left: calc(100% + #{$font-small});
    top: $font-small / 2;
    background-color: transparent;
    border: none;
    font-size: $font-small;

    & > span:global(.material-icons) {
        padding: {
            top: calc(50% - #{$font-small / 2});
        }
    }
}

.comment-row {
    width: 100%;
    order: 5;
    margin: {
        top: 8px; // should be 16, but there's a global rule interfering
    }
}

.half {
    width: calc(50% - 16px);
    padding: {
        bottom: $gutter-width / 2;
    }
    > span:first-of-type {
        display: none;
    }
}

.full {
    width: 100%;
    padding: {
        bottom: $gutter-width / 2;
    };
}

%separator {
    @extend .full;
    color: #CCCCCC;
}

.separator {
    @extend %separator;
    width: calc(100% + 46px);
    margin: {
        right: -46px;
    }
}

.full-separator {
    @extend %separator;
    width: calc(100% + 64px + 46px); // 2*32px Card + 46px Card Content
    margin: {
        left: -32px;
        right: #{ -32px - 46px };
    }
}

.add-rows-button {
    display: flex;
    background-color: $white;
    border: 1px solid $global-blue;
    border-radius: 4px;
    color: $global-blue;
    padding: 13px 17px;
    margin: {
        right: 14px;
    }
    font: {
        size: $font-small;
    }

    &:disabled {
        background-color: $grey-6;
        border-color: $grey-6;
        color: $soft-black;
        opacity: 1;
    }

    &_icon {
        // @extend .material-icons; // Doesn't work..
        align-self: center;
        font-size: 17px; // VD is 16 but introduces clipping..
    }
}
.rentalReceivedDate {
    width: calc(50% - 16px);
    padding: {
        bottom: $gutter-width / 2;
    }
        > span:first-of-type {
            visibility: hidden;
            margin-top: -32px;
        }
  }
  
.rentalReceivedAmount {
    width: calc(50% - 16px);
    padding: {
        bottom: $gutter-width / 2;
    }
    > span:first-of-type {
        visibility: hidden;
        margin-top: -32px;
    }
  }

  .datePaidPullDown {
    margin-top: -24px;
  }