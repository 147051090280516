.btn {
    font-weight: $weight-bold;
    font-family: $font-family-text;
    font-size: $font-regular;
    letter-spacing: 1.25px;
    line-height: $line-height-regular;
    @include border-radius(4px);
    border: none;
    text-align: center;
    min-width: $button-width-minimum;
    height: 48px;
    padding: 12px 16px;
    white-space: nowrap;

    &__noMinWidth{
        min-width: 0;
    }
    
    &__add {
        color: $global-blue;
        font-weight: normal !important;
        padding-left: 0px;
    }
    &__add i {
        margin-right: 16px;
    }
    &--icon {
        height: auto;
        min-width: auto;
        padding: 0;
    }

    &.btn__add:disabled {
        background-color: transparent;
        color: $grey-80;
    }
}

.btn:focus {
  outline-offset: 2px;
  box-shadow: none;
  outline: 2px solid $tint-40;
}

button.MuiPaginationItem-rounded:focus {
    outline: none;
}

.btn:disabled {
    background-color: $grey-20;
    opacity: 0.9;
    pointer-events: none;
}

.btn-primary {
    color: $white;
    background-color: $global-blue;

    &:disabled {
        background-color: $grey-20;
        color: $soft-black;
    }

    &:hover {
        background-color: $shade-30;
    }
}

.btn-outline {
    font-size: $font-small;
    font-weight: $weight-regular;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    color: $soft-black;
    background-color: $white;
    border: $border-default;
    min-width: fit-content;
    height: 32px;
    padding: 4px 16px 4px 8px;

    i {
        padding-right: 8px;
    }

    &:hover:not(:disabled) {
        background-color: $grey-20;
    }

    &:not(:last-child) {
        margin-right: 16px;
    }
}

.btn-secondary {
    color: $global-blue;
    background-color: $white;
    border: $border-primary;

    &:not(:disabled):not(.disabled) {
        &:active, &:focus {
            color: $global-blue;
            background-color: $white;
            border: $border-primary;
        }
    }
    &:disabled {
        background-color: $white;
        border-color: $grey-70;
        color: $grey-70;
    }

    &:hover {
        color: $global-blue;
        background-color: $grey-3;
    }
}

.card-header__action-buttons {
    text-align : right;
    display: inline-flex;

    & > .dropdown-button {
      margin : 0 8px;

        .btn-icon:disabled {
            background: none;
            color: $grey-20;
        }
  
      &:last-child {
          margin : 4px;
      }
    }
  
    & > button {
        margin : 0 8px;
  
        &:last-child {
            margin : 0 4px;
        }
    }
}

.textAlignRight {
    text-align : right;
}

.SignInButton{

    & .btn.btn-primary {
        background:$global-blue;
        color:$white;
    }
  
    & .btn.btn-primary:hover {
        background: rgba($global-blue, 0.85);
        border: rgba($global-blue, 0.75) 1px solid;
    }
}

.modal__card__close-button,
.toast-notification__close-button { 
    button {
        min-width: auto;
    }
}

.show-on-disable {
    display: none;
}
button[disabled] + .show-on-disable {
    display: inline;
}