@import 'Components/Styles/Core/_variables.scss';
@import 'Components/Core/HbtIcon/hbtIconVariables';

.flyout {
  background-color: $white;
  width: 620px;
  height: 1024px;
}

.menuWrapper {
  background: $white;
  border-radius: $spacing-02;
  border: 1px solid $slate-50W;
}

.menu {
  list-style: none;
  margin: 0;
  padding: $spacing-04 0;

  .menuItem {
    margin: 0;

    .menuOpen {
      background: $grey-70b;
    }

    .menuLabel, .menuLink {
      display: flex;
      padding: $spacing-06 $spacing-08;
      align-items: center;
      justify-content: space-between;
      gap: $spacing-05;
      align-self: stretch;
      text-decoration: none;
      color: black;
      font-weight: $weight-regular;

      &:hover {
        background: $violet-90W;
        font-weight: $weight-bold;
      }

      &:focus {
        outline: 2px solid $violet-20W;
        border-left: 2px solid $violet-20W;
        border-right: 2px solid $violet-20W;
      }

      &:active {
        font-weight: $weight-bold;
        outline: none;
        border: none;
        border-bottom: 1px solid $input-active-keyline;
      }
    }

    .menuLabel .label {
      flex-grow: 1;
    }
    
    .chevronContainer {
      width: $icon-md;
      height: $icon-md;
    }

    .logoutBtnLink {
      cursor: pointer;
    }
  }

  .subMenu {
    list-style: none;
    padding-left: 0;

    .menuItem .menuLabel,
    .menuItem .menuLink {
      padding-left: 64px;
    }

    .subMenu .menuItem .menuLink {
      padding-left: 96px;
    }
  }
}

.label {
  color: $slate-70B;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: $font-regular;
  font-style: normal;
  line-height: $line-height-regular;
}

.boldFont {
  font-weight: $weight-bold;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $spacing-07 $spacing-08;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;

  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing-04;
    width: 100%;
  }

  .titlePadding {
    padding: 0 $spacing-05 0 6px;
  }

  .titleText {
    margin: 0;
  }

  .headerButton button {
    background-color: $white;
    border: none;
    width: 48px;
    height: 48px;

    &:hover {
      color: $violet;
      border: $spacing-01 solid $violet;
      border-radius: $spacing-02;
    }

    &:focus {
      outline: 2px auto $violet-20W;
    }
  }
}

