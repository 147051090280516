@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";
@import "src/Components/Common/Button/styles.module.scss";

.progressbar {
  margin  : 0;
  padding : 0;

  li {
    list-style-type : none;

    &:last-child {
      padding-bottom : 0;

      &::before {
        background : none;
      }
    }
  }
}
.progressBarFooter {
  position: fixed;
  bottom: 0;
  background: $white;
  left: 0px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  @include desktop {
    position: initial;
    background: none;
    border: none;
  }
}

.gridList {
  display: flex;
  margin  : 0;
  padding : 0;
  width: 50%;
  margin: 26px 16px;
  @include desktop {
  display : flex;
  margin  : 0;
  padding : 0;
  width   : 100%;
  }
}

.gridListItem {
  width: 50%;
  margin    : 0 8px;
  @include desktop {
  flex-grow : 1;
  margin    : 0 8px;
  flex-basis: 50%;

  &:last-child {
    margin-right : 0;
  }

  &:first-child {
    margin-left : 0;
  }
}
}

.progressbarItem {
  margin   : 32px 0 40px 0;
  padding  : 0 0 16px 0;
  position : relative;

  button {
    background : none;
    border     : none;
    box-sizing : border-box;
    margin     : 0;
    padding    : 0;

    &:focus {
      box-shadow     : none;
      outline-offset : 3px;
      outline-style  : solid;
      outline-width  : 1px;
    }
  }

  &::before {
    background : #9D9D9D;
    content    : '';
    display    : block;
    height     : 100%;
    left       : 11px;
    position   : absolute;
    top        : 32px;
    width      : 2px;
  }
}

.scrollContainer {
  position : relative;
  height: 100%;
  overflow-y: scroll;
  margin: 0 -24px;
  padding: 0 24px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey-20;
    border-radius: 4px;

    &:hover {
      background: $grey-60;
    }
  }
}

.progressbarItemText {
  color          : $soft-black;
  display        : block;
  font-size      : 14px;
  letter-spacing : 0;
  line-height    : 24px;
  margin-left    : 36px;
  padding-top    : 1px;
  text-align     : left;
}

.currentText {
  font-weight : bold;
}

.progressbarItemStatusIndicator {
  background    : $white;
  border        : #9D9D9D 2px solid;
  border-radius : 50%;
  display       : block;
  height        : 24px;
  position      : absolute;
  width         : 24px;
}

.progressbarItemStatusIndicatorOuterCompleted {
  background   : $global-blue;
  border-color : $global-blue;
}

.progressbarItemStatusIndicatorCurrent {
  background    : $grey-70;
  border-radius : 50%;
  display       : block;
  height        : 16px;
  left          : 50%;
  position      : absolute;
  top           : 50%;
  transform     : translateX(-50%) translateY(-50%);
  width         : 16px;
}

.progressbarItemStatusIndicatorCompleted {
  background : url("./check.svg") center center no-repeat;
  display    : block;
  height     : 12px;
  left       : 50%;
  position   : absolute;
  top        : 50%;
  transform  : translateX(-50%) translateY(-50%);
  width      : 13px;
}

.progressBarSeparator {
  border-top: none;
  padding: 0px;
  @include desktop {
   border-top : $grey-20 1px solid;
   padding: 32px;
  }
}
.tertiaryButton {
  @extend .buttonBase;
  background-color : #FFFFFF;
  border           : 1px solid #005C84;
  border-radius    : 4px;
  color            : #005C84;
  position         : relative;
  transition       : background-color 0.3s ease-in-out;

  &:hover {
    background   : #F8F8F8;
    border-color : #00405C;
    color        : #00405C;
  }

  &:disabled {
    background: $white;
    border-color: $grey-70;
    color: $grey-70;
  }

  &:focus {
    border  : 2px solid #99BECE;

    outline : none;

    &:after {
      border        : #005C84 1px solid;
      border-radius : 4px;
      content       : '';
      display       : block;
      height        : 100%;
      left          : 0;
      position      : absolute;
      top           : 0;
      width         : 100%;
    }
  }
}
