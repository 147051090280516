@import "./variables.scss";

.primarySpinnerFirstRing {
  color: $primary-spinner-first-ring-color !important;
}

.primarySpinnerSecondRing {
  color: $primary-spinner-second-ring-color !important;
}

.secondarySpinnerFirstRing {
  color: $secondary-spinner-first-ring-color !important;
}

.secondarySpinnerSecondRing {
  color: $secondary-spinner-second-ring-color !important;
}

.lg {
  stroke-width: $spinner-stroke-width-large;
}

.md {
  stroke-width: $spinner-stroke-width-medium;
}

.sm {
  stroke-width: $spinner-stroke-width-small;
}
