@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.footerLinks {
  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 0 24px;
    border-right: $soft-black 1px solid;

    &:first-child {
      padding-left: 0;

      & a {
        text-decoration: none;
      }
    }

    &:last-child {
      border: none;
      padding-right: 0;
    }
  }

  a {
    font-weight: normal;
    color: $soft-black;
    font-size: $font-small;
    text-decoration: underline;
  }
}

.footerTextStyling{
  text-decoration: underline;
}