@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.row {
  display: grid;
  grid-template-columns: 200px auto 45px;
  grid-template-areas: "meta content delete";
  gap: 32px;
  padding: 32px;
  border-bottom: $grey-20 1px solid;

  @include desktop {
    grid-template-columns: 300px auto 45px;

  }
}

.meta {
  grid-area: meta;
  margin-left: 40px;

  span {
    display: block;
    font-size: $font-regular;
    font-weight: $weight-bold;
    margin-bottom: 4px;
  }

  time {
    font-size: $font-small;
    color: $soft-black;
  }

  ul {
    margin: 8px 0 0 0;
    padding: 0;
    list-style: none;
  }
  li {
    display: inline-block;
    margin: 4px 0;
    background: $grey-6;
    font-weight: $weight-medium;
    padding: 3px 20px;
    border-radius: 17px;
    font-size: $font-small;
  }

}

.content {
  grid-area: content;
  line-height: 24px;
  margin-bottom: 8px;

  h3 {
    font-size: $font-regular;
    font-weight: $weight-bold;
  }

  p {
    font-size: $font-small;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin: 8px 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.delete {
  grid-area: delete;
  justify-self: end;

  button {
    background: none;
    border: none;
  }

  img {
    width: 24px;
    height: 24px;
  }
}