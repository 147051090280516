$collapsingSectionMargin: 32px;
$collapsingSectionPaddingRight: 46px;

.heading-button {
  display: flex;
  justify-content: space-between;

  width: 100%;

  background: none;
  outline: inherit;
  color: inherit;
  font: inherit;
  border: none;
  padding: 0;
}

.collapsing-section {
  margin: {
    top: $collapsingSectionMargin;
  }
  padding: {
    right: $collapsingSectionPaddingRight;
  }
}
