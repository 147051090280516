@import "../../Styles/Core/variables.scss";
@import "../../Styles/Core/mixins.scss";

.breadcrumb {
  @include flex(center, flex-wrap);
  font-size: $font-small;
  font-weight: $weight-medium;
  height: 60px;

  &.condensed {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.breadcrumbItem {
  @include flex(center);
  padding: $spacing-04 0;
  min-width: $spacing-10;

  .currentPage {
    color: $slate-70B;
    pointer-events: none;

    &:focus {
      outline: none;
    }
  }

  &:not(:first-child)::before {
    content: "";
    margin: 0 $spacing-03;
    width: $spacing-05;
    height: $spacing-05;
    background: url('../HbtIcon/icons/ChevronRight.svg') no-repeat center center;
    background-size: contain;
  }

  .breadcrumbLink {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 1;
    transition: color 0.3s;
    font-family: $font-family-text;
    padding: $link-border-padding; 
    gap: $link-gap;
    white-space: normal;
    color: $link-default-focused-color !important;
    text-decoration: underline;
    text-decoration-color: $link-default-focused-color !important;

    &:hover {
      color: $link-hover-active-color !important;
      text-decoration: none !important;
    }
  
    &:focus {
      color: $link-default-focused-color !important;
      text-decoration-color: $link-default-focused-color !important;
      outline: $link-focused-border;
      border-radius: $link-border-radius;
    }
  
    &:active {
      color: $link-hover-active-color !important;
      text-decoration: none !important;
      outline: $link-active-border;
      border-radius: $link-border-radius;
    }
  }
}

.currentPageLabel {
  &:focus {
    @include focus-outline($violet-20W, $border-radius);
  }
}

.overflowEllipses {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: 0 -8px;

  &::after {
    content: "";
    margin: 0;
    display: block;
    width: 16px;
    height: 16px;
    background: url('../HbtIcon/icons/Ellipses.svg') no-repeat center center;
    background-size: contain;
  }

  &:hover {
    @include focus-outline($violet, $spacing-02)
  }

  &:active {
    @include focus-outline($violet, $spacing-02);
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.25);
  }

  &:focus:not(.active) {
    @include focus-outline($navy, $spacing-02);
    &::before, &::after {
      content: '';
      position: absolute;
      pointer-events: none;
    }

    &::before {
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      border: 2px solid $violet;
      border-radius: 10px;
    }
  }

  &:hover  {
    .tooltip, .dropdown {
      display: block;
    }    
  }  
}

.dropdownMenu {
  @include flex(start, flex-start);
  padding: $spacing-02 0;
  outline: 2px solid $global-grey;
  border-radius: $border-radius;
  background: #FFF;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
  width: 200px;

  .menuList {
    margin: 0;
    padding: 0;
  }

  .menuItem {
    @include flex(start, flex-start);
    gap: $spacing-05;
    @include font-settings($font-small, $weight-regular, $spacing-06);
    padding: $spacing-04 $spacing-05;

    &:hover {
      background: $violet-90W;
    }
  }
}