@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.piExternalUsersTabInventory {
  width: 100%;
  padding: $spacing-08 $spacing-08 0 $spacing-08;

  .externalPIUserdataTable {
    --PITableScrollContainershiftButtonColor: #{$navy};
    --PITableScrollContainershiftButtonFocusColor: #{$violet-20W};

    width: 100%;

    table {
      width: 100%;

      thead > tr {
        th {
          button {
            &:focus {
              outline: $spacing-01 solid $violet-20W;
            }
          }
          padding-left: $spacing-08;
        }
      }
  
      tbody > tr:nth-child(n) {
        &:hover {
          cursor: pointer;
          background-color: $violet-90W;
        }

        &:focus {
          outline: $spacing-01 solid $violet-20W;
        }

        td{
          padding-left: $spacing-08;
        }
      }

      tbody > tr:nth-child(even) {
        background: $slate-95W;
      }
    }
  }

  .dataTableFooter {
    margin: 0;
    padding: 0;

    > div {
      padding: 0;

      @media screen and (max-width: 991px) {
        flex-wrap: wrap;
        gap: $spacing-05;
        align-items: baseline;
      }
    }

    nav, ul, li {
      button[aria-current="true"] {
        background: $navy;
        color: $white;
        border-color: $navy;
    
        &:hover {
          background: lighten($violet, 5);
          border-color: lighten($violet, 5);
        }
      }
    }
  }

  .noSearchResultsMessage {
    display: block;
    padding: $spacing-08;
    text-align: center;
    font-weight: bold;
  }

  .searchBarWrapper { 
    @include flex(center, space-between);
    padding-bottom: $spacing-08;

    @include tablet {
      width: 100%;
      margin-bottom: 10px;
      padding: $spacing-05;
    }
  
    @include desktop {
      flex-grow: 12;
    }
  }

  .searchBarWidth {
    width: 75%;

    input, select {
      &:focus {
        border: 1px solid $violet-20W;
      }
    }
  }
}