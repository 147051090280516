@import "src/Components/Styles/variables";
@import "src/Components/Styles/base";
@import "src/Components/Styles/adaptive";

.loanInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 70%;

  & > * {
    flex-basis: calc(50% - 16px);
    margin: {
      bottom: 16px;
    };
  }
};

.buttonTray {
  display: flex;
  flex-basis: unset;
  width: 100%;

  margin: {
    top: 16px;
    bottom: 0;
  };

  & > * {
    margin: {
      right: 13px;
    }
  }
};
