@import "Components/Styles/Core/adaptive.scss";
@import "Components/Styles/Core/variables.scss";

.dashboardLayoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin: 0;
  padding: 0;

  & > div {
    margin-bottom: 0;
  }

  & > div:first-child {
    background: $white;
    box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25);
  }

  @include for-laptop-and-up {
    grid-template-areas: "sidebar content";
    grid-template-columns: 360px calc(100vw - 360px);

    & > div:first-child {
      grid-area: sidebar;
    }

    & > div:last-child {
      grid-area: content;
    }
  }

  @include for-tablet {
    grid-template-areas: "sidebar content";
    grid-template-columns: 68px calc(100vw - 68px);

    & > div:first-child {
      grid-area: sidebar;
    }

    & > div:last-child {
      grid-area: content;
    }
  }
}