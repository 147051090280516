@import "../../../../../Components/Styles/Core/variables";
@import "../../../../../Components/Styles/Core/mixins.scss";

.hbtGenericErrorPageMain {
  @include flex(center, center);
  min-height: calc(100vh - 218px);

  .header {
    @include font-settings($font-xxxlarge, $weight-medium, $line-height-xlarge, $slate-70B);
  }

  .content {
    a {
      @include font-settings($font-regular, $weight-medium, $line-height-regular, $digital-blue);
      text-decoration-line: underline;
      border-radius: $spacing-02;
  
      &:hover {
        color: $violet !important;
        text-decoration: none !important;
      }
      
      &:focus {
        color: $digital-blue !important;
        text-decoration-color: $digital-blue !important;
        outline: $spacing-01 solid $violet-20W;
        border-radius: $spacing-02;
      }
      
      &:active {
        color: $violet !important;
        text-decoration: none !important;
        outline: $spacing-01 solid $slate-50W;
        border-radius: $spacing-02;
      }
    }
  }
  
  .image {
    img {
      width: $spacing-13;
      height: $spacing-13;
      margin-right: $spacing-07;
    }
  }

  .LogOutButton {
    padding-top: $spacing-10;
  }
}