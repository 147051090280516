@import "src/Components/Styles/variables";

.actionMenus {

  position: relative;

  button {
    background: none;
    border: $global-blue 1px solid;
    margin: 0;
    line-height: 1;
    border-radius : 5px;
    align-items   : center;
    align-content : center;
    padding       : 12px 12px;
  }

  & > button {
    padding       : 12px 12px;
  }
}

.dropdownButtonBase {
  background-color : $white;
  border           : 1px solid $global-blue;
  border-radius    : 5px;
  align-items   : center;
  align-content : center;
  padding       : 12px 12px;
}

.dropdownButton {
  @extend .dropdownButtonBase;
  position: relative;
  height: 100%;
  width: 48px;
}

.subNav{
  border: $global-blue 1px solid;
  border-radius: 4px;
  position: absolute;
  top: 30px;
  right: 4px;
  background: $white;
  box-shadow: 0 4px 8px $grey-20;
  padding: 0;
  min-width: 188px;
  transform: translate3d(5px, 35px, 0px);
  z-index: 1;


  button {
    padding: 16px 24px;
    position : relative;
    width: 100%;
    border: 0;
    border-bottom: $grey-6 1px solid;
    text-align: left;

    &:hover:not([disabled]) {
      background: $tint-5;
    }
  }
}

