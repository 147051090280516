@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";
.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;
  grid-auto-flow        : dense;

  & > div { margin-bottom : 32px; }

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "sidebar content";
    grid-template-columns : 8fr 4fr;

    & > div:first-child { grid-area : sidebar; }
    & > div:last-child { grid-area : content; }
  }
}

.showForDesktopOnly {
    display: none;

    @include desktop {
        display: block;
    }
}

.hideForDesktop {
    display: block;

    @include desktop {
        display: none;
    }
}

.stickyFooter {
    border-top: $grey-20 1px solid;
    padding: 24px 0;

    button {
        margin-left: 16px;
        min-width: 178px;
    }
}

.defaultFormContents {
    padding-top: 24px;
}

.formProgressSticky {
    position: -webkit-sticky;
    position: sticky;
    top: 1rem !important;
}


/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .formProgress {
            &Sticky.IEsticky {
                width: 423px;
                top: 0;

                .form-progress__scrollableContent {
                    height: 77vh;
                    min-height: 77vh;
                }
            }

            &Sticky.IEstuck {
                position: fixed !important;

            .sticky-top {
                top: 0;
            }

            .form-progress__scrollableContent {
                height: 62vh;
                min-height: 62vh;
            }
        }
    }
}