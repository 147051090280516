@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.sideDrawerContainerEnterActive {
  & > div {
    animation-name:backgroundAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }
  & > aside {
    animation-name:asideAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }
}

.sideDrawerContainerExitActive {
  & > div {animation-name:backgroundAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: forwards;
  }

  & > aside {animation-name:asideAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    animation-fill-mode: forwards;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  background: rgba(0,0,0,0.5);
  z-index: 4;
}

.sideDrawer {
  display: block;
  position : fixed;
  width: 624px;
  height: 100vh;
  top: 0;
  right: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  background: $white;
  z-index: 100;
  box-sizing: border-box;

  @media only screen and (max-width: $tablet-width) and (orientation : portrait) {
     position: absolute;
     left:97%;
    }
}

@keyframes backgroundAnimation {
  from {
    background: rgba(0,0,0,0);
  }
  to {
    background: rgba(0,0,0,0.5);
  }
}

@keyframes asideAnimation {
  from {
    right: -624px;
  }
  to {
    right: 0;
  }
}