@import "../../../../../../Components/Styles/variables";


.suspenseSections {
  border: 1px solid $grey-20;
  border-radius: 5px;
  padding: 32px;
  margin-bottom: 32px;
}

.heading { margin-bottom: 32px; }
.codesCheckBoxListWrapper {
  display        : flex;
  flex-direction : column;
  flex-wrap      : wrap;
  margin         : 0;
  padding        : 0;
  height: 209px;

  > div {
    margin          : 0px 16px 16px 0px;
    div {
      label {
        & > span {
          max-width: inherit;
        }
      }
    }
  }
}