@import "../../Styles/Core/variables.scss";
@import "../../Styles/Core/mixins";

.appBar {
  color: $slate-70B !important;
  background: $white !important;
  padding: $spacing-07 $spacing-11;
  border-bottom: 1px solid $slate-50W;
  box-shadow: none !important;
}

.transparentBar {
  background: transparent !important;
  border-bottom: none !important;
}

.tabletBar {
  padding: $spacing-07 $spacing-08 !important;
}

.toolbar {
  @include flex-center;
  justify-content: space-between;
  padding: 0;
}

.rightContainerOnly {
  justify-content: end;
}

.leftContainer {
  @include flex-center;

  .logoContainer {
    @include flex-center;
    width: 110px;
    height: 54px;
    padding: 0 2.485px $spacing-03 3px;
  }
}

.textWrapper {
  padding-left: $spacing-07;
}

.headerText {
  @include font-settings($font-xlarge, $weight-medium, $spacing-08);
  flex-grow: 1;
}

.rightContainer {
  @include flex-center;
  gap: $spacing-07;
}

.languageContainer {
  @include flex-center;
}

.eyebrowHeaderText {
  @include font-settings($font-small, $weight-bold, $spacing-06);
}

.link {
  @include flex-center;
  justify-content: space-between;
  text-decoration: none;
  padding: $spacing-04;
  cursor: pointer;

  p {
    @include font-settings($font-regular, $weight-medium, $spacing-07);
    padding-left: $spacing-03;
  }

  &:hover {
    p {
      color: $link-hover-active-color;
    }
    text-decoration-line: underline !important;
  }

  &:active {
    p {
      color: $link-hover-active-color !important;
    }
    text-decoration-line: underline;
    outline: $spacing-01 solid $slate-50W;
    border-radius: $border-radius;
  }

  &:focus {
    outline: $spacing-01 solid $link-focused-outline-color;
    border-radius: $spacing-02;
  }
}

.tabletLink {
  padding: $spacing-02 !important;
}

.dropdownMenu {
  border-radius: $spacing-02;
  justify-content: start;
  outline: $spacing-01 solid $global-grey;
  background: $white;
  box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
  width: 200px;

  .menuList {
    margin: 0;
    padding: 0;
  }

  .menuHeaderWrapper {
    @include flex-start;
    padding: $spacing-04 $spacing-05;
    gap: $spacing-05;
  }

  .menuHeader {
    @include font-settings($font-small, $weight-bold, $spacing-06, $text-links-text-header);
  }

  .menuItem {
    @include flex-start;
    gap: $spacing-05;
    @include font-settings($font-small, $weight-regular, $spacing-06);
    padding: $spacing-04 $spacing-05;

    &:hover {
      background: $violet-90W;
    }
  }
}

.hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}
