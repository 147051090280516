.deleteCell {
  width: 24px;
  padding: 0 8px;
  text-align: center;
}

.deleteButton {
  border: none;
  background: none;
  transition: all 0.3s ease;

  img {
    display: block;
    height: 24px;
    width: 24px;
  }

  &:hover {
    filter: invert(22%) sepia(100%) saturate(2253%) hue-rotate(351deg) brightness(90%) contrast(93%);
  }
}

.iconContainer {
  display: block;
  height: 24px;
  width: 24px;

}