@import "../../../../../../Components/Styles/variables";
td { border: 0 }

.suspenseSections {
  border: 1px solid $grey-20;
  border-radius: 5px;
  padding: 32px;
  margin-bottom: 32px;
}

.heading { margin-bottom: 32px; }

.suspenseTableHeading{ margin-bottom: 18px; }

.suspenseTable {
  border: 1px solid $grey-20;
  border-radius: 5px;

  table { width: 100%;

    tr:not(:last-child) {
      border-bottom: 1px solid $grey-20;
    }
    tr:last-child td {
      border-bottom: 0;
    }
    
  }
}
.comment { color: $global-blue; }

.marginBottom35 { margin-bottom: 35px; }
.buttonHolder { border-bottom: 1px solid $grey-20; }
.buttonHolder button { padding: 6px 20px; font-size: $font-small; }
.reasonTitle {
  font-size: $font-small;
  padding-top: 3px;
  padding-bottom: 10px;
}
.overrideButton {
  margin-right: 16px;
}
.noSearchResultsMessage {
  display: block;
  padding: 32px;
  text-align: center;
  font-weight: bold;
}