@import "src/Components/Styles/variables";

.commentPopoutContainer {
  text-align: left;
  background: $white;
  border: $global-blue 1px solid;
  border-radius: 4px;
  width: 406px;
  box-shadow: 0px 3px 5px rgba(0,0,0,0.15);
}

.header {
  padding: 16px 10px 16px 17px;
  font-weight: bold;
  font-size: 16px;
  border-bottom: $grey-20 1px solid;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    border: none;
    line-height: 0;
  }
}

.content {
  display: block;
  white-space: normal;
  padding: 16px;
}