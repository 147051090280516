@import "src/Components/Styles/variables";

.thead {

}

.th {
  border-bottom: $grey-20 solid 1px;
  border-top: $grey-20 solid 1px;
  white-space: nowrap;
  padding: 20px;


  & button {
    background: none;
    border: none;
    padding: 0 28px 0 0;
    color: $grey-60;
    font-size: 14px;
    font-weight: bold;
    position : relative;
  }
}

.thText {
  color: $grey-80;
  font-size: 14px;
  font-weight: bold;
  display: block;
  position : relative;
}

.sortDirectionWrapper {
  position: absolute;
  right: 0;
}

.sortDirectionDefaultWrapper {
  span {
    position : absolute;
    right: 0;
    color: $grey-80;

    &:first-child {

      top: -3px;
    }
    &:last-child {

      top: 3px
    }
  }
}