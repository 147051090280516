@import "src/Components/Styles/Core/variables";
@import "src/Components/Styles/Core/adaptive";
@import "./linkVariables.scss";
@import "./mixins.scss";


.link {
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: color 0.3s;
  font-family: $link-font-family;
  padding: $border-padding; 
  gap: $gap;
  white-space: normal;
  color: $default-color !important;
  text-decoration: underline;
  text-decoration-color: $default-color !important; 

  &::before {
    content: '';
    position: absolute;
    top: -4px;    /* Expand the top area */
    bottom: -4px; /* Expand the bottom area */
    left: -0px;   /* Expand the left area, if needed */
    right: -0px;  /* Expand the right area, if needed */
    z-index: 40;
    // border: 2px solid #000; //Checking tap target
  }


  & + .link {
    margin-top: $spacing-links; 
  }

  &.normal {
    font-size: $font-size-body;
    line-height: $body-line-height;
    svg {
      display: flex;
      align-items: center;
      height: $icon-size-body !important;
      width: $icon-size-body !important;
    }
  }

  &.small {
    font-size: $font-size-body-small;
    line-height: $body-small-line-height;
    svg {
      display: flex;
      align-items: center;
      height: $icon-size-body-small !important;
      width: $icon-size-body-small !important;
    }
  }

  &:hover {
    color: $hover-color !important;
    text-decoration: none !important;
  }

  &:focus {
    color: $focused-color !important;
    text-decoration-color: $focused-color !important;
    outline: $focused-border;
    border-radius: $border-radius;
    position: relative;
    z-index: 1;
    display: inline-flex;
  }

  &:active {
    color: $active-color !important;
    text-decoration: none !important;
    outline: $active-border;
    border-radius: $border-radius;
    position: relative;
    z-index: 1;
    display: inline-flex;
  }

  .withLeadingIcon {
    display: flex;
    align-items: center;
    margin-right: $gap;
  }

  .withTrailingIcon {
    display: flex;
    align-items: center;
    margin-left: $gap;
  }

}
