@import "../../../../../Components/Styles/variables";

.assessmentCalculationsWrapper {
  margin: 24px;

  h3:not([class]) {
    margin-left: 0;
  }
}

.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
}

.title {
  display: inline-block;
}

.collapseToggle {
  margin: -6px 0 0 0;
  display: inline-block;
}

.addCommentButton {
  margin-top: -6px;
  display: flex;

  & > button {
    margin-left: 16px;
  }
}

.editableValueTables {
  & > div {
    margin-bottom: 32px;
  }
}


.formWrapper {
  margin-bottom: 105px;
}

.tableStandardInput {
  max-width: 153px;
  margin: 0;

  & span:first-of-type {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }



  input[type='text'] {
    border: $grey-20 1px solid;
    border-radius: 4px;
    font-size: 14px;
    padding: 14px 16px 12px 16px;

    &:disabled, &:read-only {
      background: none;
      border: none;
      padding: 15px 17px 13px 17px;
    }

    &:read-only:focus-visible {
      outline: none;
    }
  }

  &.trendable {
    input[type='text'] {
      padding-left: 24px;

      &:disabled, &:read-only {
        padding-left: 25px;
      }

      &:read-only:focus-visible {
        outline: none;
      }
    }
  }
}

.decisioningButtonsFooter {
  padding: 32px 32px 40px 32px;
  margin-left: -24px;
  margin-right: -24px;
  border-top: $grey-20 1px solid;

  & button {
    margin: 0 8px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.decisioningButtonsFooterScaffold {
  display: flex;
  width: 415px;
}

.assessmentFooterWrapper {
  margin-top: 80px;
}

/* To handle date picker - using selector with specific hierarchy to max specificity */
.hideDateIconFieldset {
  &:disabled, &:read-only {
    tbody tr td > label > div > div:after,
    tbody tr td > div > label > div > div:after {
      display: none;
    }
  }
}

