@import 'Components/Styles/_variables.scss';
@import 'Components/Common/CollapsingSection/styles.module.scss';
@import "src/Components/Styles/adaptive";

// TODO: If we're not removing Bootstrap, this can be imported
%sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

%spacefill-sr-only {
  @extend %sr-only;
  position: unset;
}

.refundCalculationCard {
    position: relative;
    display: flex;
    flex-direction: column;

    min-width: 0;

    word-wrap: break-word;

    font-size: $font-small;

    background: {
      color: $white;
      clip: border-box;
    };

    border: 1px solid $grey-6;
    border-radius: 4px;

    margin: {
      bottom: 24px;
    };
    padding-top: 32px;

    h3{
      padding-left: 32px;
      padding-bottom: 32px;
      > button:first-of-type {
        padding-right: 32px;
      }
    }

    > h3 + div {
      padding: 0px;
    }
}

.refundEdit {
  margin-left: 32px;
  margin-right: 32px;
  padding-bottom: 32px;
  display: flex;
  float: right;  

  :first-of-type{
    margin-right: 16px;
  }
}

.addReceivedAdjustment{
  margin: 0 32px 32px;
}

.addIcon {
  margin: {
    left: -8px;
    right: 8px;
  }
}

.actionIcon {
  padding: 4px;
  border: none;
}

.row {
  display: flex;
  width: calc(100% + #{$collapsingSectionMargin*2 + $collapsingSectionPaddingRight});
  margin: {
    left: -$collapsingSectionMargin;
    right: -$collapsingSectionMargin;
  }
  padding: 32px;
  border-bottom: 1px solid $grey-6;

  &:not(:last-child):nth-child(2n+1) {
    background-color: $grey-3;
  }

  &:last-child {
    background-color: $tint-5;
    font: {
      weight: bold;
    }
    margin: {
      bottom: -$collapsingSectionMargin;
    }
  }
}

.adjustmentTitles {
  @extend .row;
  display: flex;
  margin: {
    top: 32px;
  }

  border-top: 1px solid $grey-6;
}

.logCol {
  flex-basis: #{ (1 / 3) * 100% };
}

.logTitle {
  @extend %spacefill-sr-only;
  @extend .logCol;
}

.entryCol {
  padding: 8px;
}

.entryColDate {
  padding: 8px;
  width:max-content;
}

.dataTableHeader{
  display: block;
  div {
    overflow: inherit;
  }
  table{
    width: 100%;
    table-layout: fixed;
    th {
      vertical-align: text-top;
      &:first-of-type{
        width: 40%;
        @include desktop{
          width: 35%;
        }
      }
      &:nth-of-type(2),&:nth-of-type(3)
      {
        width: 20%;
        @include desktop {
          width: 12%;
        }
      }
      &:nth-of-type(4){
        width: 0%;
        @include desktop{
          width: 27%;
        }
      }
      &:nth-of-type(5),&:last-of-type{
        width: 8%;
      }
    }
    td:nth-of-type(2),:nth-of-type(3) {
      vertical-align: baseline;
    }
    
    input[readonly] {
      background-color: $white;
      border: 1px solid rgba(0,0,0,0);
      opacity: 1;
    }
  }
}

.editCol {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
}

.editTitle {
  @extend %spacefill-sr-only;
  @extend .editCol;
}

.deleteCol {
  flex-basis: 0;
  margin: {
    left: 32px;
  }
}

.deleteTitle {
  @extend %spacefill-sr-only;
  @extend .deleteCol;
}
