@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.card {
  @extend .cardBase;
  display        : flex;
  flex-direction : column;
  margin-bottom  : 32px;
}

.expandHeight {
  border: none;
  @include desktop {
  height : 100%;
  border: $grey-20 1px solid;
  }
}

.cardHeader {
  margin-bottom : -16px;
  padding       : 32px 32px 16px 32px;

  h1, h2, h3, h4, h5 {
    margin-bottom : 0;
  }

}

.cardBody { flex-grow : 1; padding : 16px 32px; }

.cardFooter { 
    padding : 32px; 
}

.cardHeaderSeparator {
  border-bottom  : $grey-20 1px solid;
  margin-bottom  : 0;
  padding-bottom : 32px;
}

.cardFooterSeparator { border-top : $grey-20 1px solid; }

.collapsibleCard {
  @extend .cardBase;
}

.collapsibleCardHeader {
  @extend .cardHeader;
  display  : block;
  margin   : 0;
  padding  : 24px;
  position : relative;

}

.collapsibleCardButton {
  background  : none;
  border      : 0;
  line-height : 0;
  padding     : 8px;
  position    : absolute;
  right       : 24px;
  top         : 18px;
}