@import "../../Styles/Core/variables.scss";
@import "../../Styles/Core/mixins.scss";
@import "./dataTableVariables";

.dataTableMainContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: $spacing-03;
}

.dataTableContainer {
  margin: $spacing-08 0 $spacing-12 0;
  display: flex;
  flex-direction: column;
  width: 73%;
}

.tableContainer {
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  margin: $spacing-03 0;
}

.dataTableSearchBar {
  margin-right: $spacing-04;
}

.dataTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;

  th, td {
    padding: $spacing-04 $spacing-07;
    border-bottom: 1px solid $slate-80W;
    text-align: left;
    overflow: inherit;
    white-space: nowrap;
    outline: none;

    &:focus-visible {
      outline: 2px solid $violet-20W;      
    }
  }

  th {
    border-top: 1px solid $slate-50W;
    border-bottom: 2px solid $slate-50W;
    border-left: none;
    border-right: none;
    cursor: pointer;
    background-color: $white;
    position: relative;
    min-width: $column-min-width;
    max-width: $column-max-width;
    min-height: $cell-min-height;
    max-height: $cell-max-height;

    &:hover {
      color: $violet;
      border-bottom-color: $violet;
    }

    &:focus-visible {
      z-index: 2;
      outline: $spacing-01 solid $violet-20W;
      outline-offset: -1px;

      &::after {
        visibility: hidden;
      }
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 12px;
      bottom: 12px;
      right: 0;
      width: 1px;
      background-color: $slate-80W;
    }
  }
  
  .caption {
    display: none;
  }

  .sticky {
    position: sticky;
    left: -1px;
    z-index: 2;
    max-width: 150px;
  }

  .greyCellBackground {
    background-color: $slate-95W;
  }

  .whiteCellBackground {
    background-color: $white;
  }

  tr {
    overflow: visible;    

    &:nth-child(even) {
      background-color: $slate-95W;
    }

    &:nth-child(odd) {
      background-color: $white;
    }

    td {
      border-left: none;
      border-right: none;
    }
    
    &.enableHover {
      &:hover {
        cursor: pointer;
        background-color: $violet-90W;

        .sticky {
          background-color: $violet-90W;
        }
      }
  
      td {
        &:hover {
        cursor: pointer;
          background-color: $violet-90W;
  
          .sticky {
              background-color: $violet-90W;
          }
        }
      }
    }
  }

  th.sticky {
    z-index: 3;
  }

  .checkboxWidth {
    width: 30px !important;
  }

  .checkboxCell {
    @include flex-center;
  }

  .alignStart {
    @include flex-start;
  }

  .alignCenter {
    @include flex-center;
    gap: $spacing-02;
  }

  .alignEnd {
    @include flex($justify: end);
    gap: $spacing-02;
  }

  .thContent {
    display: flex;
    align-items: center;
    vertical-align: middle;
    gap: $spacing-02;
  }

  .resizeHandle {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    cursor: col-resize;
    z-index: 1;

    &::before {
      @include cursor-area(col-resize, $spacing-10, $spacing-10);
    }
  }

  .resizing {
    border-right: $spacing-01 solid $slate;
  }
}

.dataTableSubmitButtonContainer {
  margin-top: $spacing-10;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: $spacing-07;
}

.noDataMessage {
  text-align: center;
  padding: $spacing-04 $spacing-07;
  color: $global-grey;
  font-size: $font-regular;
}

@keyframes breathing {
  0% {
    background-color: $slate-80W;
  }
  50% {
    background-color: $slate-95W;
  }
  100% {
    background-color: $slate-80W;
  }
}

.loadingBar {
  height: $spacing-07;
  padding: 0;
  background-color: $slate-80W;
  animation: breathing 2.5s infinite;
}

.alertBox {
  position: relative;
  width: calc(100% - 300px);
  background-color: transparent !important;
  color: $red !important;
}
