@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/adaptive";

.card {
  > section:first-of-type {
    @extend .cardBase;
  }
}


.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;
  grid-auto-flow        : dense;
	grid-template-areas   : 
		"title"
		"buttons";
  & > div { margin-bottom : 24px; }

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "title buttons";
    grid-template-columns : 1fr 1fr;
  }
}

.buttonsContainer {
  grid-area : buttons; 
  text-align: left;
  padding: 0 0 0 32px;
  button {
    padding: 12px 32px;
    margin-right: 16px;
  }

  @include desktop {
    padding: 24px;
    text-align: right;
  }
}

.titleContainer {
  grid-area : title; 
  padding: 32px 0 0 32px;
}

.dataTable > table {
  width: 100%;
  thead > tr {
    th {
      padding: 20px 32px;
    }

    > th:first-of-type {
      width: 16.5%;
    }

    > th:nth-child(2) {
      width: 13%;
    }

    > th:nth-child(3) {
      width: 13.5%;
    }

    > th:nth-child(4) {
      width: 12%;
    }

    > th:last-of-type {
      width: 45%;
    }
  }

  tbody > tr {
    td {
      padding: 10px 32px !important;
    }

    > td:first-of-type {
      width: 16.5%;
    }

    > td:nth-child(2) {
      width: 13%;
    }

    > td:nth-child(3) {
      width: 13.5%;
    }

    > td:nth-child(4) {
      width: 12%;
    }

    > td:last-of-type {
      width: 45%;

      div > div {
        right: 95%;
      }
    }
  }

  tbody > tr:nth-child(even) td {
    background: $grey-3;
  }
}

.dataTableFooter {
  padding: 24px 0px 0px 0px;
}

.tableClass {
  width: 100%;
}