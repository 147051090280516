@import 'src/Components/Styles/_variables';
@import "src/Components/Styles/adaptive";

.dataTable {
  display: block;

  table {
    width: 100%;
    table-layout: auto;

    th {
      width: 16.5%;
      white-space: nowrap;

      text-align: left;
      vertical-align: top;

      &:first-of-type {
        padding-left: 32px;
      }

      &:nth-of-type(3) {
        width: 20%;
      }
    }

    tbody tr td {
      &:first-of-type {
        padding-left: 32px;
      }

      &:nth-of-type(3) {
        width: 20%;
      }
    }
  }

  &Footer {
    white-space: nowrap;
    border-top: 1px solid #CCC;
    padding: 24px 0px 0px 0px;

    div:first-of-type > nav > ul {
      flex-wrap: nowrap;
    }
  }
}

.cardHeader {
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.showForDesktopOnly {
  display: none;

  @include desktop {
    display: block;
  }
}

.hideForDesktop {
  display: block;

  @include desktop {
    display: none;
  }
}

.searchBarWrapper {
  border-top: $border-default;
  flex-grow: 12;
  padding: 13px 33px;
}
