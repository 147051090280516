@import "../../base/variables";
@import "src/Components/Styles/adaptive";

/*CLAIM SUBMISSION FORM*/
.claim-submission-icon {
  width: 24px;
  height: 24px;
  vertical-align: text-top;
  margin-right: 18px;
  margin-top: 2px;
}

.form-heading {
  margin-bottom: 35px;
}

.form-steps {
  font-size: $font-regular;
  color: $grey-70;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-weight: $weight-medium;
}

.form-section-title {
  margin-bottom: 25px;
}
.form-divider {
  margin: 56px 0;
  border-top: 1px solid $grey-20;
}

/*Claims Payment Center*/
.claims-payment-center {
  background-color: $white;
  padding: 32px;
  box-sizing: border-box;
  border: $border-default;
  border-radius: 4px;
  overflow: hidden;
  .content-one,
  .content-two {
    margin: 5px 0;
    font-size: $font-small;
  }
  .claims-label {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: $font-small;
  }
  .claims-timings p,a {
    font-size: $font-small;
  }
}
.showPara {
  display: block;
}
.hidePara {
  display: none;
}
.expanded {
  height: 560px;
  @include desktop {
  height: 860px;
  }
}

.claims-payment-content {
  margin-top: 20px;

  p {
    padding-right: 16px;
  }
}
.telephoneUnderline {
  text-decoration: underline;
}
.claims-contact-details {
  margin-top: 35px;
  div p {
    font-size: $font-small;
  }
}

.claim-payment-center-btn {
  background: none;
  border: 0;
  color: $global-blue;
  font-weight: bold;
  padding: 0;
  text-decoration: underline;
  margin-top: 10px;
}

.lender-authorized-text-color {
  color: $grey-80;
}

.claims-page-heading-container {
  margin: 0px 0px 32px 0px;
}

.claims-page-heading {
  color: $soft-black;
  font-family: $font-family-text;
  font-size: $font-xxlarge;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}
.claim-steps-divider {
  margin: 56px 0px;
}

.claim-submission-card {
  &-top {
    padding: 32px 32px 0px 32px;
  }
  &-bottom {
    padding: 32px;
  }
  .card-heading {
    margin-bottom: 32px;
  }
  .claim-card-divider {
    margin: 0px;
  }
}

.lender-authorized-text-color {
  color: $grey-80;
}
.btn-add-color {
  color: $global-blue;
  letter-spacing: 0;
  font-weight: 500;
  padding: 0;
  background-color: transparent;
  border: none;

  .material-icons {
    margin-right: 15px;
  }

  &:disabled,
  &[disabled] {
    color: $grey-70;
    background-color: transparent;
  }
}
.btn-disabled-color {
  color: $grey-70;
  letter-spacing: 0;
  font-weight: 500;
  padding: 0;
  cursor: default;
  background-color: transparent;
  border: none;
  .material-icons {
    margin-right: 15px;
  }
  :hover {
    color: $grey-70;
    cursor: default;
  }
}
.horizontal-line-full-width {
  color: $grey-20;
  margin-left: -32px;
  color: $grey-20;
  margin-left: -32px; //TODO: once commn component is available will make hr full width
  margin-right: -103px;
}

.claims-progress-bar-body {
  height: 600px;
  overflow-y: auto;
  display: none;
  @include desktop {
    display: block;
  }
}
