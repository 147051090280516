@import 'Components/Styles/Core/_variables.scss';
@import 'Components/Core/HbtIcon/hbtIconVariables';
@import "../../Styles/Core/mixins.scss";
@import "src/Components/Styles/adaptive";

.uploadBox{
  border: $spacing-02 dashed $slate-50W;
  @include flex-center;
  flex-direction: column;
  height: 200px;
  border-radius: $border-radius;
  background-color: $slate-95W;
}

.uploadBoxError{
  @extend .uploadBox;
  border: $spacing-02 dashed $red;
}

.uploadBox:hover {
  cursor: pointer;
  border-color: $violet;
}

.uploadBox:focus {
  @include focus-outline($violet-20W, $border-radius-lg);
  outline-offset: $spacing-02;
}

.imgContainer {
  @include flex-center();
  background-color: $white;
  border-radius: 50%;
  height: $spacing-11;
  width: $spacing-11;

  svg {
    height: unset !important;
    width: unset !important;
  }
}

.uploadIcon{
  height: unset !important;
  width: unset !important;
}

.uploadWindow {
  margin-bottom: $spacing-07;
}

.uploadTitleWrapper {
  padding-bottom: $spacing-05;

  .uploadTitle {
    @include font-settings($font-large, $weight-medium, 150%, $slate-70B);
    padding-bottom: $spacing-02;
  }
  
  .uploadDescription {
    @include font-settings($font-small, $weight-regular, $line-height-small);
    font-family: $font-family-text;
  }
}

.uploadDetails {
  @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-70B);
}

.supportedFileFormatText {
  @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-30B);
}

.browser {
  @include font-settings($font-regular,$weight-medium, $line-height-regular, $digital-blue);
  text-decoration-line: underline;
}

.uploadContent{
  @include flex-center;
  flex-direction: column;
  margin-top: 5%;
  margin-Bottom: 5%;
  text-align: center;
  padding: $spacing-08 $spacing-09;
  gap: $spacing-03;

  svg {
    height: unset !important;
    width: unset !important;
  }
}

.gridContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing-04;
  width: 100%; 
  margin-bottom: $spacing-07;
}

.uploadCard{
  display: flex;
  justify-content: end;
  background-color:$white;
  flex-direction: column;
  border: $spacing-01 solid $slate-50W !important;
  border-radius: $border-radius;
  width: calc(50% - 6px);
  min-width: 500px;
}

.uploadCard:focus {
  @include focus-outline($violet-20W, $border-radius-lg);
  outline-offset: $spacing-02 !important;
 
} 

.uploadCardError{
  @extend .uploadCard;
  border: $spacing-01 solid $red !important;
}

.errorMessage{
  @include font-settings($font-small, $weight-regular, $line-height-small);
  display: flex;
  font-family: $font-family-text;
  color:$red !important;
  padding-top: $spacing-03; 
}

.errorIcon{
 padding-right: $spacing-02;
 padding-top: 1px;
 width: $spacing-05;
 height: $spacing-05;
}

.imageContent{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-05;
  padding: $spacing-05 $spacing-07;
  height: 78px;

  .imageTitleWrapper {
    display: flex;
    flex-direction: row;
    gap: $spacing-05;
    width: 93%;
  }
}

.image {
  height: $spacing-09;
  width: $spacing-09;
}

.imageTitle{
  @include font-settings($font-regular, $weight-bold, $line-height-regular);
  font-family: $font-family-text;
  flex: $spacing-01;
  width: 82%;

  .imageTitleInfo {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin: 0;
  }
}

.loadingStatus{
  @include font-settings($font-small, $weight-regular, $line-height-small);
  font-family: $font-family-text;
}

.loadingError{
  @include font-settings($font-small, $weight-regular, $line-height-small);
  font-family: $font-family-text;
  color: $red !important;
}

.deleteIcon{
  width: $spacing-07;
  height: $spacing-07;

  &:focus {
    background-color: $white !important;
    @include focus-outline($violet-20W, $spacing-02);
  };
}

.deleteIconError{
  width: $spacing-07;
  height: $spacing-07;

  &:focus {
    background-color: $white !important;
    @include focus-outline($violet-20W, $spacing-02);
  };
}

.progressBarContainer{
  height: $spacing-03;
}

.progressBar {
  height: $spacing-03;
  width: 100%;
  border-bottom-left-radius: $spacing-02;
}

.filler {
  background: $violet;
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
}

.fillerDone {
  background: $green;
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
}

.table{
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    tr {
      td:nth-child(2) {
        width: 150px;
      }
    }
  }

  tbody {
    tr {
      td:first-child {
        .tbodayFilName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      td:nth-child(2) {
        width: 150px;
      }

      .noAttachment{
        @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate);
        text-align: center;
        padding:  $spacing-04 $spacing-07;
        border-bottom: $border-default;
      }
    }

    tr.fadeEnter, tr.fadeExit {
      transition: all 0.3s ease-in-out;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(50px);
  }
}

.fadeEnter {
  opacity: 0;
  animation: fadeIn 300ms ease-in forwards;
}

.fadeExit {
  animation: fadeOut 300ms ease-out forwards;
}

.tableHeader{
 display: table-row;
}

.tableRow {
  display: table-row;
}

.tableRow:nth-child(odd) {
 background-color: $slate-95W;
}

.tableData{
  @include font-settings($font-regular, $weight-regular, $line-height-regular);
  font-family: $font-family-text;
  letter-spacing: 0;
  white-space: nowrap;
  color: $soft-black;
  border-bottom: $border-thick;
  min-width: min-content;
  padding: $spacing-04 $spacing-07;
  vertical-align: middle;
  display: table-cell;
}

.tableDataFileName{
  @extend .tableData;
  font-family: $font-family-text;
  text-decoration-line: none;
  cursor: pointer;
}

.tableHeaderCell{
 @extend .tableData;
  font-weight: $weight-bold !important;
  border-top: $border-default;
  padding: $spacing-04 0;

  div {
    padding: 0 $spacing-07;
  }

  &:not(:last-child) {
    div {
      border-right: $border-default;
    }
  }
}

.deleteButton{
  @include font-settings($font-regular, $weight-medium, $line-height-regular);
  font-family: $font-family-text;
  color: $navy;
  text-decoration-line: underline;
  cursor: pointer;
}

@include tablet {
  .gridContainer {
    flex-direction: column;

    .uploadCard {
      width: 100%;
    }
  }
}