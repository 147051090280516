@import "src/Components/Styles/mixins";
@import "src/Components/Styles/variables";

.selectWrapperBase {
  position: relative;
  display: flex;
  align-items: center;

  label {
    display: block;
    font-size: $font-small;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 8px;
  }

  select {
    background-color: $white;
    border: $grey-20 1px solid;
    border-radius: $border-radius;
    font-size: $font-small;
    padding: 9px 16px;
    width: 100%;
    @include appearance(none);
  }

  & > div {
    position: relative;
    width: 100%;

    &:after {
      pointer-events: none;
      color: $soft-black;
      content: "expand_more";
      font: {
        family: $font-family-icons;
        size: $font-xxlarge;
      }
      @include cross-browser-icon-support;
      line-height: 0;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }
  }


}