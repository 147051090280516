@import "Components/Styles/Core/variables";

.internalUserDetailsFormField {
    input, select {
      color: $slate-70B;
  
      &:focus {
          box-shadow: inset 0 0 0 $spacing-01 $slate-50W;
          outline: $spacing-01 solid $violet-20W;
          outline-offset: $spacing-01;
      }
    }
  }