@import "Components/Styles/Core/variables";

.internalPIUserAccessToggle {
  --inputSliderCheckedBgColor: #{$navy};
  --inputSliderUncheckedBgColor: #{$slate};
  --inputSliderReadOnlyBgColor: #{$slate-80W};
  --inputSliderReadOnlyColor: #{$slate};
  --inputSliderBgColor: #{$white};
  --inputSliderFocus: #{$violet-20W};
}
