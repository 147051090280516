@import "../../Styles/Core/variables.scss";

// General Colors
$dropdown-background-color: $white;
$label-color: $slate-70B;

$dropdown-min-width: 142px;
$dropdown-max-width: 986px;

$actionMenu-min-width: 139px;
$actionMenu-max-width: 302px;
