@import "src/Components/Styles/adaptive";

.appLayout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.mainContent {
  //width: 100%;
  width: $tablet-width;
  margin: 24px auto;
  flex: 1;

  @include desktop {
    padding: 0 8px;
    width: 100%;
    max-width: $desktop-width;
  }
}

//V2 Login Page Styles
.portfolioPortalMainContent {
  width: 100%;
  margin: 0;
  padding: 0;
}