.page {
    &__error {
        &--holder {
        padding-top: 100px;
      }
    }
}

.landing-page-main-image {
    @include border-radii($topRight: .25rem, $bottomRight: .25rem);
    width: 100%;
    height: 100%;
}

.hiddenItem {
    display: none;
}

.hbt-main-content {
    min-height: 400px;
}