@import 'Project/Website/styles/main.scss';

.form-select {
    width: 100%;
    padding: 12px 32px 12.5px 16.5px !important;
    @include appearance(none);

    border: {
        color: $grey-20;
        width: 1px;
        radius: 4px;
    };

    :global(label.error) & {
        border: {
            color: $notification-error;
        }
    }

    &[aria-disabled="true"] {
        background-color: $grey-6;
        border-color: $grey-20;
        opacity: 1;
    }

    option:not(:first-of-type) {
        color: $soft-black;
    }

    &-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        &::after {
            pointer-events: none;
            content: "expand_more";
            font: {
                family: $font-family-icons;
                size: $font-regular;
            }
            color: $soft-black;
            @include cross-browser-icon-support;
            margin: 12px;
            position: absolute;
            right: 0;
        }
    }
}
