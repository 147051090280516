.dataVis {

    @font-face {
        font-family : "Material Icons";
        font-style  : normal;
        font-weight : 400;
        src         : url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
    }

    .material-icons {
        font-family                   : "Material Icons", sans-serif;
        font-weight                   : normal;
        font-style                    : normal;
        font-size                     : 24px;
        line-height                   : 1;
        letter-spacing                : normal;
        text-transform                : none;
        display                       : inline-block;
        white-space                   : nowrap;
        word-wrap                     : normal;
        direction                     : ltr;
        -webkit-font-feature-settings : "liga";
        -webkit-font-smoothing        : antialiased;
    }

    .card__header {
        padding : 24px 32px;

        .card__header-title {
        .card__header-title__pre-title {
            font-size   : 16px;
            display     : block;
            font-weight : 700;
        }

        .card__header-title__sub-title {
            font-size   : 16px;
            font-weight : normal;
            display     : block;
        }
        }

        .status-tag {
            display        : inline-block;
            border         : $grey-60 3px solid;
            border-radius  : 24px;
            color          : $grey-80;
            font-weight    : 500;
            font-size      : 14px;
            margin-top     : 8px;
            padding        : 4px 16px;
            text-transform : uppercase;
        }

        .card-header__action-buttons {
            text-align : right;
            display: inline-flex;

            & > .dropdown-button {
                margin : 0 8px;
  
                &:last-child {
                    margin : 4px;
                }
            }

            & > button {
                margin : 0 8px;

                &:last-child {
                margin : 0 4px;
                }
            }
        }
    }

    .card {
        &--horizontal-blue {
            border-top : $global-blue 8px solid;
        }

        &--vertical-blue {
            border-left : $global-blue 8px solid;
        }

        &--vertical-grey {
            border-left : $grey-6 8px solid;
        }
        &--vertical-success {
            border-left : $notification-success 8px solid;
        }

        &--vertical-warning {
            border-left : $notification-warning 8px solid;
        }

        &--vertical-error {
            border-left : $notification-error 8px solid;
        }
    }

    .table {
        &__header {
            background-color : $white;
            z-index          : 3;
        }
        &__row {
            transition : background-color 0.3s ease;

            &.hover-row:hover {
                background-color : #f2f4f5;
                cursor           : pointer;
            }

            .checkbox__wrapper {
                margin-top: 5px;
                z-index: 1;
            }
            &.selected {
                background: #EDF2F4 !important;
            }

        }
    }

    .sortLink {
        color          : $grey-60;
        font-family    : $font-family-text;
        font-size      : $font-small;
        font-weight    : $weight-bold;
        letter-spacing : 0;
        line-height    : $line-height-regular;
        margin-right   : 24px;
        position       : relative;
        white-space    : nowrap;

        &.active {
            color : $soft-black;
        }

        .sortIndicator {
            position  : absolute;
            top       : 50%;
            right     : -24px;
            transform : translateY(-50%);
        }
    }

    .sortIndicator {
        color          : $grey-20;
        display        : inline-flex;
        flex-direction : column;

        .material-icons {
            line-height : 0.35;

            &:hover {
                color : $grey-20;
            }

            &.active {
                color : $shade-30;
            }
        }

    }

    .status-indicator {
        border-radius : 50%;
        display       : inline-block;
        height        : 8px;
        margin-bottom : 1px;
        margin-right  : 8px;
        width         : 8px;

        &--declined {
          background-color : $notification-error;
        }

        &--approved, &--approved-with-modifications {
          background-color : $notification-success;
        }

        &--pending-cmhc-review,
        &--unassigned,
        &--pending-lender-input {
          background-color : $notification-warning;
        }

        &--in-review {
          background-color : $global-blue;
        }

        &--cancelled {
          background-color : $grey-20;
        }

        &--inactive {
          background-color : $grey-20;
        }
    }

    .data-table__wrapper {
        border-top: 1px solid $grey-20;
        overflow-x: scroll;
        width: 100%;

        @include desktop {
          overflow-x: hidden;   
        }

        & > table {
            table-layout: auto;
            width: 100%;
        }
    }

    .data-table {
        border-collapse: collapse;
        border-top: none;
        position: relative;

        th {
            top: 0;
        }

        .form__element--checkbox {
            position: relative;
            input[type=checkbox]:focus {
                outline: none;
                top: -10px;
                & + label::before {
                    outline: 2px solid $tint-40;
                    top: -5px;
                }
                & + label::after {
                    top: -9px;
                }
            }
        }
    }

    .h-scroll-wrapper {
        position: relative;
    }

    .h-scroll-container {
        position: relative;
        overflow-x: scroll;
        overflow-y: visible;
 
        &::-webkit-scrollbar {
            height: 10px;
        }

        &::-webkit-scrollbar-track {
            background: none;
        }

        &::-webkit-scrollbar-thumb {
            background: $grey-20;
            border-radius: 4px;

            &:hover {
            background: $grey-60;
            }
        }
    }

    .btn__content-wrapper {
        display: flex;
        align-items: center !important;
        justify-content: center;

        .btn-primary &, .btn-secondary & { justify-content: center; }

        .icon-button & { justify-content: flex-start; }
    }

    .btn {
        height: max-content !important;

        &:disabled {
          cursor: initial !important;

          .material-icons:hover { color: initial !important; }
        }

        &.btn-primary:disabled:hover,
        &.btn-secondary:disabled:hover { background: $grey-80; }

        &.btn-secondary:disabled:hover { color: $white; }
        &.btn-contextual:disabled:hover {
          background: $white !important;
        }

        &-outline { min-height: 32px; }
        &:focus { border: initial;}
        &-icon {
            min-width: initial !important;
            padding: 0 !important;
        }
    }
    .icon {
        &--size-16 { font-size: 16px !important; }
        &--size-24 { font-size: 24px !important; }
        &--size-32 { font-size: 32px !important; }
        &--size-40 { font-size: 40px !important; }
    }

    .dropdown-button {
        display  : inline-block;
        position : relative;

        .dropdown-menu {
            background      : $grey-3;
            width           : max-content;
            margin-top      : 8px;
            border          : $grey-20 1px solid;
            box-shadow      : 0 0 3px 0 rgba(0, 0, 0, 0.25);

            .btn__content-wrapper {
                justify-content: left;
            }

            button {
                background    : none;
                border-radius : 0;
                color         : $soft-black;
                display       : block !important;
                font-size     : 14px;
                font-weight   : 200;
                margin        : 0;
                text-align    : left;
                width         : 100%;
                height        : max-content;
                padding-top   : 8px;
                padding-bottom: 8px;
                letter-spacing: 0.025rem;

                &:hover {
                    background-color : $grey-6;
                    color            : $soft-black;
                }
            }
        }
    }

}