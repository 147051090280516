.upload-progress-bar--arrears {
  margin-bottom: 0 !important;
  margin-top: 16px !important;
}

.upload-progress-bar__completeBorder {
  background-color: #447f00;
  border-bottom-left-radius: 5px;
  height: 3px;
  width: 0%;
}
