.toast-notification-enter {
  display   : block;
  top       : 0;
  transform : translateY(50%);
}

.toast-notification-enter-active {
  animation : toast-notification-in-out 0.25s ease-in forwards;
}


.toast-notification-exit-active {
  animation : toast-notification-in-out 0.25s ease-out reverse forwards;
}

.toast-notification {
  background    : rgba(0, 0, 0, 0.9);
  border-radius : 8px;
  color         : $white;
  display       : block;
  left          : 50%;
  padding       : 24px 24px 24px 16px;
  position      : fixed;
  top           : 150px;
  transform     : translateX(-50%);
  width         : 627px;
  z-index       : 102;

  .toast-notification__wrapper {
    align-items     : flex-start;
    display         : flex;
    justify-content : space-between;
    padding         : 0 !important;

    .toast-notification__wrapper__icon {
      flex-shrink : 1;
      margin-top  : -4px;

      .material-icons {
        color : $white !important;
      }
    }

    .toast-notification__wrapper__content {
      flex-grow    : 3;
      padding-left : 16px;
    }

    .toast-notification__wrapper__content__title {
      flex-grow : 3;

      h2 {
        color       : $white;
        font-size   : 16px;
        line-height : 32px;
        margin-top  : -8px;
      }
    }

    .toast-notification__wrapper__content__text {
      p {
        margin-bottom : 0 !important;
        font-size: 14px;
      }
    }

    .toast-notification__close-button {
      margin-top : -8px;

      .material-icons {
        color : $white !important;
      }
    }
  }
}

@keyframes toast-notification-in-out {
  0% { opacity : 0; }
  100% { opacity : 1; }
}