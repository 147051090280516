@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/adaptive";

.validationMessage {
  white-space: normal;
}

.card {
  > section:first-of-type {
    @extend .cardBase;
  }
}
.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;
  grid-auto-flow        : dense;

  & > div { margin-bottom : 24px; }

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "title buttons";
    grid-template-columns : 1fr 1fr;

    & > div:first-child { grid-area : title; }
    & > div:last-child { grid-area : buttons; }
  }
}

.noSearchResultsMessage {
  display: block;
  padding: 32px;
  text-align: center;
  font-weight: bold;
}

.gridList {
  display: flex;
  margin: 0;
  padding: 40px 33px 33px 33px;
  width: 100%;
  h3 {
    margin-left: 48px;
  }
}

.emptyDiv {
  padding: 45px;
  border: 1px solid $grey-20;
}

.buttonsContainer {
  text-align: left;
  padding: 0 0 0 32px;
  button {
    padding: 12px 32px;
    margin-left: 16px;
  }

  @include desktop {
    margin-left: -64px;
    padding: 24px;
    text-align: right;
  }
}

.titleContainer {
  padding: 24px 0 0 32px;
  h3 {
    margin-left: 48px;
  }
}

.dataTable table {
  width: 100%;
  table-layout: fixed;
  tr th, tr td {
    &:first-of-type {
      width: 10%;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:last-of-type {
      width: 25%;
    }
  }

  tr td:last-of-type{
    white-space: normal;
  }

  tbody tr:nth-child(even) td {
    background: $grey-3;
  }
}
.textualButton {
  padding: 0px;
}

.dataTableFooter {
  padding: 24px 0px 0px 0px;
}
.chevronButton {
  border: none;
  background-color: $white;
  height: 40px;
  width: 24px;
  margin-right: 24px;
  button {
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
}

.innerMargin {
  @extend .cardBase;
  margin: 32px;

  > div {
    padding: 32px;
  }
}

.buttonLayoutCreate {
  > div {
    padding-left: 0px;
  }
}
