@import "src/Components/Styles/variables";

.cancelClaimFormHeader {
  margin: 32px 0px;
  border-bottom: solid 1px $grey-20;
  & > header {
    margin-bottom: 24px;
  }
}
.textHeader {
  margin: 32px;
}
.closeButton {
  display: block;
  position: absolute;
  right: 32px;
  top: 32px;

  button {
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
}
.cancelClaimFormBody {
  margin: 32px;
  & > p {
    margin-bottom: 24px;
  }
  .textStyle {
    margin: 32px 0px;
  }
  .cancelForm {
    .buttonWrapper {
      align-content: space-around;
      display: flex;
      border-top: thin solid $grey-20;
      padding-top: 32px;
      margin: 20px;
      button {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
          margin-left: 0px;
        }
      }
    }

    .saveIconWrapper {
      position: absolute;

      & > span {
        animation: spin 0.9s linear infinite reverse;
        left: -28px;
        position: absolute;
      }
    }

    .templateWrapper {
      .cancelNotification {
        margin-top: 32px;
        margin-bottom: 32px;
        textArea {
          height: 350px;
          border: 1px solid $grey-20;
          border-radius: 4px;
        }
      }
      .notificationTemplate {
        margin-top: 24px;
      }
      .notificationTemplateWrapper {
        height: 290px;
        border: 1px solid $grey-20;
        border-radius: 4px;
        background-color: $grey-3;
        padding: 12px 12px;
        font-size: 14px;
        margin-bottom: 24px;
        p:nth-child(3) {
          word-break: break-word;
          white-space: normal;
        }
      }
      .cancelNotificationTemplate {
        textArea {
          height: 140px;
          border: 1px solid $grey-20;
          border-radius: 4px;
        }
        ::-webkit-input-placeholder {
          color: $soft-black;
        }
      }
    }
  }
}
