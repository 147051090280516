@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.breadcrumbContainer {
  @include flex(center, unset);
  padding: $spacing-07 0 $spacing-10 $spacing-11;
  gap: $spacing-04;
}

@include for-tablet {
  .breadcrumbContainer {
    padding: $spacing-07 0 $spacing-10 $spacing-08;
  }
}