@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.dataTableFooter {
  padding: 0 34px 24px 34px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.pager {
  min-width: fit-content;

  button {
    height: 42px;
    width: 42px;
    font-weight: bold;
    border: #bbb 1px solid;
    font-size: 16px;
  }

  button:disabled {
    background: $grey-20;
    color: $soft-black;
  }

  button[aria-current="true"] {
    background: $global-blue;
    color: $white;
    border-color: $global-blue;

    &:hover {
      background: lighten($global-blue, 5);
      border-color: lighten($global-blue, 5);
    }
  }
}

.itemsPerPage {
  min-width: 140px;
  margin-left: 8px;

  @include desktop {
    margin-left: 16px;
  }

  select {
    background-color: $white;
  }
}

.itemXofY {
  color: $grey-80;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  min-width: fit-content;
}

.totalItems {
  @include desktop {
   max-width: 400px;
  }
  border: solid 1px transparent;
  white-space: nowrap;
  max-width: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.totalItems:hover,
.totalItems:active {
  overflow: visible;
  margin-top: -32px;
  
    @include desktop {
      margin-top: 0px;
    }
}

.totalIemsWidth {
  @include desktop {
    max-width: 300px;
  }
  max-width: 22px;
}

.dataTableFooterDropdown {
  flex-shrink: 1;
  max-width: 120px;
  padding-right: 0;

  select {
    margin-right: 0;
    padding-left: 12px;
  }

  &:after {
    right: 11px;
  }

}