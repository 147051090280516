@import "src/Components/Styles/variables";
@import 'Project/Website/styles/_layout.scss';

.half {
  width: calc(50% - #{$gutter-width / 2});
  padding: {
      bottom: $gutter-width / 2;
  };

  &Left {
      @extend .half;
      margin: {
          right: $gutter-width;
      };
  }
}

.statusChangeReasonFormHeader {
  margin: 32px 0px;
  border-bottom: solid 1px $grey-20;
  & > h2 {
    margin-bottom: 24px;
  }
  .textHeader {
    margin: 32px;
  }
  .closeButton {
    display: block;
    position: absolute;
    right: 32px;
    top: 32px;

    button {
      background: 0;
      border: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
    }
  }
}

.statusChangeReasonFormBody {
  margin: 32px;
  & > p {
    margin-bottom: 24px;
  }
  .textStyle {
    margin: 32px 0px;
  }
  .statusChangeReasonForm {
    .buttons {
      border-top: $grey-20 1px solid;
      padding-top: 32px;
    }

    .saveIconWrapper {
      position: absolute;

      & > span {
        animation: spin 0.9s linear infinite reverse;
        left: -28px;
        position: absolute;
      }
    }

    .templateTextArea {
      margin-top: 32px;
      margin-bottom: 32px;

      textarea{
        height: 350px;
        border-radius: 4px;
      }
    
      > div:first-of-type {
        padding: 8px 0;
      }
    }
  }
}