/* ==================================================
  Last revised on 2020-08-29 by Selin Denise Acar.
  Browser compatibility determined by a combination
  of verification from the Autoprefixer plug in,
  Caniuse, W3, and Mozilla.
  Compatibility notes included with code below
================================================== */

@mixin transform($transforms) {
  /* NOTE: Transform is not supported for SVG elements on IE11 (transform attribute can be used instead) */
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin translateX($x) {
  @include transform(translateX($x));
}

@mixin appearance($value) {
  /* NOTE: This is used for hiding default browser features such as date pickers and buttons so that they can be overridden.*/
  /* NOTE: This is NOT supported by IE. */
  -moz-appearance: $value;
  -webkit-appearance: $value;
  appearance: $value;
}

@mixin user-select($value) {
  -webkit-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin cross-browser-icon-support {
  -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. */
  text-rendering: optimizeLegibility; /* Support for Safari and Chrome. */
  -moz-osx-font-smoothing: grayscale; /* Support for Firefox. */
  font-feature-settings: 'liga'; /* Support for IE. */
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius; /* NOTE: support for Chrome 4.0 and Safari 3.1, but the latest versions (4.0 & 3.1, respectively) supports it */
  -moz-border-radius: $radius; /* NOTE: support for Firefox 3.0 and later, but the latest version (4.0) supports it */
  border-radius: $radius;
}

@mixin border-radii($topLeft: 0, $topRight: 0, $bottomLeft: 0, $bottomRight: 0) {
  /* NOTE: support for Chrome 4.0 and Safari 3.1, but the latest versions (4.0 & 3.1, respectively) supports it */
  -webkit-border-top-left-radius:     $topLeft;
  -webkit-border-top-right-radius:    $topRight;
  -webkit-border-bottom-right-radius: $bottomRight;
  -webkit-border-bottom-left-radius:  $bottomLeft;

  /* NOTE: support for Firefox 3.0 and later, but the latest version (4.0) supports it */
  -moz-border-radius-topleft:     $topLeft;
  -moz-border-radius-topright:    $topRight;
  -moz-border-radius-bottomright: $bottomRight;
  -moz-border-radius-bottomleft:  $bottomLeft;

  border-top-left-radius:     $topLeft;
  border-top-right-radius:    $topRight;
  border-bottom-right-radius: $bottomRight;
  border-bottom-left-radius:  $bottomLeft;
}


@mixin box-shadow($args) {
  border-collapse: separate; /* NOTE: To allow box-shadow support for IE 9+ */
  box-shadow: $args;
}

@mixin flexbox {
  /* NOTE: flex does not play well in i.e. 11 if min-height is used on the same element */
  display: -ms-flexbox; /*support for IE 10.x, but the lastest version of IE (11.0) supports it */
  display: flex;
}

@mixin flex-direction($value) {
  /* NOTE: Works on Mac Firefox browser but docs suggest that firefox compatibility is dependent on enabling through setting layout.css.prefixes.webkit to true */
  flex-direction: $value;
}

@mixin align-items($value) {
  /* NOTE: align-items may not be supported by IE */
  align-items: $value;
}

@mixin align-self($value) {
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

@mixin appearance($value) {
  /* NOTE: This is used for hiding default browser features such as date pickers and buttons so that they can be overridden.*/
  /* NOTE: This is NOT supported by IE. */
  -moz-appearance: $value;
  -webkit-appearance: $value;
  appearance: $value;
}

@mixin cross-browser-icon-support {
  -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. */
  text-rendering: optimizeLegibility; /* Support for Safari and Chrome. */
  -moz-osx-font-smoothing: grayscale; /* Support for Firefox. */
  font-feature-settings: 'liga'; /* Support for IE. */
}