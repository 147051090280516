.react-datepicker__header { 
    background-color: white;
}

.react-datepicker__day--outside-month {
    color: #999999 !important;
    pointer-events: none;
  }

.react-datepicker {
    font-size: 16px;
    height: 100%;
}
.react-datepicker__header {
  padding-top: 0.8em;
  border-bottom: 0px solid;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #4A4A4A;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #4A4A4A;
  right: 1em;
} 

.form .date__picker { 
  width:100%;
  height: revert !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
  /* IE10+ specific styles go here */  
  .react-datepicker__input-container { 
    top: 6px;
  }
}
