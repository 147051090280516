@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.cardHeader {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas: "topLeft" "topRight" "bottomLeft" "bottomRight";
  padding: 32px;

  @include desktop {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "topLeft topRight" "bottomLeft bottomRight";
  }
}

.claimDetailsCardHeaderInternal {
  grid-template-columns: 1fr;
  .titleContainer {
    grid-area: topLeft;
    margin-bottom: -8px;

    @include desktop {
      margin-bottom: 0;
    }
  }
  .actionButtonsContainer {
    grid-area: topRight;
    margin-bottom: 16px;

    @include tablet {
      & > div > div:nth-child(1) button,
      & > div > div:nth-child(2) button {
        min-width: 200px;
      }
    }

    @include desktop {
      & > div > div:nth-child(1) button,
      & > div > div:nth-child(2) button {
        min-width: 0px;
        padding: 12px 32px;
      }
      justify-self: right;
      margin-bottom: 0;
    }
  }
}

.claimDetailsCardHeaderExternal {
  grid-template-columns: 86% 14%;
  .titleContainer {
    grid-area: auto;
  }
  .actionButtonsContainer {
    grid-area: auto;
    & > div > div:nth-child(1) button,
    & > div > div:nth-child(2) button {
      min-width: 0px;
    }
  }
  @include desktop {
    grid-template-columns: 1fr;
    .titleContainer {
      grid-area: topLeft;
    }
    .actionButtonsContainer {
      grid-area: topRight;
    }
  }
}


.statusOwnerContainer { grid-area: bottomLeft; }

.sideDrawerContestClaim,
.declineClaimSideDrawer,
.sideDrawerCancelClaim {
  z-index: 100;
}