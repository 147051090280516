@import "src/Components/Styles/Core/variables";
@import "src/Components/Styles/Core/adaptive";
@import "./buttonVariables.scss";
@import "./mixins.scss";

// General button styles
.buttonBase {
  @include button-base; 
  @include desktop {
    padding: $button-padding-base;
    text-align: center;
  }
}

.primaryButton {
  @extend .buttonBase;
  background: $primary-color;
  border: 0px solid transparent;
  color: $white;
  transition: 0.5s background ease-in-out;

  &:hover {
    @include button-hover($primary-hover-color);
  }

  &:active {
    @include button-active;

    &:focus {
      outline: none !important;
      outline-offset: none !important;
      background: $primary-hover-color;
    }
  }

  &:focus-visible {
    @include button-focus($primary-focused-outline-color);
  }
}

.secondaryButton {
  @include button-base;
  background-color: $white;
  color: $secondary-default-color;
  border: 2px solid $navy;
  position: relative;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    @include secondary-button-hover($secondary-hover-color);
  }

  &:active {
    @include button-active;
  }

  &:focus-visible {
    @include button-focus($secondary-focused-outline-color);
  }

  &.lg {
    padding: 10px 38px !important;
  }

  &.sm {
    padding: 6px 14px !important;
  }
}

.tertiaryButton {
  @include button-base;
  border: none !important;
  box-sizing: border-box;
  padding: 4px 0px !important;
  background: transparent;
  color: $tertiary-color;
  transition: background 0.3s ease-in-out;
  text-decoration: underline;
  position: relative;
  z-index: 50;

  &:hover {
    color: $tertiary-hover-color;
  }

  &:active {
    @include button-focus($tertiary-pressed-outline-color);
    color: $tertiary-hover-color;
  }
 
  &.withIcon {
    display: flex;
  }

  &:focus-visible {
    @include button-focus($tertiary-focused-outline-color);
    transition : padding 0.2s ease-in-out;
  }
}

.tertiaryButton::before{
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  left: -4px;
  bottom: -4px;
  z-index: 40;
}

.iconButton {
  @include button-base;
  background-color: $white;
  border: 2px solid $icon-default-color;
  display: flex;
  padding: 12px !important;
  flex-shrink: 0;
  color: $icon-default-color;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  width: 48px;
  height: 48px;
  gap: 10px;

  &:hover {
    background: $white;
    border-color: $icon-hover-color;
    color: $icon-hover-color
  }

  &:active {
    @include button-active;
  }

  &:focus-visible {
    @include button-focus($icon-focused-outline-color);
  }
}

.responsive {
  width: 100%;
  padding: 12px;
}

.icon {
  line-height: 0;
  margin-right: 8px;
}

.withLeadingIcon {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 8px;

  &.svg {
    height: 16px !important;
    width: 16px !important;
  }
}

.withTrailingIcon {
  display: flex;
  padding-left: 8px;
  align-items: center;

  &.svg {
    height: 16px;
    width: 16px;
  }
}

.lg {
  padding: 12px 40px;
}

.sm {
  position: relative;
  z-index: 50;
  padding: 8px 16px;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.sm::before{
  content: '';
  position: absolute;
  top: -6px;
  right: 0px;
  left: 0px;
  bottom: -6px;
  z-index: 40;
}

.primaryLoadingButton {
  @extend .buttonBase;
  background: $primary-color;
  border: 0px solid transparent;

  &:focus-visible {
    @include button-focus($primary-focused-outline-color);
  }
}

.secondaryLoadingButton {
  @extend .buttonBase;
  background: $white;
  position: relative;
  border: 2px solid $navy;
  transition: background-color 0.3s ease-in-out;

  &:focus-visible {
    @include button-focus($primary-focused-outline-color);
  }
}
