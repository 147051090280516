@import "src/Components/Styles/variables";

.field-list {
  width: 100%;

  // Global Overrides
  padding: {
    left: 0;
  }
  margin: {
    bottom: 0;
  }
}

.item {
  display: flex;
  padding: {
    bottom: 16px;
  }
  border: {
    bottom: 1px solid $grey-6;
  }
  margin: {
    bottom: 16px;
  }
}

%font {
  font: {
    family: $font-family-text;
    size: $font-small;
  }
  color: $soft-black;
}

.key {
  @extend %font;
  font-weight: $weight-bold;
  flex-basis: #{100% * 3 / 5};
}

.value {
  @extend %font;
  flex-basis: #{100% * 2 / 5};
}
