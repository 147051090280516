@import "./formFieldVariables.scss";
@import "./mixins.scss";

@-moz-document url-prefix() {
  textarea {
    overflow-y: auto;
    scrollbar-color: #D8D8D8 #FFFFFF;
  }
}

textarea::-webkit-scrollbar {
	width: 10px;
	background-color: transparent;
}

textarea::-webkit-scrollbar-track {
  background-color: transparent;
  margin-bottom: 7px;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 7px;
	background-color: #D8D8D8 !important;
}
