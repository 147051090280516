@import "src/Components/Styles/variables";

.cardItem {
  border: 1px solid $grey-20;
  margin: 24px 0;
  border-radius: 3px;

  > div:first-of-type {
    padding: 32px 32px 0;
  }

  section > div > div > div {
    margin-bottom: 2rem !important;
  }

  div:empty {
    display: none;
  }
}

.addButtons { 
  margin-right: 16px;
  display: inline-block;
  width: 185px;
}