@import "Project/Website/styles/main.scss";
@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    border-left: 4px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 24px;

    padding: {
        top: 32px;
        left: 33px;
        bottom: 32px;
        right: 32px;
    }

    &-heading-button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        outline: inherit;
    }
}

.icon {
    margin-left: 0px;

    > button {
        color: $soft-black;

        > div:first-of-type {
            display: flex;
            margin-top: -7%;
        }
    }

    button:disabled,
    button[disabled] {
        background-color: transparent;
    }
}

.completed {
    border-left-color: $global-blue;
}

.collapsing-section {
    padding-right: 20px;
    flex-wrap: wrap;
    margin: {
        top: 32px;
    }
    @include desktop {
        padding: {
            right: 46px;
        }
    }
}

.collapseButton {
    border: 0;
    background: none;
}

.sectionTitleWrapper {
    display: flex;
    justify-content: space-between;
}
