@import 'Components/Styles/Core/variables';
@import './comboboxVariables.scss';
@import 'Components/Styles/Core/mixins';

.container {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  max-width: $dropdown-max-width !important;
  min-width: $dropdown-min-width !important;
  background-color: $dropdown-background-color !important;
  gap: $spacing-03 !important;
}

.labelContainer {
  @include flex(center, unset);
  align-self: stretch;
}

.labelText {
  @include font-settings(
    $font-small,
    $weight-bold,
    $line-height-regular,
    $text-links-text-header
  );
  margin: 0;
  margin-right: $spacing-03;
}

.labelIcon {
  @include flex-center;
}

.comboboxRoot {
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.comboInput {
  padding: 0 0 0 0 !important;
}

.comboOption {
  display: flex !important;
  align-items: center;
  gap: $spacing-03;
  flex: 1 0 0;
}

.popper {
  margin-top: $spacing-02 !important;
  border-radius: $spacing-02 !important;
  border: $spacing-01 solid $input-active-keyline !important;
  box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25) !important;
}

.noOptionsText {
  font-family: $font-family-text !important;
  font-size: $font-small !important;
  font-weight: $weight-bold !important;
  line-height: $line-height-small !important;
  font-style: normal !important;
  color: $text-links-text-header !important;
}

.menuListItem {
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  white-space: normal !important;
  width: 100% !important;
  padding: $spacing-04 $spacing-05 !important;
  gap: $spacing-03 !important;
  background: $dropdown-background-color !important;

  &:hover {
    cursor: pointer;
    background: $violet-90W !important;
  }
}

.menuListItemIcon {
  @include flex(center, unset);
  justify-content: center !important;
}

.menuListItemText {
  @include flex(center, unset);

  padding-top: 0;
  padding-bottom: 0;

  max-width: 100% !important;
  white-space: normal !important;
  word-break: break-all !important;
  overflow-wrap: break-word !important;
  overflow: hidden !important;

  @include font-settings(
    $font-small,
    $weight-regular,
    $line-height-small,
    $dropdown-item-label
  );
}

.helperText {
  flex: 1 0 0;

  @include font-settings(
    $font-small,
    $weight-regular,
    $line-height-regular,
    $tooltip-text-color
  );
}

.errorContainer {
  @include flex-center;
  align-self: stretch;
  gap: $spacing-02;
}

.errorMessageText {
  flex: 1 0 0;
  @include font-settings(
    $font-small,
    $weight-regular,
    $line-height-regular,
    $red
  );
}

.errorInfoIcon {
  @include flex(center, unset);
}

// Storybook Status Select styles
.violet circle {
  fill: $violet;
}

.green circle {
  fill: $green;
}

.red circle {
  fill: $red;
}

.yellow circle {
  fill: $yellow;
}
