@import "src/Components/Styles/variables";

.primaryText {
  display: block;
}

.subText {
  margin-top: -6px;
  display: block;
  font-size: 12px;
  color: #656565
}