@import "src/Components/Styles/variables";

.selectorWrapper {
  border-top: 1px solid $grey-20;
  padding: 16px 32px;
}

.reportingPeriodDropdowns {
  display: flex;
  column-gap: 16px;
  align-items: center;

  button {
    margin-top: 24px;
  }
}