.form__element--override-checkbox {
  justify-content: center;
  margin-bottom: 20px;
}

.form__element--override-checkbox label {
  font-weight: normal !important;
}

.pullDown {
  margin-top: 24px;
  align-self: end;
}