@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.cardHeader {
  padding: 40px 33px 16px 33px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.fileView{
  display: flex;
  justify-content: space-between;
  padding: 16px; ;
}
.modalTitle {
  font-size: $font-large;
  font-weight: $weight-medium;
  padding-top: 3px;
  padding-bottom: 10px;
}

.viewport {
  height: 75vh;
  overflow-y: auto;
}
.layoutContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-flow: dense;
  grid-template-areas:
    "title"
    "buttons";

  &>div {
    margin-bottom: 24px;
  }

  @include desktop {
    column-gap: 32px;
    grid-template-areas: "title buttons";
    grid-template-columns: 1fr 1fr;
  }
}

.buttonsContainer {
  @include desktop{
    display: flex;
  }
  justify-content: space-between;

  grid-area: buttons;
  text-align: left;
  padding: 0 0 0 12px;
  
  .templatebtn {
    align-items: flex-end;
    padding: 24px;
    margin-top: 0px;
    .importUserButtons{
      padding: 1px 16px;
    }
    @include desktop{
      margin-top: 24px;
    }
  }
  
  .useActionBtn {
    align-items: flex-start;
    padding: 24px 24px 8px 24px;
    button {
      padding: 12px 32px;
      margin: 16px;
    }
  }
}




.titleContainer {
  grid-area: title;
  padding: 24px 0 0 32px;
}

.userButtons {
  margin-right: 16px;
  display: inline-block;
  min-width: 190px;
}

.dataTable {
  width: 100%;

  table {
    thead>tr {
      th:nth-child(1) {
        padding-left: 33px;
      }

      th:nth-child(2) {
        padding-left: 36px;
      }

      th:nth-child(3) {
        padding-left: 42px;
      }

      th:nth-child(4) {
        padding-left: 42px;
      }

      th:nth-child(5) {
        padding-left: 10px;
      }

      th:nth-child(6) {
        padding-left: 43px;
      }

      th:nth-child(7) {
        padding-left: 43px;
      }

      th:nth-child(8) {
        padding-left: 53px;
      }

      th:nth-child(9) {
        padding-left: 21px;
      }
    }

    tbody>tr:nth-child(n) {
      td:nth-child(1) {
        padding-left: 33px;
      }

      td:nth-child(2) {
        padding-left: 36px;
      }

      td:nth-child(3) {
        padding-left: 42px;
      }

      td:nth-child(4) {
        padding-left: 42px;
      }

      td:nth-child(5) {
        padding-left: 10px;
      }

      td:nth-child(6) {
        padding-left: 43px;
      }

      td:nth-child(7) {
        padding-left: 43px;
      }

      td:nth-child(8) {
        padding-left: 52px;
      }

      td:nth-child(9) {
        padding-left: 19px;
        padding-right: 9px;
      }
    }

    tbody>tr:nth-child(even) td {
      background: $grey-3;
    }
  }
}

.dataTableFooter {
  >div {
    margin-right: -64px;
  }
}

.description {
  padding: 0px 0px 32px 33px;
  width: 550px;
}

.addUser {
  margin-left: 48px;

  >div {
    margin-right: -16px;
  }
}

.descriptionText {
  padding-right: 33px;
  max-width: 550px;
}

.noSearchResultsMessage {
  display: block;
  padding: 32px;
  text-align: center;
  font-weight: bold;
}
.searchBarWrapper { 
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  .adminToggle{
    padding-left: 24px;
  }
  @include tablet {
    width: 100%;
    margin-bottom: 10px;
  }

  @include desktop {
    flex-grow: 12;
    padding: 16px 16px 16px 52px;
  }
}
.searchBarWidth {
  width: 75%;
}
