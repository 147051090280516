@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.hideForDesktop {
  @include desktop {
    display: none;
  }
}

.shiftLeftButtonBase {
  position : absolute;
  top: 50%;
  transform: translateY(-50%);
  button {
    border: none;
    background: rgba($grey-3, 0.5);
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $white;
    }

    .icon {
      color: var(--PITableScrollContainershiftButtonColor, $global-blue);
    }

    &:focus {
      outline: 2px solid var(--PITableScrollContainershiftButtonFocusColor, $tint-40);
    }
  }
}

.shiftLeftButtonWrapper {
  @extend .shiftLeftButtonBase;
  left: 0;
  z-index: 0;

  button {
    height: 64px;
    width: 32px;
    line-height: 0;
    border-bottom-right-radius: 64px;
    border-top-right-radius: 64px;
    box-shadow: 2px 0 4px rgba($grey-20, 0.5);

    &:hover {
      box-shadow: 2px 0 8px rgba($grey-20, 0.5);;
    }
  }

  .icon {
    position : absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}

.shiftRightButtonWrapper {
  @extend .shiftLeftButtonBase;
  right: 0;

  button {
    height: 64px;
    width: 32px;
    line-height: 0;
    border-bottom-left-radius: 64px;
    border-top-left-radius: 64px;
    box-shadow: -2px 0 4px rgba($grey-20, 0.5);

    &:hover {
      box-shadow: -2px 0 8px rgba($grey-20, 0.5);;
    }
  }

  .icon {
    position : absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

.show {
  animation-name: showToggle;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
}

.hide {
  opacity: 0;
  animation-name: hideToggle;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}

@keyframes showToggle {
  from {
    opacity: 0;
  }
  to { opacity: 1}
}

@keyframes hideToggle {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

