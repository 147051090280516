@import "src/Components/Styles/variables";

.notificationList {
  list-style-type: none;
  margin: 0;
  overflow-y: scroll;
  padding: 0;

  & > li {
    position: relative;
  }

  & > li > div {
    border-bottom: $grey-20 1px solid;
    column-gap: 16px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    transition: background-color 0.3s ease-out;

    &:hover {
      background: $tint-2;
    }

    & > time {
      color: $grey-70;
      font-size: $font-small;
      white-space: nowrap;
    }

    & > span {
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      row-gap: 8px;

      p {
        margin: 0;
      }

      & > span {
        font-size: $font-small;
      }
    }

    & > p {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
      margin-left: 24px;
    }
  }
}

.isUnread {
  p, time {
    font-weight: bold;
  }

  & > ::before {
    background: $global-blue;

    border-radius: 50%;
    content: "";
    display: block;
    height: 8px;
    left: 16px;
    position: absolute;
    top: 22px;
    width: 8px;
  }
}

.selectedNotification {
  background: $tint-5;
}

.endOfPageText {
  color: $soft-black;
  font-size: $font-small;
  font-weight: bold;
  padding: 47px 32px;
  text-align: center;
}