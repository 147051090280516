@import "Components/Styles/Core/variables";
@import "Components/Styles/Core/mixins";
@import "Components/Styles/Core/adaptive.scss";

.mainContent {
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
  min-height: 115vh !important;
  padding: 0 $spacing-11 $spacing-13 $spacing-11;
}

.dropdownIcon {
  margin-left: 5px;
}

.layoutContainer {
  display: flex;
  width: 100%;
  padding: $spacing-08;
  flex-direction: column;
  border-radius: $border-radius;
  border: 1px solid #b4bcc2;
  background: #fff;
}

.headerContainer {
  padding-bottom: $spacing-08;
}

.header {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between !important;
  gap: $spacing-08;
}

.title {
  @include font-settings($font-xxlarge, $weight-medium, $line-height-large);
  color: $slate-70B;
}

.info {
  @include font-settings($font-regular, $weight-regular, $line-height-regular);
  color: $slate-30B;
}

.support {
  @include font-settings($font-regular, $weight-medium, $line-height-regular);
  text-decoration-line: underline;
  color: $link-default-focused-color;
}

.purposeTestCMHCSupportBtnContainer {
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
  gap: $spacing-02;

  .cmhcSupportListContainer {
    margin: 0;
    padding-inline-start: $spacing-05;
    color: $slate-30B;
    animation: easeIn 0.1s;

    .piPurposeTestUserGuideContent {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      @include font-settings(
        $font-regular,
        $weight-regular,
        $line-height-regular,
        $slate-30B
      );

      a {
        @include font-settings(
          $font-regular,
          $weight-medium,
          $line-height-regular,
          $digital-blue
        );
        text-decoration: underline;
        border-radius: $spacing-02;
        display: inline-flex;
        align-items: center;

        .piPurposeTestUserGuideExternalIconLink {
          display: inline;
          align-self: center;
          margin-left: $spacing-03;
        }

        &::after {
          content: none;
        }

        &:hover {
          color: $violet !important;
          text-decoration: none !important;
        }

        &:focus {
          color: $digital-blue !important;
          text-decoration-color: $digital-blue !important;
          outline: $spacing-01 solid $violet-20W;
          border-radius: $spacing-02;
        }

        &:active {
          color: $violet !important;
          text-decoration: none !important;
          outline: $spacing-01 solid $slate-50W;
          border-radius: $spacing-02;
        }
      }
    }

    .needHelpInfoContent {
      @include font-settings(
        $font-regular,
        $weight-regular,
        $line-height-regular,
        $slate-30B
      );

      a {
        @include font-settings(
          $font-regular,
          $weight-medium,
          $line-height-regular,
          $digital-blue
        );
        text-decoration-line: underline;
        border-radius: $spacing-02;
        display: inline-flex;
        align-items: center;

        u {
          &:hover {
            text-decoration: none !important;
          }

          &:active {
            text-decoration: none !important;
          }
        }

        &:hover {
          color: $violet !important;
          text-decoration: none !important;
        }

        &:focus {
          color: $digital-blue !important;
          text-decoration-color: $digital-blue !important;
          outline: $spacing-01 solid $violet-20W;
          border-radius: $spacing-02;
        }

        &:active {
          color: $violet !important;
          text-decoration: none !important;
          outline: $spacing-01 solid $slate-50W;
          border-radius: $spacing-02;
        }
      }
    }
  }
}

.cell {
  width: 120px !important;
}

.purposeTestDatatableContainer {
  padding-top: $spacing-03;

  .tableCellFileDownloadBtn {
    z-index: unset;
  }

  .tableCellFileDownloadBtn div span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .purposeTestDatatablePaginationContainer {
    margin-top: $spacing-10;
    padding-top: $spacing-08;
    border-top: 1px solid $slate-50W;
  }
}

.flyoutContainer {
  width: 620px;
  overflow-y: scroll;
  margin-bottom: $spacing-13;
  max-height: calc(100vh - 200px);
}

.flyoutHeaderContainer {
  padding: $spacing-07 $spacing-08;
}

.flyoutBody {
  overflow-y: auto;
  padding: $spacing-07 $spacing-08;
}

.piPurposeTestFlyoutAccordion {
  &::before {
    display: none;
  }
  &:first-of-type {
    border-bottom: none;
  }

  &:last-of-type {
    margin-top: $spacing-01;
    border-top: none;
  }

  &:not(:first-of-type):not(:last-of-type) {
    border-top: none;
    border-bottom: none;
    margin-top: $spacing-01;
  }

  .piPurposeTestFlyoutAccordionSummary {
    &:hover {
      background-color: $violet-90W !important;
      font-weight: 700 !important;
    };

    &:focus {
      background-color: $white !important;
      outline: $spacing-01 solid $violet-20W !important;
      font-weight: 700 !important;
    };

    &:active {
      background-color: $violet-90W !important;
      border-bottom: 1px solid $slate !important;
      font-weight: 700 !important;
    }
  }
}

.flyoutFooter {
  bottom: 0;
  box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25);
  left: 0;
  padding: $spacing-07 $spacing-08;
  position: absolute;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.icon {
  color: $text-links-text-header;
  margin-top: -7px;
}

.accordion {
  box-shadow: none !important;
}

.accordionHeader {
  align-items: baseline !important;
  padding: 0 $spacing-03 0 0 !important;
}

.accordionTitle {
  @include font-settings($font-regular, $weight-bold, $line-height-regular, $text-links-text-header);
  padding-bottom: $spacing-03;
}

.accordionSubtitle {
  @include font-settings($font-small, $weight-regular, 150%, $text-links-text-header);
}

.checkboxContainer {
  margin-left: -$spacing-03;
  padding-bottom: $spacing-05;
}

@keyframes easeIn {
  100% {
    opacity: 1;
  }

  90% {
    opacity: 0.9;
  }

  80% {
    opacity: 0.8;
  }

  70% {
    opacity: 0.7;
  }

  60% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.5;
  }

  40% {
    opacity: 0.4;
  }

  30% {
    opacity: 0.3;
  }

  20% {
    opacity: 0.2;
  }

  10% {
    opacity: 0.1;
  }

  0% {
    opacity: 0;
  }
}

@include tablet {
  .mainContent {
    padding: 0 $spacing-08 $spacing-13 $spacing-08;
  }
}

.piPurposeTestFilterTagWrapper {
  gap: $spacing-05;

  .tagContainer {
    @include flex(center, unset);
    align-content: center;
    flex-wrap: wrap;
    flex: 1 0 0;
    min-width: 600px;
    padding-right: $spacing-08;
    gap: $spacing-04;
  }
}

.dataTableFooterContainer {
  padding-top: $spacing-03;
  padding-left: $spacing-09; 
}

.dataTableFooterContent {
  @include font-settings($font-small, $weight-regular, 21px);
  padding-top: 3px;
}

.dataTableFooterIconContainer {
  padding-right: $spacing-03;
}

.error {
  color: $red;
}
