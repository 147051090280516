@import "src/Components/Styles/_variables.scss";

// TODO: If we're not removing Bootstrap, this can be imported
%sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.spacefill-sr-only {
  @extend %sr-only;
  position: unset;
}

.header {
  padding: {
    top: 16px;
    bottom: 16px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: {
    color: $white;
    clip: border-box;
  }
  border: 1px solid $grey-6;
  border-radius: 4px;
  margin: {
    bottom: 24px;
  }
}

.thText {
  color: $grey-80;
  font-size: $font-small;
  font-weight: bold;
  display: block;
  position: relative;
}

.tdText {
  color: $soft-black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.letterSent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  margin: 0px;
  padding: 0px;
}

.letterDownloadLink {
  border: none;
  background: none;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  color: $global-blue;
  text-decoration: underline;
  cursor: pointer;
}

.balances {
  display: flex;
  padding: 24px;
  border: {
    bottom: 1px solid $grey-6;
  }
  font: {
    size: $font-small;
  }
  justify-content: center;
  align-items: center;
  text-align: center;

  & > span:first-child {
    flex-basis: 48px;
  }
  & > span {
    flex-basis: 250px;
  }
  & > span:nth-child(5) {
    flex-basis: 350px;
  }
}

.moreActions {
  flex-basis: 48px;
  border: none;
  padding: 0;
}
