@import "../../styles.module";
.content {
  width: 100%;
}

.outstandingChargeText {
  padding: 24px 24px 8px 24px;
  display: block;
}

.tableInput {
  @extend .tableStandardInput;

  margin-bottom: 0px !important;
  & > label {
    margin-bottom: 0px !important;
  }
}

/* To handle date picker - using selector with specific hierarchy to max specificity */
.fieldset {
  width: 100%;
}

.adjustedTrendable {
  input[type='text'] {
   padding-left: 24px;

    &:disabled, &:read-only {
      padding-left: 25px;
    }

    &:read-only:focus-visible {
      outline: none;
    }
  }
}
