.searchBar {
  border-top: 1px solid #CCC;
  padding: 12px 32px;
}

.dataTable {
  display: block;
  
  table {
    width: 100%;

    tr th {
      width: 11.11%;
      
      &:nth-of-type(2) {
        width: 8.5%;
      }

      &:nth-of-type(3) {
        width: 7%;
      }

      &:nth-of-type(5) {
        width: 15%;
      }
      
      &:nth-of-type(7) {
        width: 8%;
      }

      &:first-of-type {
        width: 4%;
      }


      &:last-of-type {
        width: 10.5%;
      }
    }

    tbody tr td {
      width: 11.11%;

      &:first-of-type {
        width: 4%;
      }
    }
  }

  &Footer {
    margin-top: 86px;
    padding-top: 24px;
    border-top: 1px solid #ccc;
  }
}