@import "src/Components/Styles/variables";

.main {
    margin: 24px;
    padding: 18px 10px;
}

.heading {
    font-weight: $weight-bold;
}

.subheading {
    font-weight: $weight-regular;
}