@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.dataTable {
    margin-bottom: 24px;
    table {
      width: 100%;
  
      tbody td:nth-child(2) {
        max-width: 300px;
        white-space: normal;
      }
    }
  }
  
  .searchContainer {
    padding: 16px 32px;
    border-top: $grey-20 1px solid;
  }
  
  .noSearchResultsMessage {
    display: block;
    padding: 32px;
    text-align: center;
    font-weight: bold;
  }

  .rowAction{
    width: 1px;
  }
  .cmhcLoan{
    width: 20%;
  }

.preClaimsUnderwritingReviewContainerFrench {
  div > div:nth-child(2) {
    button > span:nth-child(1) {
      margin: -12px 16px 0px 0px;
    }
  }
  button > span:nth-child(2) {
    text-align: left;
  }
  
  @include desktop {
    div > div:nth-child(2) {
      button > span:nth-child(1) {
        margin: 0px 8px 0px 0px;
      }
    }
    button > span:nth-child(2) {
      text-align: center;
    }
  }  
}