@import "src/Components/Styles/_variables.scss";

.addUnderwritingReviewWrapper {
  margin: 32px;

  & > h2 {
    margin-bottom: 24px;
  }

  input[type="text"] {
    border: $grey-20 1px solid;
    border-radius: 4px;
    font-size: $font-regular;
    padding: 12px 16px;
  }
}

.closeButton {
  display: block;
  position: absolute;
  right: 32px;
  top: 32px;

  button {
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
}
.formRow {
  margin: 32px 0;

  & > div {
    margin: 0px;

    label {
      font-size: 14px;
    }
  }
}
.radioWrapper {
  margin-bottom: 32px;

    label {
      font-size: 14px;
    }
}
.twoColumns {
  display: flex;

  &.formButtonsWrapper {
    border-top: $grey-20 1px solid;
    padding-top: 32px;

    & > div {
      margin: 0 9px;
    }
  }

  & > div {
    flex-grow: 1;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.saveIconWrapper {
  position: absolute;

  & > span {
    animation: spin 0.9s linear infinite reverse;
    left: -28px;
    position: absolute;
  }
}

.validationErrorEnterActive {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: rotateIn3D;
  transform: translateY(-25%);
}

.validationErrorExitActive {
  animation-direction: reverse;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: rotateIn3D;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateIn3D {
  from {
    opacity: 0;
    transform: rotate3d(1, 0, 0, -90deg) translateY(-25%);
  }
  to {
    opacity: 1;
    transform: rotate3d(1, 0, 0, 0deg) translateY(0);
  }
}
