@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive";

.supportMain {
  height: 100%;
  width: 100%;
  gap: $spacing-08;
  @include flex(flex-start, unset);
  flex-direction: column;
  flex: 1 0 0;
  overflow-y: scroll;

  .supportHeaderImageContainer {
    @include flex(center, unset);
    align-self: stretch;
    padding-left: $spacing-11;
    width: 100%;

    .supportTitleContainer {
      @include flex(center, unset);
      flex: 1 0 0;
      width: 50%;

      .supportTitleTextWrapper {
        @include flex(flex-start, unset);
        padding-right: $spacing-08;
        flex-direction: column;
        gap: $spacing-05;
        align-self: stretch;
    
        .supportTitle {
          @include font-settings($spacing-09, $weight-bold, $line-height-xlarge, $slate-70B);
        }
    
        .supportTitleInfo {
          @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-30B);
        }
      }
    }

    .supportMainImageWrapper {
      width: 50%;
      height: 100%;

      .supportMainImage {
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  .supportMainContainer {
    @include flex(flex-start, unset);
    flex-direction: column;
    padding-bottom: $spacing-13;
    gap: $spacing-11;
    align-self: stretch;

    .supportContainer {
      @include flex(flex-start, unset);
      flex-direction: column;
      padding: 0 $spacing-11;
      gap: $spacing-08;
      width: 100%;

      .supportContactInfoWrapper {
        @include flex(flex-start, unset);
        flex-direction: column;
        gap: $spacing-03;
        align-self: stretch;

        .contactInfoHeader {
          @include font-settings($font-regular, $weight-bold, $line-height-regular, $slate-70B);
        }

        .contactInfoContent {
          strong {
            @include font-settings($font-small, $weight-bold, $line-height-small, $slate-30B);
          }

          @include font-settings($font-small, $weight-regular, $line-height-small, $slate-30B);

          a {
            @include font-settings($font-small, $weight-medium, $line-height-small, $digital-blue);
            text-decoration-line: underline;
            border-radius: $spacing-02;
            display: inline-flex;
            align-items: center;

            u {
              &:hover {
                text-decoration: none !important;
              }
  
              &:active {
                text-decoration: none !important;
              }
            }

            &:focus {
              outline: $spacing-01 solid $violet-20W;
            }

            &:hover {
              color: $violet !important;
              text-decoration: none !important;
            }
            
            &:active {
              color: $violet !important;
              text-decoration: none !important;
              outline: $spacing-01 solid $slate-50W;
            }
          }
        }
      }
    }
  }
}

@include for-tablet {
  .supportMain {
    .supportHeaderImageContainer {
      padding-left: $spacing-08;
  
      .supportTitleContainer {
        .supportTitleTextWrapper {
          .supportTitle {
            @include font-settings($spacing-08, $weight-bold, 150%, $slate-70B);
          }
        }
      }
    }

    .supportMainContainer { 
      .supportContainer {
        padding: 0 $spacing-08;
      }
    }
  }
}