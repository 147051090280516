@import "Project/Website/styles/_layout.scss";
@import "src/Components/Styles/variables";

.half {
  width: calc(50% - #{$gutter-width / 2});
  padding: {
    bottom: $gutter-width / 2;
  }

  &Left {
    @extend .half;
    margin: {
      right: $gutter-width;
    }
  }
}

.oneThird {
  width: calc(32% - #{$gutter-width / 2});
  padding: {
    bottom: $gutter-width / 2;
  }

  &Left {
    @extend .oneThird;
    margin: {
      right: $gutter-width;
    }
  }
}

.full {
  width: 100%;
  padding: {
    bottom: $gutter-width / 2;
  }

  &Right {
    @extend .full;
    text-align: right;
  }
}

.subTitleText {
  color: $black;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 16px;
}

.noteAttachment {
  color: $soft-black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 16px 0px;
  &Heading {
    font-weight: bold;
  }
  &Text {
    display: inline;
  }
}

.maxFilesError {
  color: $notification-error;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  padding: 16px 0px;
}

.separator {
  @extend .full;
  color: $grey-20;
}

.attachementDescription {
  &Container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    border: 1px solid $grey-20;
    border-radius: 4px;
    height: 32px;
    width: 80px;
    cursor: pointer;
  }
  &ViewText {
    color: $soft-black;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.attachmentsTable {
  width: 100%;
  margin-bottom: 40px;
  display: table;
  overflow-x: scroll;
  &HeaderCell {
    font-family: $font-family-text;
    font-size: $font-small;
    letter-spacing: 0;
    line-height: $line-height-regular;
    white-space: nowrap;
    color: $grey-60;
    border-bottom: $border-default;
    height: 24px;
    min-width: min-content;
    padding: 20px 16px;
    vertical-align: middle;
    display: table-cell;
  }
  &BodyCell {
    font-family: $font-family-text;
    font-size: $font-small;
    letter-spacing: 0;
    line-height: $line-height-regular;
    white-space: nowrap;
    color: $soft-black;
    border-bottom: $border-default;
    height: 24px;
    min-width: min-content;
    padding: 20px 16px;
    vertical-align: middle;
    display: table-cell;
  }
  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
  &Type {
    width: 30%;
  }
  &Description {
    width: 20%;
  }
  &FileName {
    width: 45%;
  }
  &Action {
    width: 5%;
  }
}

.attachmentCommentPopout {
  position: absolute;
}

.attachmentDownloadLink {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  color: $global-blue;
  text-decoration: underline;
  cursor: pointer;
}

.attachmentCancel {
  color: #656565;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 12px 24px;
  margin: 0px 24px;
}

.attachmentInputArea {
  text-align: center;
  border: $border-thick-dashed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  height: 100%;

  &Icon {
    width: 64px;
    height: 64px;
  }

  &SupportedText {
    font-weight: $weight-regular;
    font-size: $font-small;
  }

  &UploadLabel {
    cursor: pointer;
    font-weight: $weight-bold;
    font-size: $font-regular;
    white-space: nowrap;
    color: $global-blue;
    outline: none;
    display: inline-block;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: underline;
    text-transform: lowercase;
  }

  &ManualUpload {
    cursor: pointer;
    color: transparent;
    border: none;
    display: inline;
    max-width: 62px;
    max-height: 24px;
    padding: 0 6px;
    position: absolute;
    left: -99999rem;

    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    &::before {
      content: "upload";
      user-select: none;
      font-weight: $weight-bold;
      font-size: $font-regular;
      white-space: nowrap;
      color: $global-blue;
      outline: none;
      display: inline-block;
      padding: 0;
      text-decoration: underline;
    }
    &:hover::before {
      border-color: $grey-80;
    }
    &:active {
      outline: 0;
    }
  }
}

.uploadProgressBar {
  border: solid $grey-20 1px;
  border-radius: 5px;
  width: 100%;
  &Main {
    -ms-grid-columns: auto auto;
    -ms-grid-rows: auto auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    width: 100%;
  }
  &Text {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    color: $soft-black;
    font-size: 0.85em;
    font-weight: bold;
    grid-column: 1/2;
    grid-row: 1/2;
    overflow: hidden;
    padding: 15px 0 0 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 2;
  }
  &Percentage {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    color: $grey-60;
    font-size: 0.7em;
    font-weight: bold;
    grid-column: 1/2;
    grid-row: 2/2;
    line-height: normal;
    padding: 0 0 15px 20px;
    z-index: 2;
  }
  &Cancel {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-column: 2/3;
    grid-row: 1/3;
    margin: auto 20px auto auto;
    z-index: 2;
  }
  &ProgressFill {
    background-color: $grey-3;
    border-top-left-radius: 5px;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-column: 1/3;
    grid-row: 1/3;
    width: 0%;
    z-index: 1;
  }
  &ProgressBorder {
    background-color: $global-blue;
    border-bottom-left-radius: 5px;
    height: 3px;
    width: 0%;
  }
}

.uploadProgressBarError {
  background-color: $grey-3;
  border: solid $grey-20 1px;
  border-radius: 5px;
  width: 100%;

  &Main {
    -ms-grid-columns: max-content auto auto;
    -ms-grid-rows: auto auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: max-content auto auto;
    grid-template-rows: auto auto;
    width: 100%;
  }

  &Icon {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-column: 1/2;
    grid-row: 1/3;
    margin: auto 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  &FileName {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    color: $soft-black;
    font-size: 0.85em;
    font-weight: bold;
    grid-column: 2/3;
    grid-row: 1/2;
    padding: 15px 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 2;
  }

  &ErrorMessage {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    color: $grey-60;
    font-size: 0.7em;
    font-weight: bold;
    grid-column: 2/3;
    grid-row: 2/2;
    line-height: normal;
    padding: 0 0 15px 0;
  }

  &Cancel {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-column: 3/4;
    grid-row: 1/3;
    margin: auto 20px auto auto;
  }

  &CancelImg {
    cursor: pointer;
  }

  &BottomBorder {
    background-color: $notification-error;
    border-bottom-left-radius: 5px;
    height: 3px;
    width: 100%;
  }
}

.formContainer {
  margin: 0px;
  width: 100%;
  padding: 0px;
}
