@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.footer {
  border-top : $grey-20 1px solid;
  background: $white;
  padding    : 40px 0;

  img {
    display : block;
    height  : 24px;
    width   : 101px;
  }
}

.footerContent {
  @extend .page-wrapper;
  align-items     : center;
  display         : flex;
  justify-content : space-between;
}
