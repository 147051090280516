@import "src/Components/Styles/variables";

.inputColor {
  > label {
    > div > div > div > div {
      color: $grey-80;
    }
  }
  > :global(label.error) {
    > div > div {
      border-color: $notification-error;
    }
  }
}

.optionSelected{
  background-color: #007bff !important;
  color: $white !important;
}
.optionUnselected{
  background-color: inherit;
}

.emailIDReadOnly input:read-only {
  background: $grey-6;
}
