@import 'Project/Website/styles/main.scss';

.date-picker {
    width: 100%;
    padding: 12.5px 16.5px;
    border: 1px solid $grey-20;

    :global(label.error) & {
        border: {
            color: $notification-error;
        }
    }

    &:disabled {
        background-color: $grey-6;
    }

    &:read-only {
        border: unset;
        outline: unset;
        background: unset;
        background-color: $grey-6;
        border: 1px solid $grey-20;
        border-radius: 0.25rem;
    }

    &-wrapper {
        :global {
            .react-datepicker-wrapper > .react-datepicker__input-container {
                display: flex;
                position: relative;
                align-items: center;
                
                &::after {
                    top: unset;
                    margin: 0;
                    padding: 16.5px;
                    color: $soft-black;
                }
            }
        }

        &:global(.readonly) {
            :global {
                .react-datepicker-wrapper > .react-datepicker__input-container {
                    &::after {
                        content: unset;
                    }
                }
            }
        }
    }
}
