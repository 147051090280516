.selectorWrapper {
  padding: 16px 32px;
}
.monthYearDropdowns {
  display: flex;
  column-gap: 16px;
  align-items: center;

  button {
    margin-top: 24px;
  }
}