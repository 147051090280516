@import "src/Components/Styles/adaptive";

.tableToolsWrapper {
  display: flex;
  margin: 16px 32px;
  align-content: center;

  @include tablet {
    flex-wrap: wrap;
  }
}

.searchBarWrapper {  
  @include tablet {
    width: 100%;
    margin-bottom: 10px;
  }

  @include desktop {
    flex-grow: 12;
    padding-right: 16px;
  }
}

.hideText {
  text-indent: -9999px;
}

.showMyInventoryWrapper {
  flex-shrink: 1;
  padding-left: 16px;
  align-self: flex-start;
}

.inventoryTabs {
  & > div {
    flex-direction: column;
    align-items: flex-start;

    @include tablet {
      width: 406px;
    }
  }
  label {
    margin-bottom: 0.5rem;
  }


}

.noSearchResultsMessage {
  display: block;
  padding: 32px;
  text-align: center;
  font-weight: bold;
}

.draftTableWithScroll {
  width: 100%;
  table-layout: auto;
}

.doNotDisplay {
  visibility: hidden;
}
.removeToggle {
  display: none;
}
.claimsInventoryCardHeaderFrench {
  div > div:nth-child(2) {
    button > span:nth-child(1) {
      margin: -40px 16px 0px 0px;
    }
  }
  button > span:nth-child(2) {
    text-align: left;
  }
  
  @include desktop {
    div > div:nth-child(2) {
      button > span:nth-child(1) {
        margin: 0px 8px 0px 0px;
      }
    }
    button > span:nth-child(2) {
      text-align: center;
    }
  }  
}