@import "../../../../../Components/Styles/adaptive";
@import "../../../../../Components/Styles/variables";

.notificationButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;

  p {
    display: none;
    font-size: $font-small;
    font-weight: bold;
    margin-left: 8px;
    margin-bottom: 0;

    @include desktop { display: block; }
  }
}
.notificationIconContainer {
  position : relative;
  margin-top: 4px;
  margin-left: -4px;

  &.hasUnread:after {
    content: '';
    background: $notification-error;
    display: block;
    position: absolute;
    top: 8px;
    right: 1px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: $white 1px solid;
  }
}