.inventory{
    &__actionBar {
        padding: 24px 0px;
        
        .form.inventorySearchForm{ 
            width: 100%;

            .inventory {
                &__search {

                    label {
                        font-weight: normal;
                        margin-right:16px;
                        margin-bottom: 0rem;
                    }

                    &--input {
                        width: 100%;
                        -moz-appearance: textfield;
                    }
                }
            }
        }
    }
}