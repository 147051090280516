@import "src/Components/Styles/BaseComponentStyles/cards";
@import "../../../../Components/Styles/adaptive";

.card {
  > section:first-of-type {
    @extend .cardBase;
  }
}

.cardHeader {
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.tableToolsWrapper {
  display: flex;
  margin: 16px 32px;
  align-content: center;
}

.searchBarWrapper {
  flex-grow: 12;
  padding-right: 16px;
}

.showMyInventoryWrapper {
  flex-shrink: 1;
  padding-left: 16px;
  align-self: center;
}

.dataTable {
  table {
    width: 100%;

    thead > tr {
      > th:first-of-type {
        padding-left: 33px;
        width: 160px;
      }
    }

    tbody > tr:nth-child(n) {
      > td:first-child {
        padding-left: 33px;

        > span:first-child {
          text-align: left;
          white-space: normal;
          width: 160px;
        }
      }
    }
  }
}

.approvalsTab {
  & > div {
    flex-direction: column;

    align-items: flex-start;

    @include tablet {
      width: 406px;
    }
  }

  label {
    margin-bottom: 0.5rem;
  }
}
