@import "src/Components/Styles/variables";

.notificationHeader {
  align-items: center;
  border-bottom: $grey-20 1px solid;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;

  h2 {
    margin: 0;
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 0 -24px 0 0;
    padding: 0;
  }

  li {
    margin: 0 6px;
  }

  button.notificationHeaderButton {
    background: none;
    border: 0;
    padding: 6px;
  }
}

.refreshLoading {
  animation-name: loadingAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes loadingAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

