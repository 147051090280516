@import "Project/Website/styles/_layout.scss";
@import "src/Components/Styles/variables";

.radio-label {
    font: {
        weight: initial;
        size: 14px;
    }
    padding: {
        left: $gutter-width / 4;
    }

    &-wrapper {
        display: flex;
        align-items: center;
        margin: {
            right: $gutter-width;
            bottom: 0 !important; // Override from global..
        }
    }
}

.radio-wrapper {
    &[aria-checked="true"] {
        span.radio {
            background-color: #005C84;
            border: 4px solid #fff;
            border-radius: 16px;
            box-shadow: 0 0 2px $black;
        }
        &[aria-disabled="true"] {
            span.radio {
                background-color: $soft-black;
                border: 4px solid $grey-20;
                border-radius: 16px;
                box-shadow: 0 0 2px $black;
            }
        }
    }
    &[aria-disabled="true"] {
        span.radio {
            background-color: $grey-20;
            border: 4px solid $grey-20;
            border-radius: 16px;
            box-shadow: 0 0 2px $black;
        }
    }
}

.radio {
    $dim: 16px;
    $accent-colour: $global-blue;
    $primary-colour: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    height: $dim;
    width: $dim;

    background-color: $primary-colour;

    border: 1px solid $soft-black;
    border-radius: $dim;

    :global(fieldset.error) & {
        border: {
            color: $notification-error;
        }
    }

    input[type="radio"]:checked + &::after {
        content: "";
        background-color: $accent-colour;
        height: $dim / 2;
        width: $dim / 2;
        border-radius: $dim / 2;
    }

    input[type="radio"]:disabled + & {
        background: $grey-6;
        border: 1px solid $grey-20;
    }

    input[type="radio"]:focus-within + & {
        outline: 2px solid $tint-40;
    }
}
