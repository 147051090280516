@import "src/Components/Styles/variables";

.documentDownloadWrapper {
  border: 1px solid $grey-20;
  background: $grey-3;
  border-radius: 4px;
  padding: 17px;
  width: 374px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.documentIcon {}

.documentInfo {
  flex-grow: 3;
  margin-left: 16px;

  p {
    margin: 0;

    &:first-of-type {
      font-weight: bold;
    }

    &:nth-child(2) {
      color: $grey-80;
      font-weight: $weight-medium;
      font-size: $font-xsmall;
      margin-top: -4px;
    }
  }
}

.documentDownloadButton {
  background: none;
  border: none;

  img {
    height: 32px;
    width: auto;
  }
}