@import "src/Components/Styles/variables";

.trendIconWrapper.negative {
  & + label input {
    color: $notification-error;
  }
}

.positiveDownArrow {
  color: $global-blue;
}

.positive {
  color: $soft-black;
}
.negative {
  color: $notification-error;
}

.trendIcon {
  position: absolute;
  top: 49%;
  transform: translateY(-50%);

  & > span {
    margin-top: -8px;
    margin-bottom: -8px;
    display: block;
  }
}