@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";
@import "src/Components/Styles/mixins";
@import 'Project/Website/styles/_layout.scss';

.showForDesktopOnly {
    display: none;

    @include desktop {
        display: block;
    }
}

.hideForDesktop {
    display: block;

    @include desktop {
        display: none;
    }
}

.layout {
    & > header {
        & > div {
            display               : grid;
            grid-template-columns : 1fr;
            grid-template-rows    : auto;
            grid-auto-flow        : dense;
            grid-template-areas   : 
              "title"
              "buttons";
            margin: 8px 0;
          
            & > div:first-child { 
                grid-area : title; 
                max-width: 100%;
                padding: 0px;
            }
            & > div:last-child { 
                grid-area : buttons; 
                max-width: 100%;
                text-align: left;
                padding: 0px;
                margin-left: -10px;
            }
            & > div { margin: 8px 0; }
          
            @include desktop {
                column-gap            : 32px;
                grid-template-areas   : "title buttons";
                grid-template-columns : 4fr 4fr;

                & > div:last-child { 
                    text-align: right;
                    margin-left: 10px;
                }
            }
        }
    }
}

.btn {
    position: relative;
    background: 0;
    border: 0;
    line-height: 0;
    margin: 0;
    padding: 0; 

    &:hover {
        cursor: pointer;
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }

    &:focus {
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }
}

.closebtn {
    @extend .btn;
    float: right;
    

    &:after {
        font-family: $font-family-icons;
        font-size: $font-xxlarge;
        content: "close";
        color: $black;
    }
}

.header {
    display: flex;
    padding: 32px;
    border-bottom: 1px solid $grey-20;
    margin: 0px;
}

.title {
    display: flex;
    flex: auto;
}

.textArea {
    font-size: 14px;
    border: 1px solid $grey-20;
    border-radius: 4px;
    height: min-content;
    padding: 12px 16px;
    height: 160px;
    width: 100%;
}

.content {
    padding: 32px;
    
    select, input, textarea {
        font-size: $font-small;
        border: $border-default;
        @include border-radius(4px);
        height: min-content;
        padding: 12px 16px;
        margin-bottom: 16px;

        &:focus {
            @include box-shadow(inset 0px 0px 0px 2px $tint-40);
            box-sizing: border-box;
            outline-color: transparent;
        }

        &:disabled {
            background-color: $grey-6;
            border-color: $grey-20;
            opacity: 1;
        }
    }

    label, legend {
        font-size: $font-regular;
        font-weight: $weight-bold;
        margin-bottom: 0px;

        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }

    select {
        &:not(.react-datepicker__year-select){
            @include appearance(none);
        }
        &.select-element:focus {
            border-color: $tint-40;
            box-shadow: 0 0 1px 1px $tint-40;
            box-shadow: 0 0 0 1px -moz-mac-focusring;
            color: $soft-black;
            outline: none;
            background-color: $white
        }

        &.select-element:hover {
            border-color: $grey-20;
            background-color: $grey-3;
        }

        &.select-element:disabled:hover {
            border-color: $grey-20;
            background-color: $grey-6;
        }

        &.select-element option:checked,
        &.select-element option:hover {
            box-shadow: 0 0 10px 100px $shade-30 inset;
        }

        & option {
            font-family: $font-family-text;
        }
    }
}

.selectContainer {
    width: 100%;
    position: relative;
    margin-bottom: 32px;
}

.full {
    width: 100%;
    padding: {
        bottom: $gutter-width / 2;
    };
}
