$fly-out-width: 408px;

.fly-out {
    background-color: $white;
    border-left: $border-default;
    height: 100%;
    width: $fly-out-width;
    width: 0;
    padding: 0;
    position: fixed;
    top: 0;
    right: $fly-out-width * -1;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.5s;

    &.open {
        right: 0;
        width: $fly-out-width;
    }
}

.fly-out .closebtn {
    padding-left: 16px;
    height: 24px;
    width: 24px;
}

.fly-out .closebtn:hover {
    cursor: pointer;
    outline: 0;
    border: none;
    -moz-outline-style: none;
}

.fly-out .closebtn:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
}

.fly-out .closebtn:after {
    font-family: $font-family-icons;
    font-size: $font-xxlarge;
    content: "close";
    color: $black;
}

.fly-out__header {
    padding: 40px 30px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 30px;
}

.fly-out__header-text {
    font-size: $font-xxlarge;
    font-weight: $weight-medium;
    margin: 0;

    &-subtext {
        font-weight: $weight-bold;
        border-radius: 16px;
        background-color: #e9e9e9;
        margin: 8px 0 -16px 16px;
        text-align: center;
        padding: 2px 24px;
    }
}
