.dataTable {
    display: block;

    table {
        width: 100%;
        table-layout: auto;

        th {
            width: 10%;
            white-space: pre-wrap;
            text-align: center;
            vertical-align: top;

            &:nth-of-type(1) {
                min-width: 7rem;
            }

            &:nth-of-type(2) {
                min-width: 7rem;
            }

            &:nth-of-type(3) {
                min-width: 7rem;
            }

            &:nth-of-type(4) {
                min-width: 7rem;
            }

            &:nth-of-type(5) {
                min-width: 10rem;
            }

            &:nth-of-type(6) {
                min-width: 10rem;
            }

            &:nth-of-type(7) {
                min-width: 13rem;
            }

            &:nth-of-type(11) {
                min-width: 12rem;
            }
        }

        tbody tr td {
            width: 10%;
            white-space: pre-wrap;

            &:first-of-type {
                padding-left: 32px;
            }

            &:nth-of-type(2) {
                min-width: 7rem;
            }

            &:nth-of-type(3) {
                min-width: 7rem;
            }

            &:nth-of-type(4) {
                min-width: 7rem;
            }

            &:nth-of-type(5) {
                min-width: 10rem;
            }

            &:nth-of-type(6) {
                min-width: 10rem;
            }

            &:nth-of-type(7) {
                min-width: 13rem;
            }
        }
    }

    &Footer {
        white-space: nowrap;
        border-top: 1px solid #CCC;
        padding: 24px 0px 0px 0px;

        div:first-of-type>nav>ul {
            flex-wrap: nowrap;
        }
    }
}

.actionIcons {
    font-size: 18px;
}

.form {
    textarea {
        min-height: 50px;
        width: 8rem;
        height: 50px;
    }

    input {
        width: 100%;
    }
}