.side-menu {
    @extend .navigation__items;
    @include flex-direction(column);
    @include align-self(flex-start);
    @include align-items(stretch);
    font-family: $font-family-text;
    font-size: $font-small;
    letter-spacing: 0;
    line-height: $line-height-regular;
    margin-left: unset;
    width: 100%;
}

.side-menu__item {
    @include flexbox;
    @include flex-direction(column);
    width: 100%;
}

.side-menu__item-link {
    background-color: $white;
    color: $soft-black;
    display: inline-block;
    font-size: $font-small;
    font-weight: $weight-bold;
    line-height: $line-height-regular;
    padding: 12px 30px;
    text-decoration: none;
    width: 100%;
    cursor: pointer;
}

.side-menu__item-link:hover {
    background-color: $tint-5;
    color: $soft-black;
}

.side-menu__item-link:active,
.side-menu__item-link:focus {
    outline: 0;
    -moz-outline-style: none;
}

.side-menu__item-link.has-children:after {
    float: right;
    font-family: $font-family-icons;
    font-size: $font-xxlarge;
    content: "expand_more";
}

.side-menu__item-link.has-children[aria-expanded="true"]:after {
    content: "expand_less";
}

.side-menu__item-link[aria-expanded="true"] {
    background-color: $tint-5;
    color: $soft-black;
}

.side-menu__subitems-container {
    background-color: $white;
    list-style: none;
    padding-left: 0;
}

.side-menu__subitem:hover,
.side-menu__subitem[aria-expanded="true"] {
    background-color: $tint-5;
}

.side-menu__subitem-link {
    display: inline-block;
    color: $soft-black;
    font-size: $font-small;
    font-weight: $weight-regular;
    padding: 12px 30px 12px 60px;
    width: 100%;
}

.side-menu__subitem-link:hover {
    background-color: $tint-5;
    color: $soft-black;
}

.side-menu__subitem-link:active,
.side-menu__subitem-link:focus {
    outline: 0;
    -moz-outline-style: none;
}

.side-menu__subitem-link[aria-expanded="true"] {
    background-color: $tint-5;
    font-weight: $weight-bold;
}

.side-menu__subitem-link.has-children:after {
    float: right;
    font-family: $font-family-icons;
    font-size: $font-xxlarge;
    content: "expand_more";
}

.side-menu__subitem-link.has-children[aria-expanded="true"]:after {
    content: "expand_less";
}

.side-menu__subsubitems-container {
    background-color: $white;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.side-menu__subsubitem:hover {
    background-color: $tint-5;
}

.side-menu__subsubitem-link {
    display: inline-block;
    color: $soft-black;
    font-size: $font-small;
    font-weight: $weight-regular;
    padding: 12px 0 12px 90px;
    width: 100%;
}

.side-menu__subsubitem-link:hover {
    background-color: $tint-5;
    color: $soft-black;
}

.side-menu__subsubitem-link:active,
.side-menu__subsubitem-link:focus {
    outline: 0;
    -moz-outline-style: none;
}
