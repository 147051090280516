@import "../../Styles/Core/variables.scss";

// General Colors
$primary-color: $navy;
$primary-hover-color: $violet;
$primary-focused-color: $navy;
$primary-pressed-color: $violet;
$primary-focused-outline-color: $violet-20W;

$secondary-default-color: $navy;
$secondary-hover-color: $violet;
$secondary-focused-color: $navy;
$secondary-pressed-color: $violet;
$secondary-focused-outline-color: $violet-20W;

$tertiary-color: $navy;
$tertiary-hover-color: $violet;
$tertiary-focused-color: $navy;
$tertiary-pressed-color: $violet;
$tertiary-pressed-outline-color: $slate-50W;
$tertiary-focused-outline-color: $violet-20W;

$icon-default-color: $navy;
$icon-hover-color: $violet;
$icon-focused-color: $navy;
$icon-pressed-color: $violet;
$icon-focused-outline-color: $violet-20W;

// Button-Specific Font properties
$button-font-family: 'Roboto', sans-serif;
$button-font-size-base: 16px;
$button-font-weight: 500;

// Button-Specific Border radius
$button-border-radius-base: 4px;

// Button-Specific Padding
$button-padding-base: 12px 40px;

// Button-Specific Gaps
$button-gap-base: 8px;

// Button-Specific Width
$button-min-width: 48px;
$button-max-width: 276px;

// Button-Specific Line Height
$button-line-height: 24px;

// Button-Specific Line Height
$button-line-height: 24px;

// Button Shadow
$button-active-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);

// Button tertiary focus outline color
$button-tertiary-focus-outline-color: $primary-focused-outline-color;