@import "src/Components/Styles/variables";

%iconButton {
  border: unset;
}

.dateSort {
  @extend %iconButton;
  padding: 16px 16px 0;
}

.commentWrapper {
  margin: 16px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr auto;
  gap: 12px 8px;
}

.commentCode {
  grid-area: 1 / 1 / 3 / 1;
}

.comment {
  font: {
    weight: bold;
    size: $font-regular;
  }
}

.editButton {
  justify-self: end;
  @extend %iconButton;
}

.commentInput {
  grid-area: 1 / 2 / 2 / 4;
}

.buttonWrapper {
  display: flex;
  gap: 8px;
}

.cancelButton {
  @extend %iconButton;
}
