.spinning-loader {
    animation     : loader-spin 0.9s linear infinite;
    border        : $grey-6 5px solid;
    border-radius : 50%;
    border-top    : $global-blue 5px solid;
    display       : block;
    height        : 128px;
  
    width         : 128px;
  
    &--wrapper {
      margin          : 75px 0 75px 0;
      width           : 100%;
      display         : flex;
      justify-content : center;
    }
}
  
  
  
@keyframes loader-spin {
    0% {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}