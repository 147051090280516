@import "../../Styles/Core/variables.scss"; 
@import "src/Components/Styles/Core/adaptive"; 
@import "./checkboxVariables.scss"; 

.baseCheckbox {
  color: $checkbox-default-color !important;
  border: none;
  border-radius: $spacing-01;
  transition: background 0.5s ease-in-out; 
  font-size: $font-small !important;
  font-family: $font-family-text !important;
  line-height: $spacing-06 !important;
  font-weight: $weight-regular !important;
  height: 30px;

  &::after {
    transition: none !important;
  }

  &:hover {
    background-color: transparent !important;
  }

  &:active {
    transition: none !important;
  }
}

.errorCheckbox {
  color: $checkbox-error-color !important;
  border: 0 solid transparent;
  size: $spacing-05;
  border-radius: $spacing-01;
  transition: background 0.5s ease-in-out;
  height: 30px;

  &:checked {
    color: $checkbox-default-color !important;
  }
}

.groupLabel, .headerText {
  font-size: $font-large !important;
  font-family: $font-family-text !important;
  font-weight: $weight-bold !important;
  color: $checkbox-header-color !important;
  margin-bottom: 0;
  padding-left: $spacing-03 !important;
}

.groupLabel {
  width: 400px;
  padding-bottom: $spacing-03 !important;
}

.headerText {
  padding-bottom: $spacing-03 !important;
}

.labelText {
  color: $checkbox-label-color;
  font-size: $font-small !important;
  font-family: $font-family-text !important;
  line-height: $spacing-06 !important;
  font-weight: $weight-regular !important;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.labelTextVertical {
  color: $checkbox-label-color;
  font-size: $font-small !important;
  font-family: $font-family-text !important;
  line-height: $spacing-06 !important;
  font-weight: $weight-regular !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  width: 400px;
}

.groupCheckboxContainer{
  display: flex;
  gap: $spacing-07;
  flex-direction: column;
}

.errorMessageText {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $checkbox-error-color;
  font-family: $font-family-text !important;
  font-size: $font-small !important;
}

.errorInfoIcon {
  padding: $spacing-02;
  padding-left: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.errorWrapper {
  display: flex;
  padding-top: 5px !important;
  padding-left: $spacing-03 !important;
}

.errorBelowWrapper {
  display: flex;
  padding-bottom: $spacing-03 !important;
  padding-left: $spacing-03 !important;
}

.boxCheckbox{
  display: flex;
  flex-direction: column;
  gap: $spacing-checkbox;
}

.baseCheckboxHorizontal{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing-checkbox $spacing-09
}

.selectAllLabel {
  color: $checkbox-label-color;
  font-size: $font-small !important;
  font-family: $font-family-text !important;
  line-height: $spacing-06 !important;
  font-weight: $weight-regular !important;
  margin: 0 !important;
  padding: 0 0 $spacing-checkbox 0 !important;
  width: 400px;
  display: flex !important;
  flex-direction: row;
  align-items: normal !important;
  height: 30px;
}

input[type="checkbox"] {
  width: $spacing-05;
  height: $spacing-05;
  top: 7px;
  left: 9px;
  padding: 0 !important;

  &:focus {
    outline: none;
  }
}