@import "Components/Styles/Core/variables";
@import "Components/Styles/Core/adaptive.scss";
@import 'Components/Styles/Core/mixins';

.footerWrapper {
    position: static;
    bottom: 0;
    left: 0;
    width: 100%;

    .footer {
        @include flex(center, space-between);
        flex-direction: row;
        background-color: $white;
        border-top: 1px solid $slate-50W;
        padding: $spacing-09 $spacing-11;

        .footerTextLinkContainer {
            @include flex(center, unset !important);
            flex-direction: row;
            gap: $spacing-07;
            color: $slate-70B;

            .footerText {
                font-family: Roboto;
                font-size: $font-small;
                font-style: normal;
                font-weight: $weight-regular;
                line-height: $spacing-06;
                display: inline-block;
                margin: 0;
            }

            .footerLinks {
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    gap: $spacing-05;
                }

                .links {
                    font-size: $font-small !important;
                    font-weight: $weight-medium !important;
                    line-height: $spacing-06 !important;
                    display: inline-block;
                    color: $navy;
                    text-decoration: underline;
                }
            }
        }
    }
}

.fixedFooterPosition {
  position: fixed;
}

@include for-tablet {
  .footerWrapper {
    .footer {
      padding: $spacing-09 $spacing-08;
    }
  }
}