@import "Components/Styles/Core/variables";
@import "Components/Styles/Core/adaptive";
@import "./variables.scss";
@import "./mixins.scss";

.container {
  width: $flyout-container-width;
  height: $flyout-container-height;
}

.headerContainer {
  align-items: $flyout-header-container-align-items;
  align-self: $flyout-header-container-align-self;
  display: $flyout-header-container-display;
  gap: $flyout-header-container-gap;
  padding: $flyout-header-container-padding;
}

.header {
  color: $flyout-header-color;
  font-family: $flyout-font-family;
  font-size: $flyout-header-font-size;
  font-style: $flyout-header-font-style;
  font-weight: $flyout-header-font-weight;
  line-height: $flyout-header-line-height;
  display: inline-flex;
  gap: $spacing-05
}

.actionsContainer {
  align-items: $flyout-actions-container-align-items;
  align-self: $flyout-actions-container-align-self;
  display: $flyout-actions-container-display;
  gap: $flyout-actions-container-gap;
  padding: $flyout-actions-container-padding;
}

.actionsContainerCustom {
  align-items: $flyout-actions-container-align-items;
  align-self: $flyout-actions-container-align-self;
  display: $flyout-actions-container-display;
}

.actionsIconContainer {
  gap: $flyout-actions-icon-container-gap;
}

.actionsDropdown {
  width: $flyout-actions-dropdown-width;
}

.icon {
  color: $flyout-icon-color;
}

.notificationsContainer {
  height: $flyout-notifications-container-height;
}

.noResults {
  color: $flyout-no-results-color;
  font-size: $flyout-no-results-font-size;
  font-style: $flyout-no-results-font-style;
  font-weight: $flyout-no-results-font-weight;
  line-height: $flyout-no-results-line-height;
  text-align: $flyout-no-results-text-align;
}

.flyoutContainerCloseBtn {
  .icon {
    &:hover {
      color: $violet;
    }
  }

  &:hover {
    border: $spacing-01 solid $violet;
    border-radius: $spacing-02;
    background: $white;
  }
  
  &:focus {
    outline: $spacing-01 solid $violet-20W;
    border-radius: $spacing-02;
  }
}
