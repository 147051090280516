@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";
.textualButton {
    border        : none !important;
    box-sizing    : border-box;
    font-family   : 'Roboto', sans-serif;
    font-size     : 16px;
    font-weight   : 500;
    line-height   : 24px;
    padding       : 0 12px!important;
    text-align    : center;
    display: block;
    margin: 20px auto;
    align-items   : center;
    align-content : center;
    display: block;
    margin: 20px auto;
    margin-bottom: 0;

    @include desktop {
      text-align: center;
    }
  
    &.withIcon {
      display: flex;
    }
  
    background      : #FFFFFF;
    color           : #005C84;
    transition      : background 0.3s ease-in-out;
    text-decoration : underline;
    position        : relative;
  
    &:focus{
      border: 2px solid #99BECE;
      color: #00405C;
    }
  
    &:hover {
      background: #FFFFFF;
      color: #00405C;
    }
  
    &:disabled {
      background: #FFFFFF;
      color: #656565;
    }
  }

  .buttonBase {
    border        : transparent 2px solid;
    border-radius : 4px;
    box-sizing    : border-box;
    font-family   : 'Roboto', sans-serif;
    font-size     : 16px;
    font-weight   : 500;
    line-height   : 24px;
    padding       : 12px 24px;
    text-align    : center;
  
    @include desktop {
      padding: 12px 30px;
      text-align: center;
    }

    align-items: center;
    align-content: center;
  
    &.withIcon {
      display: flex;
    }
  }

  .primaryButton {
    @extend .buttonBase;
    background : #005C84;
    color      : #FFFFFF;
    transition : 0.3s background ease-in-out;
    margin-right: 20px;
    width: 45%;

    &:focus {
      background    : #005C84;
      border        : 2px solid #99BECE;
      border-radius : 4px;
      outline       : none;
    }
  
    &:hover {
      background : #00405C;
    }
  
    &:disabled {
      background : #CCCCCC;
      color      : #282828;
    }
  }

  .secondaryButton{
    @extend .buttonBase;
    background-color : #FFFFFF;
    border           : 1px solid #005C84;
    border-radius    : 4px;
    color            : #005C84;
    position         : relative;
    transition       : background-color 0.3s ease-in-out;
    width: 45%;

    &:hover {
      background   : #F8F8F8;
      border-color : #00405C;
      color        : #00405C;
    }
  
    &:disabled {
      background: $white;
      border-color: $grey-70;
      color: $grey-70;
    }
  
    &:focus {
      border  : 2px solid #99BECE;
  
      outline : none;
  
      &:after {
        border        : #005C84 1px solid;
        border-radius : 4px;
        content       : '';
        display       : block;
        height        : 100%;
        left          : 0;
        position      : absolute;
        top           : 0;
        width         : 100%;
      }
    }
  }