.message {
    margin: {
        top: 8px;
    };
    padding: {
        left: 8px;
    };
        text-wrap: wrap;
    :global(label.error) &,
    :global(fieldset.error) & {
        color: #DA291C;
        font: {
            size: 14px;
            weight: initial;
        }
    }
}
