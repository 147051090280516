// default not collapsible
.accordion .card__header{
    @include appearance(none);

    &:after{
        font-family: $font-family-icons;
        font-size: 32px;
        height:32px;
        @include cross-browser-icon-support;
        float: right;
        padding-top: 8px;
    }
}

// adding collapsible styles
.accordion .card__header--collapsible{
    cursor: pointer;
    color: $grey-80;

    &:after{
        content: "expand_less";
        color: $grey-80;
    }

    &:not(.collapsed):after{
        content: "expand_less";
        color: $grey-80;
    }

    &.collapsed:after{
        content: "expand_more";
        color: $grey-80;
    }
}