@import '../../../../../../Components/Styles/variables';

.documentRequestWrapper {
	display: flex;
	flex-direction: column;
	padding: 32px;
}

.headerWrapper {
	align-items: center;
	border-bottom: 1px solid $grey-20;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 32px 35px 32px 32px;
	h2 {
		margin: 0;
	}
}

.closeButton {
	button {
		background: 0;
		border: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
	}
}

.textWrapper {
	color: $black;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 32px;
	padding-right: 32px;
}

.categoriesWrapper {
	margin-bottom: 32px;
	label {
		font-weight: bold;
		font-size: 14px;
	}
}

.templateWrapper {
	margin-bottom: 32px;
}

.templateTextArea {
	textarea {
		height: 160px;
		border: $border-default;
		border-radius: 4px;
	}

	> div:first-of-type {
		padding: 8px 0;
	}
}

.notesWrapper {
	margin-bottom: 48px;
}

.textSeparator {
	width: 350px;
}

.notesTextArea {
	textarea {
		height: 160px;
	}
}

.buttonWrapper {
	display: flex;
	flex-direction: row;
	border-top: thin solid $grey-20;
	padding-top: 32px;
	button {
		margin-right: 9px;
		&:last-child {
			margin-right: 0;
			margin-left: 9px;
		}
	}
}

.selectOption {
	display: flex;
	flex-direction: row;
	align-items: center;
	label {
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		margin: 0;
		padding: 0;
		margin-left: 16px;
	}
	input[type='checkbox'] {
		appearance: none;
		padding: 1px;
		height: 16px;
		width: 16px;
		border-radius: 2px;
		border: thin solid $black;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	input[type='checkbox']:checked {
		border: none;
		background-color: $global-blue;
	}
	input[type='checkbox']:checked:after {
		color: $white;
		content: '\2713';
		margin-bottom: -1px;
	}
}

.reduceFont {
	margin-top: -32px;
	> label,
	div,
	b {
		font-size: $font-small;
	}

	> div {
		> label,
		> fieldset > legend,
		> fieldset > div > label {
			font-size: $font-small;
		}
	}
}
