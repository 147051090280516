//COLOURS ====================================================================================================================

$soft-black: #282828; //default body text
$grey-80: #4A4A4A; //footer
$grey-70: #656565;
$grey-60: #7A7A7A; //supplementary/help/admin text
$grey-50: #B5BCC2; // divider new design
$grey-20: #CCCCCC; //disabled states, keylines
$grey-6: #E9E9E9; // divider for section/datatable rows separation and hover state indication
$grey-3: #F8F8F8; //contrast and segmenting i.e. textfield bg
$white: #FFFFFF; //default form component / page/card/data table bg
$black: #000000;

$shade-30: #00405C; //hover over state over the component with glibal blue
$global-blue: #005C84; //fill for primary CTA, outline for secondary CTA
$tint-60: #669DB5;
$tint-40: #99BECE; //focus state border outline
$tint-5: #EDF2F4; //selected state on data table row
$tint-2: #F2F4F5; //hover state on data table row

$notification-success: #447F00;
$notification-error: #DA291C;
$notification-warning: #F89100;

//DIVIDERS ====================================================================================================================
$border-default: 1px solid $grey-20;
$border-primary: 1px solid $global-blue;
$border-thick: 2px solid $grey-20;
$border-thick-dashed: 2px dashed $grey-20;
$border-focused: 2px solid $tint-40;
$border-active: 2px solid $global-blue;
$border-error: 1px solid $notification-error;

//TYPOGRAPHY ====================================================================================================================
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

$font-family-text: 'Roboto', sans-serif;
$font-family-icons: 'Material Icons';
$line-height-regular: 24px;

$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;

$font-xxsmall: 10px;
$font-xsmall: 12px;
$font-small: 14px;
$font-regular: 16px;
$font-large: 18px;
$font-xlarge: 20px;
$font-xxlarge: 24px;
$font-xxxlarge: 36px;

//BUTTON ====================================================================================================================
$button-width-minimum: 120px;

$border-radius: 4px;