@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.layoutContainer {
  display               : grid;
  grid-template-columns : 1fr;
  grid-template-rows    : auto;
  grid-auto-flow        : dense;

  & > div { margin-bottom : 32px; }

  @include desktop {
    column-gap            : 32px;
    grid-template-areas   : "sidebar content";
    grid-template-columns : 8fr 4fr;

    & > div:first-child { grid-area : sidebar; }
    & > div:last-child { grid-area : content; }
  }
}

.showForDesktopOnly {
    display: none;

    @include desktop {
        display: block;
    }
}

.notificationCard {
  > div > div {
    div {
      width: 800px;
      div:nth-of-type(2) {
        margin-left: -285px;
        white-space: nowrap;
      }
    }
  }
}

.notificationCardInFrench {
  div > div > div > div:nth-of-type(2) {
    margin-left: -225px;
  }
}

.hideForDesktop {
    display: block;

    @include desktop {
        display: none;
    }
}

.stickyFooter {
  border-top: $grey-20 1px solid;
  padding: 24px 0;

  button {
      margin-left: 16px;
      min-width: 178px;
  }
}
