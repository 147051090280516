@import "../../../../../../Components/Styles/variables";

.assessmentFooterContent, .editingButtonsContent {
  display: block;
  margin: 0 auto;
  max-width: 1266px;
}

.assessmentFooterSubTotals, .assessmentFooterTotals {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 24px 48px 24px 28px;
  font-weight: bold;

  & > div {
    flex-basis: 49%;
    flex-grow: 1;

    &:nth-child(2n) {
      text-align: right;
    }
  }
}

.assessmentFooterSubTotals {
  font-size: 14px;
  border-bottom: $grey-20 1px solid;

  & > div {
    margin-bottom: 16px;
  }
}

.assessmentFooterTotals {
  font-size: 20px;
}

.editingButtonsWrapper {
  border-top: $grey-20 1px solid;
  padding: 24px 0;
}

.editingButtonsContent {
  padding-left : 16px;

  button {
    margin: 0 8px;
  }
}

.noEditWrapper button {
  min-width: 229px;
}
