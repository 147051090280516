@import "Project/Website/styles/_layout.scss";

.half {
    width: calc(50% - #{$gutter-width / 2});
    padding: {
        bottom: $gutter-width / 2;
    }

    &Left {
        @extend .half;
        margin: {
            right: $gutter-width;
        }
    }
}

.radio-group {
    width: calc(100% - 12px); // TODO: Should be 37.5 but card is too wide
    margin: {
        right: 12px;
    }
    padding: {
        bottom: 32px;
    }

    &-full {
        @extend .radio-group;
        width: 100%;
    }
}

.full {
    width: 100%;
    padding: {
        bottom: $gutter-width / 2;
    }
}

.separator {
    @extend .full;
    color: #cccccc;
}

.subHeading {
    @extend .full;
    color: #656565;
    font: {
        weight: unset;
    }
}

.coborrowerTitle {
    display: block;
    width: 100%;
    height: 32px;
    color: #656565;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 32px;
}

.coborrowerDelete {
    display: block;
    float: right;
    width: 100%;
}
