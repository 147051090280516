@import "../../Styles/Core/variables.scss";

$form-field-align-items: center;
$form-field-align-self: stretch;
$form-field-background-color: $white;
$form-field-border: 2px solid $slate-50W;
$form-field-text-area-border: none;
$form-field-border-radius: 4px;
$form-field-display: flex;
$form-field-font-family: Roboto;
$form-field-font-size: 16px;
$form-field-font-style: normal;
$form-field-font-weight: 400;
$form-field-gap: 16px;
$form-field-height: 96px;
$form-field-line-height: 24px;
$form-field-margin: 8px 0px 8px 0px;
$form-field-padding: 12px;
$form-field-width: 100%;
$form-field-resize: vertical;

// Form field state color
$form-field-empty-color: $slate;
$form-field-error-color: $red;
$form-field-filled-color: $slate-70B;
$form-field-focused-color: $violet-20W;
$form-field-text-area-focus-visible-outline: none;

// Form field error
$form-field-error-background: $white;
$form-field-error-border: 2px solid $red;

// Form field label
$form-field-label-color: $slate-70B;
$form-field-label-font-weight: 700;
$form-field-label-gap: 8px;

// Form field helper
$form-field-helper-align-items: center;
$form-field-helper-color: $text-links-body;
$form-field-helper-font-size: 14px;
$form-field-helper-font-style: normal;
$form-field-helper-line-height: 24px;


// Form field text count
$form-field-text-count-color: $slate;
$form-field-text-count-font-size: 14px;
$form-field-text-count-font-style: normal;
$form-field-text-count-line-height: 20px;

// Form field helper container
$form-field-helper-container-gap: 4px;

// Form field helper icon container
$form-field-helper-icon-container-align-content: end;
$form-field-helper-icon-container-align-items: center;
$form-field-helper-icon-container-display: flex;

// Form field helper error
$form-field-helper-error-align-items: center;
$form-field-helper-error-color: $red;
$form-field-helper-error-gap: 8px;
$form-field-helper-error-padding: 8px 0px 0px 0px;

// Form field disabled
$form-field-disabled-background-color: $slate-90W;
$form-field-disabled-color: $slate-70B;
$form-field-disabled-border: none;
$form-field-disabled-font-size: 16px;
$form-field-disabled-font-weight: 16px;
$form-field-disabled-line-height: 16px;
