@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.piUserAccessManagementMainBody {
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
  padding: 0 $spacing-11 $spacing-13 $spacing-11;
  min-height: calc(100vh - 350px);

  .piUserAccessManagementMainContainer {
    @include flex(flex-start, unset);
    flex-direction: column;
    padding: $spacing-08 0;
    border-radius: $spacing-02;
    border: 1px solid $slate-50W;
    background: $white;
    width: 100%;


    .piUserAccessManagementHeaderContainer {
        @include flex(flex-start, space-between);
        flex-direction: row;
        gap: $spacing-08;
        width: 100%;
        padding: 0 $spacing-08 $spacing-08 $spacing-08;

        .piUserAccessManagementHeaderTitleInfoWrapper {
            @include flex(flex-start, unset);
            flex-direction: column;
            align-self: stretch;
            gap: $spacing-03;
    
            .piUserAccessManagementTitle {
                @include font-settings($font-xxlarge, $weight-medium, $line-height-large, $slate-70B);
                text-align: center;
            }
    
            .piUserAccessManagementTitleInfo {
                @include font-settings($font-regular, $weight-regular, $line-height-regular, $slate-70B);
            }
        }
    }

    .piUserAccessManagementTabContainer {
        width: 100%;
        padding: 0 $spacing-08 0 $spacing-08;

        .piUserAccessManagementTabs {
          --tab-font-color: #{$navy};
          --tab-text-shadow: none;
          --tab-font-weight: 700;
          --tab-item-bg: #{$slate-90W};
          --tab-item-focusColor: #{$violet-20W};

            ul {
              padding: 0 !important;

              li button {
                &::after {
                  background: $navy;
                }
              }
            }
        }
    }

    .internalPIUserdataTable {
      --PITableScrollContainershiftButtonColor: #{$navy};
      --PITableScrollContainershiftButtonFocusColor: #{$violet-20W};

        width: 100%;
        padding: $spacing-08 $spacing-08 0 $spacing-08;
      
        table {
          thead > tr {
            th:nth-child(1) {
              padding-left: 33px;
              button {
                &:focus {
                  outline: $spacing-01 solid $violet-20W;
                }
              }
            }
      
            th:nth-child(2) {
              padding-left: 36px;
              button {
                &:focus {
                  outline: $spacing-01 solid $violet-20W;
                }
              }
            }
      
            th:nth-child(3) {
              padding-left: 42px;
              button {
                &:focus {
                  outline: $spacing-01 solid $violet-20W;
                }
              }
            }
      
            th:nth-child(4) {
              padding-left: 42px;
            }
      
            th:nth-child(5) {
              padding-left: 10px;
            }
      
            th:nth-child(6) {
              padding-left: 43px;
            }
      
            th:nth-child(7) {
              padding-left: 43px;
            }
          }
      
          tbody > tr:nth-child(n) {
            &:hover {
              cursor: pointer;
              background-color: $violet-90W;
            }

            &:focus {
              outline: $spacing-01 solid $violet-20W;
            }

            td:nth-child(1) {
              padding-left: 33px;
            }
      
            td:nth-child(2) {
              padding-left: 36px;
            }
      
            td:nth-child(3) {
              padding-left: 42px;
            }
      
            td:nth-child(4) {
              padding-left: 42px;
            }
      
            td:nth-child(5) {
              padding-left: 10px;
            }
      
            td:nth-child(6) {
              padding-left: 43px;
            }
      
            td:nth-child(7) {
              padding-left: 43px;
            }
          }
      
          tbody > tr:nth-child(even) {
            background: $slate-95W;
          }
        }
      }
      
      .dataTableFooter {
        margin: 0;
        padding: 0;

        > div {
          padding: 0;

          @media screen and (max-width: 991px) {
            flex-wrap: wrap;
            gap: $spacing-05;
            align-items: baseline;
          }
        }

        nav, ul, li {
          button[aria-current="true"] {
            background: $navy;
            color: $white;
            border-color: $navy;
        
            &:hover {
              background: lighten($violet, 5);
              border-color: lighten($violet, 5);
            }
          }
        }
      }
      
      .description {
        padding: 0 0 $spacing-08 33px;
        width: 550px;
      }
      
      .addUser {
        margin-left: $spacing-10;
      
        > div {
          margin-right: -$spacing-05;
        }
      }
      
      .descriptionText {
        padding-right: 33px;
        max-width: 550px;
      }
      
      .noSearchResultsMessage {
        display: block;
        padding: $spacing-08;
        text-align: center;
        font-weight: bold;
      }

      .searchBarWrapper {
        @include flex(center, space-between);
        padding-bottom: $spacing-08;

        @include tablet {
          width: 100%;
          margin-bottom: 10px;
        }
      
        @include desktop {
          flex-grow: 12;
        }
      }
      .searchBarWidth {
        width: 75%;
        input, select {
          &:focus {
            border: 1px solid $violet-20W;
          }
        }
      }
  }
}

@include tablet {
  .piUserAccessManagementMainBody {
    padding: 0 $spacing-08 $spacing-13 $spacing-08;

    .piUserAccessManagementMainContainer {
      .piUserAccessManagementHeaderContainer {
        flex-direction: column;
      }

      .piUserAccessManagementTabContainer {
        padding: 0;
      }
    }
  }
}