@import "../../../../../../Components/Styles/variables";
@import "../../../../../../Components/Styles/adaptive";

.actionMenu {
  border-bottom: 1px solid $grey-20;
  display: grid;
  grid-template-columns: 225px auto;
  margin: 0;
  padding: 24px 32px;
  width: 100%;

  & > div { align-self: center; }
}

.filterMenu, .actionButtons {
  font-size: $font-xlarge;
  font-weight: $weight-medium;
  position: relative;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;

  & > button {
    display: flex;
    margin: 8px;

    &:nth-child(-n+2) {
      display: none;

      @include desktop { display: flex; }
    }

    &:last-child { margin-right: 0; }
  }
}

.actionIcons { font-size: 18px; }

.hideDesktop {
  display: initial;

  @include desktop {
    display: none;
  }
}

.buttonDropdown {
  margin-top: 8px;

  & > button:first-child {
    height: 34px;
    margin: 0;
    padding: 0;
    width: 38px;
  }

  > div {
    border: $border-primary;
    position: absolute;
    right: 5px;
    top: 8px;
    z-index: 1;

    button {
      border: none;
      border-radius: 0;
      font-size: $font-small;
      font-weight: $weight-regular;
      line-height: $line-height-regular;
      text-align: left;

      &:not(:last-child) { border-bottom: $border-default; }
    }
  }
}
