
//SWITCH ============================================================================================

.switch{
    @include flexbox;
    @include flex-direction(row);
    @include align-items(center);
    margin-right: 24px;

    &__input {
        
        
        margin-right: 16px;
        margin-top: 8px;
        position: relative;
        display: inline-block;
    }

    /* Hide default HTML checkbox */
    &__input-checkbox {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 12px;
        
        &:checked + .switch__slider {
            background-color: $tint-40;
        
            &:before{
                background-color: $global-blue;
                @include translateX(16px);
            }
        }
    }

    &__label{
        line-height: 16px;
        padding-left: 40px;
    }

    &__slider {
        cursor: pointer;
        background-color: $grey-60;
        @include border-radius(34px);
        position: absolute;
        top: 7px;
        left: 0;
        right: 0;
        bottom: 0;
        transition: .4s;
        width: 32px;
        height: 12px;
    
        &:before {
            content: "";
            background-color: $white;
            @include border-radius(50%);
            @include box-shadow(0 0 2px 0 $grey-60);
            position: absolute;
            height: 16px;
            width: 16px;
            left: 0;
            bottom: -2px;

            &:disabled{
                background-color: $grey-20;
            }
        }
    
        &:disabled{
            background-color: $grey-6;
        }
      }
}

input[type=checkbox].switch__input-checkbox:focus {
    width: 32px!important;
    height: 3px;
    position: absolute;
    top: 10px;
    outline: 2px solid $tint-40;
    outline-offset: 10px;
    outline-color: $tint-40!important;
    opacity: 1!important;
}