@import 'Project/Website/styles/_layout.scss';
@import "src/Components/Styles/adaptive";

.half {
    width: calc(50% - #{$gutter-width / 2});
    padding: {
        bottom: $gutter-width / 2;
    };

    &Left {
        @extend .half;
        margin: {
            right: $gutter-width;
        };
    }
}

.oneThird {
    width: calc(32% - #{$gutter-width / 2});
    padding: {
        bottom: $gutter-width / 2;
    };

    &Left {
        @extend .oneThird;
        margin: {
            right: $gutter-width;
        };
    }
}

.radio-group {
    width: calc(45% - 12px); // TODO: Should be 37.5 but card is too wide
    margin: {
        right: 12px;
    };
    padding: {
        bottom: 32px;
    };

    &-full {
        @extend .radio-group;
        width: 100%;
    }
}

.full {
    width: 100%;
    padding: {
        bottom: $gutter-width / 2;
    };

    &Right {
        @extend .full;
        text-align: right;
    }
}

.adjustMargin {
    margin-top: 22px;
    @include desktop {
        margin-top: 1px !important;
    }
}
.separator {
    @extend .full;
    color: #CCCCCC;
}

.subHeading {
    @extend .full;
    color: #656565;
    font: {
        weight: unset;
    }
}

.pullDown {
    @include desktop {
        margin-top: 24px;
    }
  }