@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss"; 

.addExternalPIUserMain {
  @include flex(flex-start, unset);
  flex-direction: column;
  align-self: stretch;
  padding: 0 $spacing-11 $spacing-13 $spacing-11; 
  min-height: calc(100vh - 350px);

  .addExternalPIUserMainContainer {
    @include flex(flex-start, unset);
    flex-direction: column;
    padding: $spacing-08 0;
    border-radius: $spacing-02;
    border: 1px solid $slate-50W;
    background: $white;
    width: 100%;

    .addExternalPIUserMainContent{
      @include flex(flex-start, space-between);
      flex-direction: column;
      gap: $spacing-08;
      width: 100%;
      padding: 0 $spacing-08 $spacing-08 $spacing-08;

      .addExternalPIUserHeaderContainer {
        .addExternalPIUserTitle {
          @include font-settings($font-xxlarge, $weight-medium, $line-height-large, $slate-70B);
          text-align: center;
        }
      }

      .addExternalPIUserFormContainer {
        @include flex(flex-start, unset);
        flex-direction: column;
        align-self: stretch;
        gap: $spacing-08;

        .addExternalPIUserFormBody {
          border-radius: $spacing-02;
          border: 1px solid $slate-50W;
          margin: 0;

          > div:first-of-type {
            padding: $spacing-08 $spacing-08 0;
            margin: 0;

            section {
              padding: 0;

              div:first-of-type {
                div > div > div {
                  margin-bottom: $spacing-08 !important;
                }
              }
            }
          }

          div:empty {
            display: none;
          }

          .actionButtons {
            @include flex(center, flex-start);
            flex-direction: row;
            gap: $spacing-05;
          }

          .userActiveToggleContainer {
            @include flex(center, flex-start);
            padding: $spacing-08 0;

            .externalUserActiveToggle {
              --inputSliderCheckedBgColor: #{$navy};
              --inputSliderUncheckedBgColor: #{$slate};
              --inputSliderReadOnlyBgColor: #{$slate-80W};
              --inputSliderReadOnlyColor: #{$slate};
              --inputSliderBgColor: #{$white};
              --inputSliderFocus: #{$violet-20W};
            }
          }

          .addExternalPIUserFormActionBtnContainer {
            @include flex(center, flex-start);
            flex-direction: row;
            padding: $spacing-03 $spacing-08 $spacing-08;
            gap: $spacing-05;

            .addButtons {
              button {
                min-width: 200px;
              }
            }
          }
        }
      }
    }
  }
}

@include for-tablet {
  .addExternalPIUserMain {
    padding: 0 $spacing-08 $spacing-13 $spacing-08;
  }
}
