

//TYPOGRAPHY ====================================================================================================================

h1, h2, h3, body, small, a{
    font-family: $font-family-text;
    line-height: $line-height-regular;
    color: $soft-black
}

h1{
    font-weight: $weight-regular;
    font-size: 36px;
    line-height: 48px;
}

h2{
    font-weight: $weight-medium;
    font-size: $font-xxlarge;
    line-height: 40px;
}

h3{
    font-weight: $weight-medium;
    font-size: $font-xlarge;
    line-height: 32px;
}

body{
    font-weight: $weight-regular;
    font-size: $font-regular;
}

b{
    font-weight: $weight-bold;
}

small{
    font-weight: $weight-regular;
    font-size: $font-small;
}

a{
    font-weight: $weight-medium;
    font-size: $font-regular;
    color: $global-blue;
    &:hover{
        text-decoration: none;
    }
}

small a, a small{
    font-weight: $weight-medium;
    font-size: $font-small;
}

.cta{
    letter-spacing: 1.25px;
    font-weight: $weight-bold;
}

.cta-default{
    font-size: $font-regular;
}

.cta-small{
    font-size: $font-small;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px indicatorted $soft-black;
    opacity: 1;
}
  
.tooltiptext {
    visibility: hidden;
    font-size: $font-xsmall;
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
    color: $white;
    background-color: $grey-80;
    @include border-radius(6px);
    padding: 4px 12px;
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1;
}
  
.tooltip:hover .tooltiptext {
    visibility: visible;
}
