@import "Components/Styles/Core/variables";
@import 'Components/Styles/Core/mixins';
@import "Components/Styles/Core/adaptive.scss";

.mainSideNavigation {
  @include flex(flex-start, space-between);
  flex-direction: column;
  padding: $spacing-10 0 $spacing-08 $spacing-11;
  height: 100%;
  position: sticky;
  top: 0;
  height: calc(100vh - 105px);

  .sideNavContainer {
    @include flex(flex-start, unset);
    flex-direction: column;
    gap: $spacing-06;
    width: 100%;

    .sideNavContainerHeader {
      font-family: $font-family-text;
      @include font-settings($font-regular, $weight-bold, $line-height-regular, $slate-70B);
    }

    .sideNavMenu {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      .sideNavMenuItem {
        margin: 0;

        .menuLink {
          display: flex;
          border-style: solid;
          border-width: 0 0 0 $spacing-02;
          border-color: transparent;

          &:hover {
            display: flex;
            border-style: solid;
            border-inline-color: $violet;
            border-width: 0 0 0 $spacing-02;
            border-radius: $spacing-02 0 0 $spacing-02;
            background: $violet-90W;
          }

          &:focus {
            outline: 2px solid $violet-20W;
          }

          .moduleContainer {
            @include flex(center, unset);
            padding: $spacing-05 $spacing-06;
            gap: $spacing-05;
            flex: 1 0 0;
            min-height: 56px;
      
            .iconTextWrapper {
              @include flex(flex-start, unset);
              gap: $spacing-04;
              flex: 1 0 0;
      
              .label {
                font-family: $font-family-text;
                @include font-settings($font-regular, $weight-regular, $line-height-regular, $navy);
              }
            }
          }
        }

        .activeLink {
            display: flex;
            border-style: solid;
            border-image: linear-gradient(180deg, $navy 0%, $red 100%) 1;
            border-width: 0 0 0 $spacing-02;
            border-radius: $spacing-02 0 0 $spacing-02;
            background: $slate-95W;        
        }

        .sideNavLogoutLink {
          cursor: pointer;
        }
      }
    }

    .sideNavMenu .sideNavMenuItem .menuLink:hover .moduleContainer .iconTextWrapper .label,
    .sideNavMenu .sideNavMenuItem .menuLink.activeLink .moduleContainer .iconTextWrapper .label {
        font-weight: $weight-bold !important;
    }

    .accountContainer {
      @include flex(flex-start, unset);
      flex-direction: column;
      gap: $spacing-05;
      min-height: 56px;
      width: 100%;

      .moduleContainer { 
        padding: $spacing-05 $spacing-06 $spacing-05 $spacing-07;

        .iconTextWrapper {
          @include flex(flex-start, unset);
          gap: $spacing-04;
          flex: 1 0 0;

          .label {
            font-family: $font-family-text;
            @include font-settings($font-regular, $weight-regular, $line-height-regular, $navy);
          }
        }
      }
    }
  }
}

@include for-tablet {
  .mainSideNavigation {
    padding: 96px 0 $spacing-08 0;
    .sideNavContainer {
      .sideNavContainerHeader {
        display: none;
      }

      .sideNavMenu {
        .sideNavMenuItem {
            .menuLink {
                &:focus {
                    outline-offset: -4px;
                }
                .moduleContainer {
                    padding: 0 $spacing-06 0 $spacing-06;

                    .iconTextWrapper {
                        align-items: center;
                        gap: $spacing-05;

                        .label {
                          display: none;
                        }
                    }
                }
            }
        }
      }
    }
  }
}