@import "src/Components/Styles/variables";

.half {
  width: calc(45% - 16px);
  padding: {
    bottom: 32px;
  }

  &Left {
    @extend .half;
    margin: {
      right: 32px;
      left: 16px;
    }
  }
}

.oneThird {
  width: calc(30% - 16px);
  margin: {
    right: 16px;
  }

  &:first-child {
    margin-left: -3px;
  }

  &:last-child {
    margin-right: 12px;
  }
}

.createClaimButton {
  width: 167px;
  height: 49px;
}

.secondFieldRow {
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
}

.transitNumberField {
  margin-top: 24px;
}
