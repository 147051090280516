@import "src/Project/Website/styles/main.scss";
@import "src/Components/Styles/variables";

.form-select {
  width: 100%;
  padding: 7.5px 16px;
  margin-right: 24px;
  border: $grey-20 1px solid;
  border-radius: 4px;
  font-size: 14px;
  background-color: #e9e9e9;
  @include appearance(none);

  &-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    label {
      display: block;
      margin-right: 4px;
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 14px;
    }

    &::after {
      pointer-events: none;
      content: "expand_more";
      font: {
        family: $font-family-icons;
        size: $font-regular;
      }
      color: #282828;
      @include cross-browser-icon-support;
      position: absolute;
      right: 42px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      line-height: 0;
    }
  }
}
