.default-modal-form {
  margin-bottom: -40px;

  label {
    font-weight: normal !important;
  }

  textarea {
    width: 100%;

  }

  .default-modal-form__submit-button {
    margin-top: 20px;
    text-align: right;
  }


}