@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/BaseComponentStyles/formSelect";

.tabsWrapper {
  border-bottom : $grey-20 1px solid;
}

.tabs {
    margin  : 0;
    padding : 0 17px;
    display: none;

    @include desktop {
      display : flex;
    }

  li {
    list-style-type : none;
  }
}

.selector {
  @extend .selectWrapperBase;

  margin: 32px;

  @include desktop { display: none; }
}

.tabItem {
  background : none;
  border     : none;
  padding    : 24px 16px;
  position   : relative;
  text-align: left;

  &:focus {
    outline: 2px solid var(--tab-item-focusColor, $tint-40);
    span { position : relative; }
  }
}

$boldSize : 0.020rem;

.tabItemIsCurrent {
  color       : var(--tab-font-color, $global-blue);
  text-shadow : var(--tab-text-shadow,
                  (-$boldSize) (-$boldSize) 0 var(--tab-font-color, $global-blue),
                  $boldSize  (-$boldSize) 0 var(--tab-font-color, $global-blue),
                  (-$boldSize)   $boldSize  0 var(--tab-font-color, $global-blue),
                  $boldSize    $boldSize  0 var(--tab-font-color, $global-blue));
  font-weight: var(--tab-font-weight, 400);
  background: var(--tab-item-bg, none);

  &:after {
    background : $global-blue;
    bottom     : -1px;
    content    : '';
    display    : block;
    height     : 2px;
    left       : 50%;
    position   : absolute;
    transform  : translateX(-50%);
    width      : 100%;
  }
}