.sideMenu, .sideMenu ul {
  list-style-type: none;
}

.sideMenu {
  margin: 24px 0;
  padding: 0;

  & ul {
    margin: 0;
  }

  & > li div {
    padding: 14px 32px;
  }

  & > li > div > a {
    font-weight: bold;
  }

}

