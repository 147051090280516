.modal {
	display: block;
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 100;

	&.modal-enter {
		display: block;

		.modal__card {
			opacity: 1;
		}
	}

	&.modal-enter-active {
		.modal__bg {
			animation: modal-bg 0.1s ease-out forwards;
		}

		.modal__card {
			animation: modal 0.3s ease-in-out forwards;
		}
	}

	&.modal-enter-done {
		display: block;

		.modal__bg {
			opacity: 1;
		}

		.modal__card {
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&.modal-exit {
		display: block;
	}

	&.modal-exit-active {
		.modal__bg {
			animation: modal-bg 0.1s ease-out 0.1s reverse forwards;
		}

		.modal__card {
			animation: modal 0.3s ease-in-out reverse both;
		}
	}

	&.modal-exit-done {
		opacity: 0;

		.modal__bg {
			opacity: 0;
		}

		.modal__card {
			display: none;
			left: 50%;
			top: 50vh;
			transform: translateX(-50%) translateY(-50%);
		}
	}
}

.modal__bg {
	background: rgba(0, 0, 0, 0.75);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 101;
}

.modal__card {
	left: 50%;
	padding: 32px 32px 0 !important;
	position: absolute;
	top: 100vh;
	transform: translateX(-50%);
	width: 627px;
	z-index: 102;

	&--default .modal__card__header__icon {
		color: $global-blue;
	}

	&--error .modal__card__header__icon {
		color: $notification-error;
	}

	&--success .modal__card__header__icon {
		color: $notification-success;
	}

	&--warning .modal__card__header__icon {
		color: darken($notification-warning, 7.75) !important;
	}

	&--inactive .modal__card__header__icon {
		color: $notification-warning;
	}

	&__lessBottomPadding {
		padding-bottom: 16px !important;
	}

	&__bottomPadding {
		padding-bottom: 32px !important;
	}

	.modal__card__header {
		align-items: flex-start;
		display: inline-flex;
		padding: 0 !important;

		.modal__card__header__icon {
			flex-shrink: 1;
			margin-top: 8px;

			.material-icons {
				color: inherit !important;
			}
		}

		.modal__card__header__title {
			flex-grow: 3;

			h2 {
				margin: 8px 16px;
			}
		}
	}

	.modal__card__body {
		margin-bottom: 20px;
		padding: 0 !important;
	}

	.input_field {
		width: 60%;
		padding: 10px 20px;
		margin: 8px 0;
		box-sizing: border-box;
	}

	.confirm_text {
		padding-top: 20px;
	}

	.model_content_styles {
		margin-left: 16px !important;
	}

	.modal__card__body_pop {
		margin-top: 0px !important;
		margin-bottom: 20px !important;
		padding: 0 !important;
	}

	.card_divider {
		border-bottom: 1rem #000000;
	}

	.modal__card__footer--buttons {
		align-items: center;
		display: inline-flex;
		justify-content: space-between;

		.modal__card__footer__link-button {
			.btn {
				color: $global-blue;
				padding: 0 !important;
			}

			.btn__content-wrapper {
				justify-content: flex-start !important;
			}
		}

		.modal__card__footer__buttons button {
			margin-right: 16px;

			&:last-child {
				margin-right: 0px !important;
			}
		}
	}

	.modal__card__footer--buttons_left {
		align-items: center;
		justify-content: space-between;

		.modal__card__footer__link-button {
			.btn {
				color: $global-blue;
				padding: 0 !important;
			}

			.btn__content-wrapper {
				justify-content: flex-start !important;
			}
		}

		.modal__card__footer__buttons button {
			margin-left: 16px;

			&:last-child {
				margin-right: 0px !important;
			}
		}
	}
}

// New core modals
.core-modal {
	&.modal-enter-done {
		.modal__bg {
			opacity: 50% !important;
		}
	}

	.modal__bg {
		background: $slate-70B;
	}

	.modal__card {
		padding: $spacing-07 $spacing-08 $spacing-08 $spacing-08 !important;
		box-shadow: 0px $spacing-02 $spacing-05 0px $grey-35B;
		background: $white;
		border-radius: $spacing-02;
		width: calc(640px - 64px);
	}
}

@keyframes modal {
	0% {
		top: 50vh;
		left: 50%;
		opacity: 0;
		transform: translateX(-50%) translateY(-50%);
	}

	100% {
		top: 50vh;
		left: 50%;
		opacity: 1;
		transform: translateX(-50%) translateY(-50%);
	}
}

@keyframes modal-bg {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
