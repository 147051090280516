@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.sideMenuButton {
  display: flex;
  background: none;
  border: none;
  padding: 0;

  p {
    font-weight: bold;
    font-size: $font-small;
    display: none;
    margin: 0;

    @include desktop {
      display: initial;
    }
  }
}

.menuIcon {
  margin-top:4px;
  margin-right: 2px;
  display: block;
}
