@import "src/Components/Styles/variables";

.menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-weight: normal;
    color: $black;
    font-size: $font-small;

    &.active {
      font-weight: bold;
    }
  }

  button {
    background: none;
    border: none;
  }
}

.parentActive {
  background: $tint-5;
}

