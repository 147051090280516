@import "src/Components/Styles/variables";

.searchBarContainer {
  align-items   : center;
  border        : $grey-20 1px solid;
  border-radius : 4px;
  position      : relative;
}

.searchFieldValueWrapper {
  display : flex;
}

.searchFieldWrapper {
  & > div {
    position : relative;

    &:after {
      border-left  : 4px solid transparent;
      border-right : 4px solid transparent;
      border-top   : 4px solid $black;
      content      : '';
      display      : block;
      height       : 0;
      position     : absolute;
      right        : 16px;
      top          : 50%;
      transform    : translateY(-51%);
      width        : 0;
    }
  }

  select {
    appearance   : none;
    background   : $grey-3;
    border       : none;
    border-right : $grey-20 1px solid;
    display      : block;
    font-size    : 14px;
    height       : 100%;
    padding      : 16px 32px 16px 16px;
    position     : relative;
    transition   : background-color 0.3s ease;
    width        : 100%;

    &::-ms-expand {
      display : none;
    }

    &:hover {
      background : darken($grey-3, 2);
    }

    &:focus {
      border        : $shade-30 1px solid;
      border-radius : 4px 0 0 4px;
      margin        : -1px 0 -1px -1px;
      outline       : none;
    }
  }
}
.singleSearchField {

  select {
    padding-right: 16px;
  }
  & > div:after {
      display: none;
    }

}

.searchTextBoxWrapper {
  flex-grow : 1;

  input {
    border        : none;
    border-radius : 0 4px 4px 0;
    font-size     : 14px;
    padding       : 16px 45px 16px 15px;
    width         : 100%;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance : none;
      margin             : 0;
    }

    &:focus {
      border  : $shade-30 1px solid;
      margin  : -1px -3px -1px 0;
      outline : 0;
      padding : 16px 45px 16px 15px;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow : 0 0 0 30px white inset;
      color              : $global-blue;
    }
  }

  input[type=number] {
    -moz-appearance : textfield;
  }
}

.searchButton {
  position  : absolute;
  right     : 4px;
  top       : 50%;
  transform : translateY(-50%);

  button {
    background  : none;
    border      : 0;
    line-height : 0;
    margin      : 8px 4px 0 0;
  }
}
.noSearchResultsMessage {
  display: block;
  padding: 32px;
  text-align: center;
  font-weight: bold;
}