@import "src/Components/Styles/variables";
@import "src/Components/Styles/adaptive";

.formWrapper {
  border: 1px solid $grey-20;
  border-radius: 3px;
  margin: 32px 42px;
  width: calc(100% - 84px);

  div:empty { display: none; }
}

.buttonsLayout {
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;

    &:first-of-type {
      flex-grow: 2;

      & > button {
        margin: 0 8px;
        min-width: 186px;
        @include tablet{
          min-width: 140px;
        }

        &:first-of-type { margin-left: 0; }
        &:last-of-type { margin-right: 0; }
      }
    }

  }
  .showUserActive {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 1;
    margin-right: -86px;
  }
}

.cardItem {
  > div > div > div {
    margin-bottom: 2rem !important;
  }

  div:empty {
    display: none;
  }
}

