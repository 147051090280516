@import 'Project/Website/styles/main.scss';


.tooltip-icon {
	position: relative;
	display: inline-block;
	.hover-content {
		visibility: hidden;
		width: 87px;
		background-color: $grey-80;
		color: $white;
		text-align: center;
		border-radius: 6px;
		padding: 4px 0;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 16px;
		margin-left: -32px;
		margin-top: 4px;
		position: absolute;
		z-index: 1;
	}
	&:hover {
		.hover-content {
			visibility: visible;
			width: 87px;
		}
	}
}
