@import "../../Styles/Core/variables.scss";
@import "../../Styles/Core/mixins.scss";

.notificationEnter {
    transform: translateY(-100%);
    opacity: 0;
  }
  
  .notificationEnterActive {
    transform: translateY(0);
    opacity: 1;
    transition: transform 800ms ease-in-out, opacity 800ms ease-in-out;
  }

.notificationBanner {
    padding: $spacing-07;
    border-radius: $spacing-02;
    box-shadow: 0 $spacing-02 $spacing-05 0 rgba(0, 0, 0, 0.25);
    border-left: $spacing-03 solid;
}

.notificationBanner.info {
    background-color: $white;
    border-left-color: $navy;
}

.notificationBanner.success {
    background-color: $white;
    border-left-color: $green;
}

.notificationBanner.error {
    background-color: $white;
    border-left-color: $red;
}

.notificationBanner.warning {
    background-color: $white;
    border-left-color: $yellow;
}

.topContent {
    @include flex(center, space-between);
}

.closeButton {
    background: transparent;
    border: none;
    cursor: pointer;
    width: $spacing-07;
    height: $spacing-07;
    min-width: unset;

    &:active{
    box-shadow: none;
    }
}

.leftSide {
    @include flex(center, space-between);
    gap: $spacing-05;
}

.leadingIconStyle {
    width: $spacing-07;
    height: $spacing-07;
}

.headerStyle {
    @include font-settings($font-large, $weight-medium, $line-height-medium);
    font-family: $font-family-text !important;
    color: $text-links-body !important;
}

.messageStyle {
    padding-left: $spacing-09 !important;
    @include font-settings($font-small, $weight-regular, $line-height-small);
    font-family: $font-family-text !important;
    color: $text-links-body !important;

    a {
      @include font-settings($font-regular, $weight-medium, $line-height-regular, $digital-blue);
      text-decoration-line: underline;
      border-radius: $spacing-02;

      u {
        text-decoration-line: none;
      }

      &:hover {
        color: $violet !important;
        text-decoration: none !important;
      }
  
      &:focus {
        color: $digital-blue !important;
        text-decoration-color: $digital-blue !important;
        outline: $spacing-01 solid $violet-20W;
        border-radius: $spacing-02;
      }
  
      &:active {
        color: $violet !important;
        text-decoration: none !important;
        outline: $spacing-01 solid $slate-50W;
        border-radius: $spacing-02;
      }
    }
}