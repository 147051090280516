@import "Project/Website/styles/_layout.scss";
@import "src/Components/Styles/variables";

.radio-label {
    font: {
        weight: initial;
        size: 14px;
    }
    padding: {
        left: $gutter-width / 4;
    }

    &-wrapper {
        display: flex;
        align-items: center;
        margin: {
            right: $gutter-width;
        }
    }
}

.read-only {
    pointer-events: none;
}

.radio {
    $dim: 16px;
    $accent-colour: $global-blue;
    $primary-colour: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    height: $dim;
    width: $dim;

    background-color: $primary-colour;

    border: 1px solid $soft-black;
    border-radius: $dim;

    :global(fieldset.error) & {
        border: {
            color: $notification-error;
        }
    }

    input[aria-disabled="false"]:checked + &::after {
        content: "";
        background-color: $accent-colour;
        height: $dim / 2;
        width: $dim / 2;
        border-radius: $dim / 2;
    }

    input[aria-disabled="true"]:checked + &::after {
        content: "";
        background-color: $soft-black;
        height: $dim / 2;
        width: $dim / 2;
        border-radius: $dim / 2;
    }

    input[aria-disabled="true"] + & {
        background-color: $grey-20;
        border: 4px solid $grey-20;
        border-radius: 16px;
        box-shadow: 0 0 2px $black;
    }

    input[type="radio"]:disabled + & {
        background: $grey-6;
        border: 1px solid $grey-20;
    }

    input[type="radio"]:focus-within + & {
        outline: 2px solid $tint-40;
    }
}
