@import "src/Components/Styles/BaseComponentStyles/cards";
@import "src/Components/Styles/adaptive";
@import "src/Components/Styles/variables";

.dataTable {
    width: 100%;
    padding-top: 16px;
    table {
        width: 100%;
      thead > tr {
        th{
          padding-left: 32px;
        }
      }
  
      tbody > tr:nth-child(n) {
        td{
          padding-left: 32px;
        }
  
      }
  
      tbody > tr:nth-child(even) td {
        background: $grey-3;
      }
    }
  }

  .dataTableFooter {
    >div {
      margin-right: -64px;
    }
  }

  .noSearchResultsMessage {
    display: block;
    padding: 32px;
    text-align: center;
    font-weight: bold;
  }

  .searchBarWrapper { 
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
  .adminToggle{
    padding-left: 24px;
  }
    @include tablet {
      width: 100%;
      margin-bottom: 10px;
      padding: 16px;
    }
  
    @include desktop {
      flex-grow: 12;
      padding: 16px 16px 16px 52px;
    }
  }
  .searchBarWidth {
    width: 75%;
  }