@import "src/Components/Styles/variables";


.form-text {
    padding: 16.5px 12.5px;
    border: $border-default;
    height: 100px;

    &:read-only {
        background-color: $grey-6;
        border-color: $grey-20;
        opacity: 1;
    }

    :global(label.error) & {
        border: {
            color: #da291c;
        }
    }

}

.text-area-help-text {
    padding: 8px;
    display: flex;
    justify-content: space-between;

    %helper-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
    }

    &-limit,
    &-count {
        @extend %helper-text;
        color: #282828;
        height: 24px;
        letter-spacing: 0;
        line-height: 24px;
    }
}